import { Box } from '@mui/system';
import React from 'react';
import DateSelector from '../../components/DateSelector';
import InputField from '../../components/InputForm';
import { IFaseProps } from './Index';

const Analisis = ({ handlechange,setDates,state } : IFaseProps) =>{
    return (
        <Box style={{ flexDirection: 'column', justifyContent : 'space-around'}}>
            <Box>
                <Box sx={{marginBottom : 2}}>
                    <DateSelector
                        label = "Fecha de autorización"
                        retrieveDate={( date : string ) => setDates("fecha_autorizacion", date) }
                    />
                </Box>
                <Box>
                    <DateSelector
                        label = "Fecha de vencimiento"
                        retrieveDate={( date : string ) => setDates("fecha_vencimiento", date) }
                    />
                </Box>
            </Box>
                <Box mt={2}>
                    <InputField onChange={handlechange} name="monto_autorizado" label="Monto autorizado" type="number" fullWidth/>
                    <span>{`$${new Intl.NumberFormat().format(state.monto_autorizado)}`}</span>
                </Box>
                <Box mt={2}>
                    <InputField onChange={handlechange} value={state.tasa} name="tasa" label="Tasa de interes" type="number" fullWidth/>
                </Box>
        </Box>
    )
};

export default Analisis;