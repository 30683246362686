export interface Notificaciones {
    title: string
    content: string
    type: NotificationType
    image?: string
    callback?: (args: any) => void
}
export enum NotificationType {
    BIRTHDAY = "BIRTHDAY",
    GENERAL = "GENERAL"
}