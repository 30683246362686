import { ReactElement } from 'react'
import { useAppContext } from '../../AppContext'
import { PermisosEspeciales, TipoUsuario } from '../models/Asesor'
import { getRole, hasAccess } from '../Validations'

interface IVerificarAccesos {
    permisos?: Partial<PermisosEspeciales>
    tipo_usuario?: TipoUsuario | null | number
}
interface Props extends IVerificarAccesos {
    children: ReactElement,
}

export const VerificarAccesos = () => {
    const { usuario } = useAppContext()

    const buildContent = (props: IVerificarAccesos) => {
        if (props.tipo_usuario && usuario) {
            if (props.tipo_usuario === getRole(usuario.tipo_usuario)) {
                return true
            }
            if ((Number(usuario.tipo_usuario) & Number(props.tipo_usuario)) > 0) {
                return true
            }
        }

        if (props.permisos) {
            if (usuario && hasAccess(props.permisos, usuario.permisos)) {
                return true
            }
        }

        return false
    }
    return {
        buildContent
    }
}

function TieneAcceso(props: Props) {
    const { buildContent } = VerificarAccesos()

    return (
        buildContent(props) ? props.children : null
    )
}

export default TieneAcceso