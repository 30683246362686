import React, { ReactElement } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

interface IVerticalTabsProps {
    options: IOptions[]
}

interface IOptions {
    title: string,
    component: ReactElement
}

function TabPanel(props: TabPanelProps) {
    const {
        children,
        value,
        index,
        ...other
    } = props;

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            sx={{
                width: '100%'
            }}
            {...other}>
            {value === index && (
                <Box sx={{
                    p: 3,
                }}>
                    {children}
                </Box>
            )}
        </Box>
    );
}

function a11yProps(index: number) {
    return { id: `vertical-tab-${index}`, 'aria-controls': `vertical-tabpanel-${index}` };
}

const VerticalTabs = (props: IVerticalTabsProps) => {
    const [value,
        setValue] = React.useState(0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Box
            sx={{
                bgcolor: 'background.paper',
                height: '100%',
                borderBottom: 1,
                borderColor: 'divider'
            }}>
            <Tabs
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example">
                {props.options.length > 0 && props
                    .options
                    .map((item, index) => (<Tab key={index} label={item.title} {...a11yProps(index)} />))
                }
            </Tabs>
            {props.options.length > 0 && props
                .options
                .map((item, index) => (
                    <TabPanel value={value} key={index} index={index}>
                        {item.component}
                    </TabPanel>
                ))
            }
        </Box>
    );
}

export default VerticalTabs;