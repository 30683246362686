import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import React, { ReactElement } from 'react';
import SmallDialog from './SmallDialog';

interface IOption{
    text : string,
    Icon : ReactElement
    handleEvent : () => void
}
interface IOptionsProps{
    open : boolean,
    handleClose : () => void,
    text : string ,
    options : IOption[]
}

const Opciones = ({open, handleClose, text, options} : IOptionsProps) => {
    return (
        <SmallDialog open={open} handleClose={handleClose} text={text}>
            <List>
                {
                    options.map((item : IOption,index) => (
                        <ListItem key={index} autoFocus button onClick={ item.handleEvent }>
                            <ListItemIcon>
                                { item.Icon }
                            </ListItemIcon>
                            <ListItemText primary={ item.text } />
                        </ListItem>
                    ))
                }
            </List>

        </SmallDialog>
    );
};

export default Opciones;