import React from 'react';
import { InputLabel,Select,FormControl,MenuItem,Paper } from '@mui/material';

interface ISelectProps {
    label : string,
    menuRender : { id : any, name : string }[],
    onChange : (name : string, value : any) => void,
    name : string,
    value? : any,
    defaultText? : string,
    disabled? : boolean
}
const SelectField = (props : ISelectProps) => {

    const handleChange = ( ev : any )=>{
        props.onChange(ev.target.name, ev.target.value)
    }
    return (
        <Paper sx={{marginRight : 2}}>
                <FormControl variant="outlined" fullWidth size="small">
                    <InputLabel>{props.label}</InputLabel>
                    <Select
                    label={props.label}
                    disabled={props.disabled}
                    size="small"
                    autoWidth
                    onChange={handleChange}
                    name={props.name}
                    value={props.value}
                    >
                        <MenuItem value="0">
                            <em>{props.label}</em>
                        </MenuItem>
                        {
                            props.menuRender && props.menuRender.map(item =>(
                                <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </Paper>
    );
};

export default SelectField;