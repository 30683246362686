import React, { useEffect, useState } from 'react'
import { Box, Grid } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid';
import CustomSection from '../../components/CustomSection'
import TableComponent, { createColumns } from '../../components/TableComponent'
import { useAppContext } from '../../AppContext';
import EstadisticasApi from '../../api/estadisticas';

import { Casos } from '../../business/models/Casos';

import SelectField from '../../components/SelectField';
import AsesorHook from '../../hooks/AsesorHook';


const AsesorColumns : GridColDef[] = [
    createColumns({ headerName : "Asesor", field : "asesor" }),
    createColumns({ headerName : "Expedientes", field : "total_casos" }),
    createColumns({ headerName : "Enero", field : "Enero" }),
    createColumns({ headerName : "Febrero", field : "Febrero" }),
    createColumns({ headerName : "Marzo", field : "Marzo" }),
    createColumns({ headerName : "Abril", field : "Abril" }),
    createColumns({ headerName : "Mayo", field : "Mayo" }),
    createColumns({ headerName : "Junio", field : "Junio" }),
    createColumns({ headerName : "Julio", field : "Julio" }),
    createColumns({ headerName : "Agosto", field : "Agosto" }),
    createColumns({ headerName : "Septiembre", field : "Septiembre" }),
    createColumns({ headerName : "Octubre", field : "Octubre" }),
    createColumns({ headerName : "Noviembre", field : "Noviembre" }),
    createColumns({ headerName : "Diciembre", field : "Diciembre" }),
    createColumns({ headerName : "Totales", field : "Totales" }),
    createColumns({ headerName : "Porcentaje", field : "Porcentaje" }),
]
const AsesorAnioColumns : GridColDef[] = [
    createColumns({ headerName : "Año", field : "anio" }),
    createColumns({ headerName : "Expedientes", field : "total_casos" }),
    createColumns({ headerName : "Enero", field : "Enero" }),
    createColumns({ headerName : "Febrero", field : "Febrero" }),
    createColumns({ headerName : "Marzo", field : "Marzo" }),
    createColumns({ headerName : "Abril", field : "Abril" }),
    createColumns({ headerName : "Mayo", field : "Mayo" }),
    createColumns({ headerName : "Junio", field : "Junio" }),
    createColumns({ headerName : "Julio", field : "Julio" }),
    createColumns({ headerName : "Agosto", field : "Agosto" }),
    createColumns({ headerName : "Septiembre", field : "Septiembre" }),
    createColumns({ headerName : "Octubre", field : "Octubre" }),
    createColumns({ headerName : "Noviembre", field : "Noviembre" }),
    createColumns({ headerName : "Diciembre", field : "Diciembre" }),
    createColumns({ headerName : "Totales", field : "Totales" }),
    createColumns({ headerName : "Porcentaje", field : "Porcentaje" }),
]

function Asesores() {
    const { handleActivePage } = useAppContext()
    const [ datos, setDatos ] = useState<Casos[]>([])
    const [ porAsesor, setDatosPorAsesor ] = useState<Casos[]>([])
    const [ years, setYears ] = useState([])
    const [ year, setYear ] = useState("0")
    const [ asesor, setAsesor ] = useState("0")
    const { usuarios, getAsesores } = AsesorHook()

    useEffect(()=>{
        handleActivePage('Firmados por asesor')
        obtener_years_firmados()
        getAsesores()
    },[])

    useEffect(()=>{
        if(year !== "0")
            getByYear(year)
    },[year])

    useEffect(()=>{
        if(asesor !== "0")
            getByAsesor(asesor)
    },[asesor])

    const getByYear = async (year : string) =>{
        try {
            const { data } = await EstadisticasApi.getAsesoresFirmados(`year=${year}`)
            setDatos(data.casos)
        } catch (error) {
            
        }
    }

    const getByAsesor = async (asesor : string) =>{
        try {
            const { data } = await EstadisticasApi.getAsesoresFirmados(`asesor=${asesor}`)
            setDatosPorAsesor(data.casos)
        } catch (error) {
            
        }
    }

    const getYear = (name : string, value : string ) =>{
        setYear(value)
    }

    const getAsesor = (name : string, value : string ) =>{
        setAsesor(value)
    }

    const obtener_years_firmados = async() =>{
        try {
            const { data } = await EstadisticasApi.getYears()
            setYears(data.years.map((item : any) =>{ 
                return{
                    id : item.anio,
                    name : item.anio
                }
            }))
        } catch (error) {
            
        }
    }

    const rowSelected = () =>{

    }
    return (
        <CustomSection>
            <Grid container>
                <Grid item xs={12}>
                    <Grid item xs={12} display='flex' flexDirection="row" justifyContent='center'>
                        <Box style={{width : '100%'}}>
                            <SelectField label="Año firmado" menuRender={years} onChange={getYear} name="year"  />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <div style={{ height: '100%', width: '100%' }}> 
                            <TableComponent rows={datos} columns={AsesorColumns} rowCallback={ rowSelected } />
                        </div>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid item xs={12} display='flex' flexDirection="row" justifyContent='center'>
                        <Box style={{width : '100%'}}>
                            <SelectField label="Asesor" menuRender={usuarios as any} onChange={getAsesor} name="asesor"  />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <div style={{ height: '100%', width: '100%' }}>
                            <TableComponent rows={porAsesor} columns={AsesorAnioColumns} rowCallback={ rowSelected } />
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </CustomSection>
    )
}

export default Asesores