import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid';
import CustomSection from '../../components/CustomSection'
import TableComponent, { createColumns } from '../../components/TableComponent'
import { useAppContext } from '../../AppContext';
import EstadisticasApi from '../../api/estadisticas';
import { mapCasos } from '../../business/Validations';
import { Casos } from '../../business/models/Casos';

interface Props {

}

const columns: GridColDef[] = [
    createColumns({ headerName: "Asesor", field: "asesor", type: 'string' }),
    createColumns({ headerName: "Cliente", field: "cliente", type: 'string' }),
    createColumns({ headerName: "Fecha Expediente", field: "fecha_ingreso_banco", type: 'date' }),
    createColumns({ headerName: "Fecha Etapa", field: "fecha_ultima_fase", type: 'date' }),
    createColumns({ headerName: "Monto Solicitado", field: "monto_solicitado", sortable: true, type: "number" }),
    createColumns({ headerName: "Monto Aprobado", field: "monto_autorizado", sortable: true, type: "number" }),
    createColumns({ headerName: "Monto Firmado", field: "monto_firmado", sortable: true, type: "number" }),
    // createColumns({ headerName : "Folio Soc", field : "folio_soc", type : 'string'}),
    createColumns({ headerName: "Folio Banco", field: "folio_banco", type: 'string' }),
    createColumns({ headerName: "Banco", field: "nombre_banco", type: 'string' }),
    createColumns({ headerName: "Esquema", field: "nombre_esquema", type: 'string' }),
    createColumns({ headerName: "Apoyo", field: "apoyo", type: 'string' }),
    createColumns({ headerName: "Fase", field: "fase", sortable: true, type: 'string' }),
]

const Bitacora = (props: Props) => {
    const { handleActivePage } = useAppContext()
    const [datos, setDatos] = useState<Casos[]>([])

    useEffect(() => {
        handleActivePage('Bitacora')
        obtener_datos()
    }, [])

    const obtener_datos = async () => {
        try {
            const { data } = await EstadisticasApi.getBitacora()
            setDatos(mapCasos(data.bitacora))
        } catch (error) {
            console.log(error)
        }
    }

    const rowSelected = () => {

    }
    return (
        <CustomSection>
            <Grid container>
                <Grid item xs={12}>
                    <div style={{ height: '100%', width: '100%' }}>
                        <TableComponent rows={datos} columns={columns} rowCallback={rowSelected} />
                    </div>
                </Grid>
            </Grid>
        </CustomSection>
    )
}

export default Bitacora