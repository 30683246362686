import { List, ListItem, ListItemText, ListSubheader, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { format } from 'date-fns';
import { es } from 'date-fns/locale'
import React, { useState, useEffect } from 'react';
import ComentariosService from '../../api/comentarios';
import { useAppContext } from '../../AppContext';
import InputField from '../../components/InputForm';
import LoadingButtonComponent from '../../components/LoadingButton';
import { VerificarAccesos } from './TieneAcceso';
// import EnviarCorreo from 'components/Correo/EnviarCorreo';

interface IComentariosProps {
    id_caso: number,
    fase: string
}
const Comentarios = ({ id_caso, fase }: IComentariosProps) => {
    const [comentario, setComentario] = useState("")
    const [loading, setLoading] = useState(true)
    const [enviando, setEnviando] = useState(false)
    const [comentarios, setComentarios] = useState([])
    const { usuario } = useAppContext()
    const { buildContent } = VerificarAccesos()

    useEffect(() => {
        ObtenerComentariosCaso()
    }, [id_caso])


    const ObtenerComentariosCaso = async () => {
        try {
            let { data }: any = await ComentariosService.get(id_caso)
            setComentarios(data.comentarios)
            setLoading(false)
        } catch (error: any) {

        }
    }
    const guardarComentario = async () => {
        setEnviando(true)
        let payload = {
            id_caso: id_caso,
            comentario: comentario,
            fase: fase
        }
        try {
            await ComentariosService.guardar(payload)
            ObtenerComentariosCaso()
            setComentario("")
            setTimeout(() => {
                setEnviando(false)
            }, 2000)
        } catch (error: any) {
            setEnviando(false)
            console.log(error.response)
        }
    }

    return (
        <>
            <Box style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between'
            }}>
                <List sx={{
                    position: 'relative',
                    overflow: 'auto',
                    maxHeight: 300,
                    marginBottom: 5
                }}
                    subheader={<ListSubheader>Comentario</ListSubheader>}
                >
                    {
                        loading ? 'Cargando...' :
                            comentarios.length > 0 ? comentarios.map((item: any) => (
                                <ListItem button key={item.id}>
                                    <ListItemText primary={
                                        <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                            <Typography>{item.comentario}</Typography>
                                            {format(new Date(item.fecha), 'PP', { locale: es })}
                                        </Box>
                                    } secondary={item.usuario} />
                                </ListItem>
                            )) : 'No se encontraron comentarios'
                    }
                </List>
                {
                    ((Number(usuario!.tipo_usuario) & 7) > 0 || buildContent({ tipo_usuario: null, permisos: { comentarios: true } })) &&
                    <Box>
                        <InputField multiline onChange={(name: string, value: any) => setComentario(value)} rows={4} fullWidth value={comentario} />
                        <LoadingButtonComponent
                            callback={() => guardarComentario()}
                            label="Guardar"
                            loading={enviando}
                            color="primary"
                            sx={{ marginTop: 2 }}
                        />
                    </Box>
                }
            </Box>
        </>
    );
};

export default Comentarios;