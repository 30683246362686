import React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';

const Loading = ({open, handleClose}) =>{

    return (
        <Backdrop  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open} >
            <CircularProgress color="inherit"/>
        </Backdrop>
    );
}
export default Loading

export const LoadingHook = () =>{
    const [loading,
        setLoadig] = React.useState(false);
    
        const handleCloseLoading = () => {
        setLoadig(false);
    };

    const handleToggleLoading = () => {
        setLoadig(!loading);
    };

    return {
        loading,
        handleCloseLoading,
        handleToggleLoading
    }
}