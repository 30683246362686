import React, { useEffect, useState } from 'react'
import { useAppContext } from '../../AppContext';
import Grid from '@mui/material/Unstable_Grid2';
import { Card, CardContent, Typography, CardActions, Button, Divider, Checkbox, FormControlLabel, FormGroup, Alert } from '@mui/material';
import InputField from '../../components/InputForm';
import AsesoresApi from '../../api/asesores';

interface Props {

}

const Perfil = (props: Props) => {
    const { handleActivePage, usuario, openAlert } = useAppContext()

    const [passwords, setPasswords] = useState({
        password: '',
        password_confirm: ''
    });

    const [changePassword, setChangePassword] = useState(false)

    useEffect(() => {
        handleActivePage("Perfil")
    }, []);


    const getValueOfPassword = (name: string, value: any) => {
        setPasswords({ ...passwords, [name]: value })
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChangePassword(event.target.checked);
        if (!event.target.checked) {
            setPasswords({
                password: '',
                password_confirm: ''
            })
        }
    };

    const validatePassword = () => {
        if (passwords.password === '' && passwords.password_confirm === '') {
            return false
        }
        return passwords.password === passwords.password_confirm
    }

    const guardar = async () => {
        if (changePassword && !validatePassword()) {
            openAlert('error', "La contraseña no coincide, por favor verifiquelo.")
        }
        try {
            await AsesoresApi.cambiarPassword(usuario?.uid!, passwords.password)
            openAlert('success', "Contraseña actualizada")
        } catch (error) {
            openAlert('error', "Ocurrio un error al intentar actualizar la contraseña")
        }
    }
    return (
        <Grid container>
            <Grid xs={12} md={8} mdOffset={2}>
                <Card >
                    <CardContent>
                        <Typography sx={{
                            display: 'flex',
                            justifyContent: 'center'
                        }} variant='h6' color="text.secondary" gutterBottom>
                            {usuario?.nombre.toUpperCase()}
                        </Typography>
                        <Divider sx={{ marginBottom: 5 }} />

                        <Grid container spacing={2}>
                            <Grid xs={12}>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox checked={changePassword} onChange={handleChange} />} label="¿Deseas cambiar tu contraseña?" />
                                </FormGroup>
                            </Grid>
                            <Grid xs={6}>
                                <InputField onChange={getValueOfPassword} value={passwords.password} name="password" label="Nueva contraseña" type="password" fullWidth disabled={!changePassword} />
                            </Grid>
                            <Grid xs={6}>
                                <InputField onChange={getValueOfPassword} value={passwords.password_confirm} name="password_confirm" label="Repite la contraseña" type="password" fullWidth disabled={!changePassword} />
                            </Grid>
                        </Grid>
                        {
                            (changePassword && !validatePassword()) &&
                            <Alert variant="outlined" severity="error" sx={{ marginTop: 5 }}>
                                La contraseña esta vacio o no coincide, por favor verifiquelo.
                            </Alert>
                        }
                    </CardContent>
                    <CardActions>
                        <Button size="small" disabled={changePassword ? !validatePassword() : false} onClick={() => guardar()}>Guardar</Button>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
    )
}

export default Perfil