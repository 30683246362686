import httpService from "./Http"
class FasesService {
    
    static async casosFase(fase : string){
        return await new httpService().get(`v1/fases/casos/${fase}`)
    }
    static async avanzar(fase : string, id_caso : number, state : any){
        return await new httpService().post(`v1/fases/${fase}/${id_caso}`, state)
    }
    static async obtenerExpediente( id_caso : number){
        return await new httpService().get(`v1/expediente/${id_caso}`)
    } 
    static async obtener_por_vencer(){
        return await new httpService().get(`v1/vencimiento/por_vencer`)
    }
    static async obtener_vencidos(){
        return await new httpService().get(`v1/vencimiento/vencidos`)
    }
    static async reporte_diario_fase(fase : string){
        return await new httpService().get(`v1/fases/reporte_diario/${fase}`)
    }
    static async regresar_expediente(id_caso : number,fase : number){
        return await new httpService().put(`v1/fases/regresar_expediente/${id_caso}/${fase}`)
    }
}
export default FasesService