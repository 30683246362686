import { FormHelperText, Grid, Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import AdministradorApi from '../../api/administrador'
import InputField from '../../components/InputForm'
import LoadingButtonComponent from '../../components/LoadingButton'

export interface IDatosGenerales {
  nombre: string,
  direccion: string,
  telefonos: string
  logotipo: string
}
const DatosGenerales = () => {
  const [datos_generales, setGenerales] = useState<IDatosGenerales>({
    nombre: '',
    direccion: '',
    telefonos: '',
    logotipo: ''
  })
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    detalles()
  }, [])

  const detalles = async () => {
    try {
      const { data } = await AdministradorApi.obtener_detalles()
      if (data.detalles)
        setGenerales(data.detalles)
    } catch (error) {

    }
  }

  const obtenerDatos = (name: string, value: any) => {
    setGenerales({
      ...datos_generales, [name]: value
    })
  }

  const guardar = async () => {
    setLoading(true)
    try {
      await AdministradorApi.crear_detalles(datos_generales)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  return (
    <Grid container>
      <Grid item md={4} sm={12} sx={{ paddingBottom: 1, paddingRight: 1 }}>
        <InputField
          type="text"
          name="nombre"
          value={datos_generales.nombre}
          onChange={obtenerDatos}
          id="nombre"
          label="Nombre Empresa"
          fullWidth />
      </Grid>
      <Grid item md={4} sm={12} sx={{ paddingBottom: 1, paddingRight: 1 }}>
        <InputField
          type="text"
          name="direccion"
          value={datos_generales.direccion}
          onChange={obtenerDatos}
          id="Direccion"
          label="Direccion"
          fullWidth />
      </Grid>
      <Grid item md={4} sm={12} sx={{ paddingBottom: 1, paddingRight: 1 }}>
        <InputField
          type="text"
          name="telefonos"
          value={datos_generales.telefonos}
          onChange={obtenerDatos}
          id="Telefonos"
          label="Telefonos"
          fullWidth />
        <FormHelperText id="Telefonos">
          Puede agregar mas telefonos separandolos por (,)
        </FormHelperText>
      </Grid>
      <Grid item sm={12} sx={{ paddingBottom: 1, paddingRight: 1 }}>
        <Stack direction="row-reverse">
          <LoadingButtonComponent
            callback={() => guardar()}
            label="Guardar"
            loading={loading}
            color="success"
          />
        </Stack>
      </Grid>
    </Grid>
  )
}

export default DatosGenerales