import { Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { downloadCSV } from '../Validations'

interface Props {
    data : any[]
    columns : any[]
}

function ExportCsv(props : Props) {
    const [listo, setListo] = useState(false)

    useEffect(()=>{
        if(props.data.length > 0){
            setListo(true)
        }
        return () =>{
            setListo(false)
        }
    },[props.data])
    const exportar = () =>{
        downloadCSV(props.data, props.columns)
    }
    return (
        <Button disabled={!listo} onClick={exportar}>Exportar</Button>
    )
}

export default ExportCsv