import Reactfrom from 'react';
import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Constructoras from './pages/catalogos/Constructoras';
import Alta from './pages/cliente/Alta';
import Buscar from './pages/cliente/Buscar';
import FasesWrapper from './pages/fases/Index';
import Login from './pages/Login';
import NavigationScroll from './components/NavigationScroll';
import Expediente from './pages/cliente/Expediente';
import { Layout } from './components/Layout/Layout';
import { theme } from './themes/theme';
import AltaAsesor from './pages/asesores/Asesor'
import ListaAsesores from './pages/asesores/Lista';
import Bancos from './pages/catalogos/Bancos';
import Notarias from './pages/catalogos/Notarias';
import Valuadores from './pages/catalogos/Valuadores';
import EditarExpediente from './pages/cliente/EditarExpediente';
import Vencer from './pages/fases/Vencer';
import Principal from './pages/principal/Principal';
import AdminPage from './pages/admin/AdminPage';
import Bitacora from './pages/estadisticas/Bitacora';
import ListaClientes from './pages/cliente/Lista';
import Firmado from './pages/estadisticas/Firmado';
import Asesores from './pages/estadisticas/Asesores';
import BancosEstadisticas from './pages/estadisticas/Bancos';
import RecuperarPassword from './pages/RecuperarPassword';
import Vencidos from './pages/fases/Vencidos';
import ReporteDiario from './pages/estadisticas/ReporteDiario';
import ComparativoAnual from './pages/estadisticas/ComparativoAnualBancos';
import ComparativoAnualAsesores from './pages/estadisticas/ComparativoAnualAsesores';
import Perfil from './pages/usuario/Perfil';
import ThemeCustomization from './theme';

function App() {
  return (
    <BrowserRouter>
      <StyledEngineProvider injectFirst>
        <ThemeCustomization>
          <CssBaseline />
          <NavigationScroll>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/login" element={<Login />} />
              <Route path="/auth/recover_password/:token" element={<RecuperarPassword />} />
              <Route path="/admin" element={<Layout />}>
                <Route path="home" element={<AdminPage />} />
                <Route path="principal" element={<Principal />} />
                <Route path="cliente">
                  <Route path="buscar" element={<Buscar />} />
                  <Route path="editar/:id" element={<Alta />} />
                  <Route path="alta" element={<Alta />} />
                  <Route path="lista" element={<ListaClientes />} />
                </Route>
                <Route path="asesores">
                  <Route path="alta" element={<AltaAsesor />} />
                  <Route path="lista" element={<ListaAsesores />} />
                  <Route path="editar/:id" element={<AltaAsesor />} />
                </Route>
                <Route path="catalogo" >
                  <Route path="constructoras" element={<Constructoras />} />
                  <Route path="bancos" element={<Bancos />} />
                  <Route path="notarias" element={<Notarias />} />
                  <Route path="valuadores" element={<Valuadores />} />
                </Route>
                <Route path="expediente">
                  <Route path="crear/:id_cliente_asesor" element={<Expediente />} />
                  <Route path="editar/:id_caso" element={<EditarExpediente />} />
                  {/* <Route path="editar/:id_cliente_asesor" /> */}
                </Route>
                <Route path="fases">
                  <Route path=":fase" element={<FasesWrapper />} />
                  <Route path="venciendo" element={<Vencer />} />
                  <Route path="vencidos" element={<Vencidos />} />
                </Route>
                <Route path="estadistica">
                  <Route path="bitacora" element={<Bitacora />} />
                  <Route path="firmados" element={<Firmado />} />
                  <Route path="asesores" element={<Asesores />} />
                  <Route path="bancos" element={<BancosEstadisticas />} />
                  <Route path="comparativo/bancos" element={<ComparativoAnual />} />
                  <Route path="comparativo/asesores" element={<ComparativoAnualAsesores />} />
                </Route>
                <Route path="reporte">
                  <Route path="diario" element={<ReporteDiario />} />
                </Route>
                <Route path="perfil" element={<Perfil />} />
              </Route>

              {/* <Route path="*" element={<NotFound />} /> */}
            </Routes>
          </NavigationScroll>
        </ThemeCustomization>
      </StyledEngineProvider>
    </BrowserRouter>
  );
}

export default App;
