import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';

import ExpedientesApi from '../api/expedientes';
import BancoHooks from './BancoHooks';
import { format } from 'date-fns';
import { useAppContext } from '../AppContext';

const initialState = {
    fecha_ingreso_banco: format(new Date(), 'yyyy/MM/dd'),
    id_banco: 0,
    id_esquema: 0,
    id_apoyo: 0,
    id_destino: 0,
    tasa: 0,
    monto_solicitado: 0,
    ejecutivo_banco: '',
    plazo: 0,
    factor_pago: '',
    comision_apertura: '',
    pago_adelantado: '',
    folio_banco: '',
    folio_soc: '',
    incremento_factor_pago: '',

}
const ExpedienteHook = () => {
    const { handleToggleLoading, handleCloseLoading, handleActivePage, openAlert, obtener_fases } = useAppContext()
    const { bancos, obtenerBancos, esquemas_banco } = BancoHooks()
    const [expediente, setExpediente] = useState(initialState)
    const [esquemas, setEsquemas] = useState([])
    const [apoyos, setApoyos] = useState([])
    const [destino, setDestino] = useState([])
    const [id, setId] = useState<string>("0")
    const navigate = useNavigate()

    useEffect(() => {
        obtenerBancos()
        getApoyos()
    }, [])

    useEffect(() => {
        if (expediente.id_banco) {
            renderEsquemas()
        }
    }, [expediente.id_banco])

    const renderEsquemas = async () => {
        setEsquemas(await esquemas_banco(expediente.id_banco))
    }

    const getApoyos = async () => {
        const { data } = await ExpedientesApi.getApoyos()
        setApoyos(data.apoyos.map((item: any) => {
            item.name = item.nombre_apoyo
            return item
        }))
    }

    const crear = async () => {
        handleToggleLoading()
        try {
            let { data } = await ExpedientesApi.crear(id, expediente)
            openAlert('success', data.message)
            handleCloseLoading()
            obtener_fases()
            setTimeout(() => {
                navigate('/admin/cliente/buscar', { replace: true })
            }, 1500);
        } catch (error: any) {
            const { data } = error.response
            handleCloseLoading()
            openAlert('warning', data.message)
        }
    }

    const setDatos = (name: string, value: any) => {
        setExpediente({
            ...expediente, [name]: value
        })
    }
    const destino_credito = async () => {
        try {
            let { data } = await ExpedientesApi.destino_creditos()
            setDestino(data.destinos.map((item: any) => {
                item.name = item.nombre
                item.id = item.id_destino
                return item
            }))
        } catch (error) {

        }
    }
    const obtener_expediente = async (id_caso: string) => {
        try {
            handleToggleLoading()
            let { data } = await ExpedientesApi.expediente(id_caso)
            console.log(data)
            setExpediente(data.expediente)
            handleCloseLoading()
        } catch (error) {

        }
    }
    const actualizar = async (id_caso: string) => {
        handleToggleLoading()
        try {
            let { data } = await ExpedientesApi.update(id_caso, expediente)
            openAlert('success', data.message)
            handleCloseLoading()
        } catch (error: any) {
            handleCloseLoading()
            const { data } = error.response
            openAlert('warning', data.message)
        }
    }
    return {
        handleActivePage,
        expediente,
        bancos,
        esquemas,
        apoyos,
        crear,
        setDatos,
        setId,
        obtener_expediente,
        actualizar,
        destino_credito,
        destino
    }
};

export default ExpedienteHook;