import { useState, useEffect } from 'react';
import Valuadores from '../api/valuadores';
import { useAppContext } from '../AppContext';
import { Valuador } from '../business/models/Valuadores';

const ValuadoresHooks = (estatus = 0) => {
    const { openAlert, handleActivePage, handleToggleLoading, handleCloseLoading } = useAppContext()
    const [valuadores, setValuadores] = useState<Valuador[]>([])
    const [valuador, setValuador] = useState<Valuador>({ id: "0", name: "", nombre_valuador: '', correo: '', telefono: '', buscar: '', activo: 1 });
    const [seleccion, setSeleccion] = useState<null | string>(null)

    useEffect(() => {
        obtener_valuadores(estatus)
    }, [])


    const setNombrePagina = () => {
        handleActivePage('Valuadores')
    }
    const obtenerDatos = (name: string, value: any) => {
        setValuador({
            ...valuador,
            [name]: value
        });
    };

    const obtener_valuadores = async (estatus: number) => {
        try {
            let { data } = await Valuadores.all(estatus)
            setValuadores(data.valuadores.map((item: any) => {
                item.name = item.nombre_valuador
                return item
            }))
        } catch (error) { }
    }

    const nuevo_valuador = async () => {
        try {
            handleToggleLoading()
            await Valuadores.create(valuador)
            openAlert('success', 'Valuador registrado')
            clearSelection()
            await obtener_valuadores(0)
            handleCloseLoading()
        } catch (error) {
            openAlert('warning', 'Hubo un error al registrar la unidad de valuacion')
        }
    }

    const editar = (id: string) => {
        const data = valuadores.find(x => x.id === id)
        if (data) {
            setValuador(data as Valuador)
        }
    }

    const clearSelection = () => {
        setValuador({ id: "0", name: "", nombre_valuador: '', correo: '', telefono: '', buscar: '', activo: 1 })
    }

    const actualizar = async (id: string) => {
        try {
            await Valuadores.update(id, { nombre_valuador: valuador.nombre_valuador, correo: valuador.correo, telefono: valuador.telefono })
            openAlert('success', 'Valuador actualizado')
            clearSelection()
            obtener_valuadores(0)
        } catch (error: any) {
            openAlert('error', error.response.data.message)
        }
    }
    const actualizarEstatus = async (id: string, activo: boolean) => {
        try {
            await Valuadores.update(id, { activo })
            openAlert('success', 'Valuador actualizado')
            await obtener_valuadores(0)
            setSeleccion(null)
        } catch (error: any) {
            openAlert('error', error.response.data.message)
        }
    }
    return { valuador, valuadores, obtenerDatos, nuevo_valuador, setNombrePagina, seleccion, setSeleccion, clearSelection, actualizarEstatus, editar, actualizar };
};

export default ValuadoresHooks;
