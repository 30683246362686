import { Box, Container } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useAppContext } from '../../AppContext'
import VencerCard from './VencerCard'
import VencidosCard from './VencidosCard'
import CircularProgress from '@mui/material/CircularProgress';
import ExpedientesApi from '../../api/expedientes'
import { Comunicado } from '../../business/models/Comunicados'
import ComunicadosService from '../../api/comunicados'
import ComunicadoItem from '../admin/ComunicadoItem'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import FirmasCard from './FirmasCard'
import { format_to_number } from '../../business/Validations'
import PorFirmarCard from './PorFirmarCard'

type Props = {}

const Principal = (props: Props) => {
    const { handleActivePage, cantidades } = useAppContext()
    const [montoFirmar, setMonto] = useState(0)
    const [comunicados, setComunicados] = useState<Comunicado[]>([])

    useEffect(() => {
        handleActivePage(`Bienvenido `)
        obtener_por_firmar()
        getComunicados()
    }, [])

    const obtener_por_firmar = async () => {
        try {
            const { data } = await ExpedientesApi.monto_actual_firmar()
            setMonto(data.monto_a_firmar)
        } catch (error) {

        }
    }

    const getComunicados = async () => {
        try {
            const { data } = await ComunicadosService.get()
            setComunicados(data.comunicados)
        } catch (error) {

        }
    }
    return (
        <Box component="main" sx={{
            flexGrow: 1,
        }}>
            <Container maxWidth={false}>
                {
                    cantidades !== null ?
                        <Grid container spacing={3}>
                            <Grid lg={3} sm={6} xl={3} xs={12}>
                                <VencidosCard cantidad={Number(cantidades.vencidos)} />
                            </Grid>
                            <Grid lg={3} sm={6} xl={3} xs={12}>
                                <VencerCard cantidad={Number(cantidades.venciendo)} />
                            </Grid>
                            <Grid lg={3} sm={6} xl={3} xs={12}>
                                <FirmasCard cantidad={format_to_number(Number(cantidades.proyecciones.monto_por_firmar))} proyectado={format_to_number(Number(cantidades.proyecciones.monto_proyectado))} expedientes={cantidades.proyecciones.por_firmar} />
                            </Grid>
                            <Grid lg={3} sm={6} xl={3} xs={12}>
                                <PorFirmarCard cantidad={format_to_number(Number(cantidades.proyecciones.monto_firmado))} expedientes={cantidades.proyecciones.firmados} />
                            </Grid>
                        </Grid>
                        :
                        <Grid container>
                            <Grid xs={12}>
                                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                    <CircularProgress />
                                </Box>
                            </Grid>
                        </Grid>
                }
                <Grid container spacing={2}>
                    {
                        comunicados.map((item, index) => (
                            <Grid key={index} xs={12} md={4}>
                                <ComunicadoItem titulo={item.titulo} contenido={item.contenido} />
                            </Grid>
                        ))
                    }
                </Grid>
            </Container>
        </Box>
    )
}

export default Principal