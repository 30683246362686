import { Button, Grid } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import CustomSection from '../../components/CustomSection';
import InputField from '../../components/InputForm';
import SelectField from '../../components/SelectField';
import ExpedienteHook from '../../hooks/ExpedienteHook';

const EditarExpediente = () => {
    const { id_caso } = useParams()
    const { expediente, bancos, esquemas, apoyos, setDatos, obtener_expediente, actualizar, destino_credito, destino, handleActivePage } = ExpedienteHook()

    useEffect(() => {
        handleActivePage("Editar Expediente")
        if (id_caso) {
            obtener_expediente(id_caso)
            destino_credito()
        }
    }, [id_caso])

    const getValueFromInput = (name: string, value: any) => {
        if (!isNaN(value)) {
            value = parseFloat(value)
        }
        setDatos(name, value)
    }

    return (
        <CustomSection>
            <>
                <Grid container>
                    <Grid item md={4} xs={12} style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between'
                    }}>
                        <Box sx={{ paddingBottom: 1, paddingRight: 1 }}>
                            <SelectField
                                label="Selecciona apoyo"
                                menuRender={apoyos}
                                onChange={getValueFromInput}
                                value={expediente.id_apoyo}
                                name="id_apoyo"
                            />
                        </Box>
                        <Box sx={{ paddingBottom: 1, paddingRight: 1 }}>
                            <SelectField
                                label="Selecciona banco"
                                disabled={true}
                                menuRender={bancos}
                                onChange={getValueFromInput}
                                value={expediente.id_banco}
                                name="id_banco"
                            />
                        </Box>
                        <Box sx={{ paddingBottom: 1, paddingRight: 1 }}>
                            <SelectField
                                label="Selecciona esquema"
                                menuRender={esquemas}
                                onChange={getValueFromInput}
                                value={expediente.id_esquema}
                                name="id_esquema"
                            />
                        </Box>
                        <Box sx={{ paddingBottom: 1, paddingRight: 1 }}>
                            <SelectField
                                label="Selecciona destino del credito"
                                menuRender={destino}
                                onChange={getValueFromInput}
                                value={expediente.id_destino}
                                name="id_destino"
                            />
                        </Box>
                    </Grid>
                    <Grid item md={8} xs={12}>
                        <Grid container>
                            <Grid container item md={4} sx={{ paddingBottom: 1, paddingRight: 1 }}>
                                <Box width={1}>
                                    <InputField onChange={getValueFromInput} value={expediente.monto_solicitado} name="monto_solicitado" label="Monto solicitado" type="number" fullWidth />
                                    <span> $ {new Intl.NumberFormat().format(expediente.monto_solicitado)}</span>
                                </Box>
                            </Grid>
                            <Grid container item md={4} sx={{ paddingBottom: 1, paddingRight: 1 }}>
                                <Box width={1}>
                                    <InputField onChange={getValueFromInput} value={expediente.ejecutivo_banco} name="ejecutivo_banco" label="Ejecutivo banco" type="text" fullWidth />
                                </Box>
                            </Grid>
                            <Grid container item md={4} sx={{ paddingBottom: 1, paddingRight: 1 }}>
                                <Box width={1}>
                                    <InputField onChange={getValueFromInput} value={expediente.plazo} name="plazo" label="Plazo" type="number" fullWidth />
                                </Box>
                            </Grid>
                            <Grid container item md={4} sx={{ paddingBottom: 1, paddingRight: 1 }}>
                                <Box width={1}>
                                    <InputField onChange={getValueFromInput} value={expediente.tasa} name="tasa" label="Tasa de interes" type="number" fullWidth />
                                </Box>
                            </Grid>
                            <Grid container item md={4} sx={{ paddingBottom: 1, paddingRight: 1 }}>
                                <Box width={1}>
                                    <InputField onChange={getValueFromInput} value={expediente.factor_pago} name="factor_pago" label="Factor de pago" type="number" fullWidth />
                                </Box>
                            </Grid>
                            <Grid container item md={4} sx={{ paddingBottom: 1, paddingRight: 1 }}>
                                <Box width={1}>
                                    <InputField onChange={getValueFromInput} value={expediente.comision_apertura} name="comision_apertura" label="Comision por apertura" type="number" fullWidth />

                                </Box>
                            </Grid>
                            <Grid container item md={4} sx={{ paddingBottom: 1, paddingRight: 1 }}>
                                <Box width={1}>
                                    <InputField onChange={getValueFromInput} value={expediente.pago_adelantado} name="pago_adelantado" label="Comision pago adelantado" type="number" fullWidth />
                                </Box>
                            </Grid>
                            <Grid container item md={4} sx={{ paddingBottom: 1, paddingRight: 1 }}>
                                <Box width={1}>
                                    <InputField onChange={getValueFromInput} value={expediente.incremento_factor_pago} name="incremento_factor_pago" label="Incremento factor de pago" type="number" fullWidth />
                                </Box>
                            </Grid>
                            <Grid container item md={4} sx={{ paddingBottom: 1, paddingRight: 1 }}>
                                <Box width={1}>
                                    <InputField onChange={getValueFromInput} value={expediente.folio_banco} name="folio_banco" label="Folio banco" type="text" fullWidth />
                                </Box>
                            </Grid>
                            {/* <Grid container item md={4} sx={{ paddingBottom : 1,paddingRight : 1}}>
                                <Box width={1}>
                                    <InputField onChange={ getValueFromInput } value={expediente.folio_soc} name="folio_soc" label="Folio Soc" type="text" fullWidth/>
                                </Box>
                            </Grid> */}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container sx={{ marginTop: 2 }}>
                    <Grid item md={12}>
                        <Box display='flex' flexDirection='row-reverse'>
                            <Button variant='contained' color='success' onClick={() => actualizar(id_caso!)}>Actualizar</Button>
                        </Box>
                    </Grid>
                </Grid>
            </>
        </CustomSection>
    );
};

export default EditarExpediente;