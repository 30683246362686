import React from 'react';
import { DataGrid, GridColDef, GridValueFormatterParams, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid';
import { format_to_number } from '../business/Validations';
import ExportCsv from '../business/components/ExportCsv';
import { Box } from '@mui/material';

interface TableProps {
    rows: any[],
    columns: GridColDef[],
    rowCallback: (row: any) => void
    width?: number
}
export const createColumns = (props: GridColDef) => {
    return {
        field: props.field,
        headerName: props.headerName,
        width: props.width ?? 250,
        type: props.type,
        sortable: props.sortable,
        //@ts-ignore
        valueFormatter: function (params: GridValueFormatterParams<number>) {
            if (params.field.includes('monto')) {
                return format_to_number(params.value)
            }
            return params.value
        }
    }
}
function CustomToolbar(rows: any[], columns: any[]) {
    return (
        <GridToolbarContainer>
            {/* @ts-ignore */}
            <GridToolbarFilterButton />
            <ExportCsv data={rows} columns={columns} />
        </GridToolbarContainer>
    );
}
const TableComponent = (props: TableProps) => {
    return (
        <Box sx={{ width: '100%' }}>
            <DataGrid
                autoHeight
                density='compact'
                showCellRightBorder={true}
                showColumnRightBorder={true}
                rows={props.rows}
                columns={props.columns}
                pageSize={100}
                rowsPerPageOptions={[100]}
                onRowClick={(params) => props.rowCallback(params)}
                autoPageSize
                components={{
                    Toolbar: () => CustomToolbar(props.rows, props.columns),
                }}
            />
        </Box>
    );
};

export default TableComponent;