import React, { useEffect, useState } from 'react'
import { Grid, Stack, Box, Button, List, ListItem, IconButton, ListItemButton, ListItemIcon, Checkbox, ListItemText, Divider, Alert, AlertTitle } from '@mui/material'
import InputField from '../../components/InputForm'
import LoadingButtonComponent from '../../components/LoadingButton'

import AdministradorApi from '../../api/administrador'
import { useAppContext } from '../../AppContext'
import ApartmentIcon from '@mui/icons-material/Apartment';

const Oficinas = () => {
  const [ loading, setLoading ] = useState(false)
  const [ oficina, setOficina ] = useState('')
  const [ oficinas, setOficinas ] = useState<any[]>([])
  const {  openAlert } = useAppContext()

  useEffect(()=>{
    obtener_oficinas()
  },[])
  const obtener_oficinas = async() =>{
    try {
      const { data } = await AdministradorApi.oficinas()
      setOficinas(data.oficinas.map((item : any)=>{
        item.acciones = <Button color='success' size='small' > Activar </Button>
        return item
      }))
    } catch (error) {
      
    }
  }

  const obtenerDatos = (name : string, value : any) =>{
    setOficina(value)
  }

  const guardar = async () =>{
    if(oficina === ""){
      return
    }
    try {
      setLoading(true)
      const { data } = await AdministradorApi.create(oficina)
      openAlert('success',data.message)
      setOficina('')
      await obtener_oficinas()
      setLoading(false)
    } catch (error) {
      console.log({error})
      openAlert('warning',"Error al intentar crear la oficina")
      setLoading(false)
    }
  }
  const actualizar_lista = async () =>{
    try {
      setLoading(true)
      const lista = oficinas.map(item=>{
        return{
          id : item.id,
          estatus : item.estatus ? 1 : 0
        }
      })
      await AdministradorApi.actualizarLista(lista)
      await obtener_oficinas()
      setLoading(false)
    } catch (error) {
      setLoading(false)
      
    }
  }

  const handleToggle = (value : number) => () => {
    const copia = oficinas.map((item:any) =>{
      if(item.id === value){
        item.estatus = !item.estatus
      }
      return item
    })
    setOficinas(copia)
  }

  return (
    <Grid container>
      <Grid item xs={12}>
          <InputField
            type="text"
            name="oficina"
            value={oficina}
            onChange={obtenerDatos}
            id="oficina"
            label="Registrar nueva oficina"
            fullWidth/>

            <Stack direction="row-reverse" sx={{ marginTop : 5}}>
                <LoadingButtonComponent
                    callback ={ () => guardar() }
                    label = "Guardar"
                    loading = { loading }
                    color="success"
                />
            </Stack>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{marginTop : 2, marginBottom : 2}} />
        <Box>
          <Alert severity="info">
            <AlertTitle>Nota:</AlertTitle>
            Desmarca o Marca la casilla para activar o desactivar la oficina
          </Alert>
        </Box>
        <h5>Oficinas</h5>
        <Box sx={{ height: 300, width: '100%' }}>
          <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            {oficinas.map((item : any) => {
              const labelId = `checkbox-list-label-${item.id}`;

              return (
                <ListItem
                  key={item.id}
                  secondaryAction={
                    <IconButton edge="end" aria-label="office">
                      <ApartmentIcon />
                    </IconButton>
                  }
                  disablePadding
                >
                  <ListItemButton role={undefined} onClick={handleToggle(item.id)} dense>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={Boolean(Number(item.estatus))}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </ListItemIcon>
                    <ListItemText id={labelId} primary={`${item.nombre }`} />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </Box>
        <Stack direction="row-reverse" sx={{ marginTop : 5}}>
                <LoadingButtonComponent
                    callback ={ () => actualizar_lista() }
                    label = "Guardar"
                    loading = { loading }
                    color="success"
                />
          </Stack>
      </Grid>
    </Grid>
  )
}

export default Oficinas