import { Box } from '@mui/material';
import React from 'react';
import DateSelector from '../../components/DateSelector';
import { IFaseProps } from './Index';

const Cobranza = ({ handlechange,setDates,state } : IFaseProps) =>{
    return (
        <Box style={{ flexDirection: 'column', justifyContent : 'space-around'}}>
            <Box>
                <DateSelector
                    label = "Fecha de Cobranza"
                    retrieveDate={( date : string ) => setDates("fecha_comision", date) }
                />
            </Box>
        </Box>
    )
};

export default Cobranza;