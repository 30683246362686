import React, { useEffect, useState } from 'react';
import { AppBar, Box, Toolbar, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Outlet, useLocation } from 'react-router-dom';
import SpeedDialTooltipOpen from '../FasesFloat';
import AppProvider, { useAppContext } from '../../AppContext';
import SidebarComponent from './SidebarComponent';
import { onMessageListener } from '../../services/FirebaseService';
import Header from './Header';
import MainDrawer from './Drawer';

const DashboardLayoutRoot = styled('div')(({ theme }) => ({
    display: 'flex',
    flex: '1 1 auto',
    maxWidth: '100%',
    paddingTop: 64,

}));

const PrivateRoutes = (props: any) => {
    const { activePage, usuario, cantidades } = useAppContext()
    const [isSidebarOpen, setSidebarOpen] = useState(false);
    const [rightBar, setRightBar] = useState(false);
    const location = useLocation()

    useEffect(() => {
        //GetToken(setMessaginToken)
    }, [])

    onMessageListener().then(payload => {
        console.log(payload);
    }).catch(err => console.log('failed: ', err));

    useEffect(() => {
        if (isSidebarOpen) {
            setSidebarOpen(false)
        }
    }, [location])

    return (
        usuario !== null ?
            <>
                <DashboardLayoutRoot>
                    <Box
                        sx={{
                            display: 'flex',
                            flex: '1 1 auto',
                            flexDirection: 'column',
                            width: '100%',
                            margin: 5
                        }}>
                        <Box sx={{ flexGrow: 1, marginBottom: 5 }}>
                            <AppBar position="static" color='inherit'>
                                <Toolbar>
                                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                                        {activePage}
                                    </Typography>
                                </Toolbar>
                            </AppBar>
                        </Box>
                        <Outlet />
                    </Box>
                </DashboardLayoutRoot>
                <Header open={isSidebarOpen} handleDrawerToggle={() => setSidebarOpen(!isSidebarOpen)} />
                <MainDrawer open={isSidebarOpen} handleDrawerToggle={() => setSidebarOpen(!isSidebarOpen)} />
                <SpeedDialTooltipOpen handleOpen={() => setRightBar(true)} />
                <SidebarComponent anchor='right' open={rightBar} onClose={() => setRightBar(false)} cantidades={cantidades} />
            </>
            : null
    );
};

export const Layout = () => {
    return (
        <AppProvider>
            <PrivateRoutes />
        </AppProvider>
    )
}