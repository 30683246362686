import { useState } from 'react';
import FasesService from '../api/fases';
import { useAppContext } from '../AppContext';
import { Casos } from '../business/models/Casos';
import { Fases as FasesList } from '../business/models/Fases';
import { mapCasos } from '../business/Validations';

const FasesHook = (  ) => {
    const [ casos, setCasos ] = useState<Casos[]>([])
    const [ casoSeleccionado, setCasoSeleccionado ] = useState<Casos | null>(null)
    const [loading, setLoading] = useState(true)
    const { openAlert } = useAppContext()
    
    const obtener_casos = async (fase : FasesList) =>{
        try {
            let { data } : { data : { casos : Casos[]} } = await FasesService.casosFase(fase.toLowerCase())
            setCasos( mapCasos(data.casos) )
            setLoading(false)
        } catch (error) {
            console.log(error)
        }
    }

    const faseExists = (fase : string ) =>{
        return FasesList.hasOwnProperty(fase.toUpperCase());
    }

    const regresar_expediente = async (id_caso : number, fase : number) =>{
        try {
            let { data } = await FasesService.regresar_expediente(id_caso,fase)
            openAlert('success',data.message)
        } catch (error : any) {
            console.log(error);
            openAlert( 'warning', 'Ocurrio un erro al intentar regresar el expediente de etapa')
        }
    }

    return {
        casos,
        casoSeleccionado,
        loading,
        setLoading,
        setCasoSeleccionado,
        obtener_casos,
        faseExists,
        regresar_expediente
    }
};

export default FasesHook;