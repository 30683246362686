import React, { useEffect, useState } from 'react'
import { Box, Grid } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid';
import CustomSection from '../../components/CustomSection'
import TableComponent, { createColumns } from '../../components/TableComponent'
import { useAppContext } from '../../AppContext';
import EstadisticasApi from '../../api/estadisticas';
import { Casos } from '../../business/models/Casos';

import SelectField from '../../components/SelectField';
import BancoHooks from '../../hooks/BancoHooks';

interface Props {}

const BancoColumns : GridColDef[] = [
    createColumns({ headerName : "Banco", field : "banco" }),
    createColumns({ headerName : "Expedientes", field : "total_casos" }),
    createColumns({ headerName : "Enero", field : "Enero" }),
    createColumns({headerName : 'Exp. Enero'  ,field : 'cant_enero'}),
    createColumns({ headerName : "Febrero", field : "Febrero" }),
    createColumns({headerName : 'Exp. Febrero'  ,field : 'cant_febrero'}),
    createColumns({ headerName : "Marzo", field : "Marzo" }),
    createColumns({headerName : 'Exp. Marzo'  ,field : 'cant_marzo'}),
    createColumns({ headerName : "Abril", field : "Abril" }),
    createColumns({headerName : 'Exp.Abril'  ,field : 'cant_abril'}),
    createColumns({ headerName : "Mayo", field : "Mayo" }),
    createColumns({headerName : 'Exp. Mayo'  ,field : 'cant_mayo'}),
    createColumns({ headerName : "Junio", field : "Junio" }),
    createColumns({headerName : 'Exp. Junio'  ,field : 'cant_junio'}),
    createColumns({ headerName : "Julio", field : "Julio" }),
    createColumns({headerName : 'Exp. Julio'  ,field : 'cant_julio'}),
    createColumns({ headerName : "Agosto", field : "Agosto" }),
    createColumns({headerName : 'Exp. Agosto'  ,field : 'cant_agosto'}),
    createColumns({ headerName : "Septiembre", field : "Septiembre" }),
    createColumns({headerName : 'Exp. Septiembre'  ,field : 'cant_septiembre'}),
    createColumns({ headerName : "Octubre", field : "Octubre" }),
    createColumns({headerName : 'Exp. Octubre'  ,field : 'cant_octubre'}),
    createColumns({ headerName : "Noviembre", field : "Noviembre" }),
    createColumns({headerName : 'Exp. Noviembre'  ,field : 'cant_noviembre'}),
    createColumns({ headerName : "Diciembre", field : "Diciembre" }),
    createColumns({headerName : 'Exp. Diciembre'  ,field : 'cant_diciembre'}),
    createColumns({ headerName : "Totales", field : "Totales" }),
    createColumns({ headerName : "Porcentaje", field : "Porcentaje" }),
]
const BancoAnioColumns : GridColDef[] = [
    createColumns({ headerName : "Año", field : "anio" }),
    createColumns({ headerName : "Expedientes", field : "total_casos" }),
    createColumns({ headerName : "Enero", field : "Enero" }),
    createColumns({ headerName : "Febrero", field : "Febrero" }),
    createColumns({ headerName : "Marzo", field : "Marzo" }),
    createColumns({ headerName : "Abril", field : "Abril" }),
    createColumns({ headerName : "Mayo", field : "Mayo" }),
    createColumns({ headerName : "Junio", field : "Junio" }),
    createColumns({ headerName : "Julio", field : "Julio" }),
    createColumns({ headerName : "Agosto", field : "Agosto" }),
    createColumns({ headerName : "Septiembre", field : "Septiembre" }),
    createColumns({ headerName : "Octubre", field : "Octubre" }),
    createColumns({ headerName : "Noviembre", field : "Noviembre" }),
    createColumns({ headerName : "Diciembre", field : "Diciembre" }),
    createColumns({ headerName : "Totales", field : "Totales" }),
    createColumns({ headerName : "Porcentaje", field : "Porcentaje" }),
]

function Bancos({} : Props) {
    const { handleActivePage } = useAppContext()
    const [ datos, setDatos ] = useState<Casos[]>([])
    const [ porBanco, setDatosPorBanco ] = useState<Casos[]>([])
    const { bancos,obtenerBancos } = BancoHooks()
    const [ years, setYears ] = useState([])
    const [ year, setYear ] = useState("0")
    const [ banco, setBanco ] = useState("0")

    useEffect(()=>{
        handleActivePage('Firmados por banco')
        obtener_years_firmados()
        obtenerBancos()
    },[])

    useEffect(()=>{
        if(year !== "0")
            getByYear(year)
    },[year])

    useEffect(()=>{
        if(banco !== "0")
            getByBanco(banco)
    },[banco])

    const getByYear = async (year : string) =>{
        try {
            const { data } = await EstadisticasApi.getFirmadosBanco(`year=${year}`)
            setDatos(data.casos)
        } catch (error) {
            
        }
    }

    const getByBanco = async (Banco : string) =>{
        try {
            const { data } = await EstadisticasApi.getFirmadosBanco(`banco=${Banco}`)
            setDatosPorBanco(data.casos)
        } catch (error) {
            
        }
    }

    const getYear = (name : string, value : string ) =>{
        setYear(value)
    }

    const getBanco = (name : string, value : string ) =>{
        setBanco(value)
    }

    const obtener_years_firmados = async() =>{
        try {
            const { data } = await EstadisticasApi.getYears()
            setYears(data.years.map((item : any) =>{ 
                return{
                    id : item.anio,
                    name : item.anio
                }
            }))
        } catch (error) {
            
        }
    }

    const rowSelected = () =>{

    }
    return (
        <CustomSection>
            <Grid container>
                <Grid item xs={12}>
                    <Grid item xs={12} display='flex' flexDirection="row" justifyContent='center'>
                        <Box style={{width : '100%'}}>
                            <SelectField label="Año firmado" menuRender={years} onChange={getYear} name="year"  />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <div style={{ height: '100%', width: '100%' }}>
                            <TableComponent rows={datos} columns={BancoColumns} rowCallback={ rowSelected } />
                        </div>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid item xs={12} display='flex' flexDirection="row" justifyContent='center'>
                        <Box style={{width : '100%'}}>
                            <SelectField label="Banco" menuRender={bancos as any} onChange={getBanco} name="Banco"  />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <div style={{ height: '100%', width: '100%' }}>
                            <TableComponent rows={porBanco} columns={BancoAnioColumns} rowCallback={ rowSelected } />
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </CustomSection>
    )
}

export default Bancos