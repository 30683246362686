import React, { useEffect, useRef, useState } from 'react';

// material-ui
import { Theme, useTheme } from '@mui/material/styles';
import {
  Avatar,
  Badge,
  Box,
  ClickAwayListener,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Paper,
  Popper,
  Typography,
  useMediaQuery
} from '@mui/material';

// project import
import MainCard from '../../../MainCard';
import Transitions from '../../../@extended/Transitions';

// assets
import { BellOutlined, CloseOutlined, GiftOutlined, MessageOutlined, SettingOutlined } from '@ant-design/icons';
import { useAppContext } from '../../../../AppContext';
import Clientes from '../../../../api/clientes';
import { Notificaciones, NotificationType } from '../../../../business/models/Notificaciones';
import { VerificarAccesos } from '../../../../business/components/TieneAcceso';

// sx styles
const avatarSX = {
  width: 36,
  height: 36,
  fontSize: '1rem'
};

const actionSX = {
  mt: '6px',
  ml: 1,
  top: 'auto',
  right: 'auto',
  alignSelf: 'flex-start',

  transform: 'none'
};

// ==============================|| HEADER CONTENT - NOTIFICATION ||============================== //

const Notification = () => {
  const theme: any = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
  const anchorRef = useRef<any>(null);
  const [open, setOpen] = useState(false);
  const [notificaciones, setNotificaciones] = useState<Notificaciones[]>([])
  const { buildContent } = VerificarAccesos()

  useEffect(() => {
    if (buildContent({ tipo_usuario: 3, permisos: { clientes: true } })) {
      obtenerBirthdays()
    }
  }, [])

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const obtenerBirthdays = async () => {
    try {
      const { data } = await Clientes.obtenerBirthdays()
      setNotificaciones(notificaciones.concat(data.clientes.map((item: any) => {
        return {
          content: item.correo,
          title: `Hoy es cumple de ${item.nombre} ${item.apellido_paterno} ${item.apellido_materno}`,
          type: NotificationType.BIRTHDAY,
          callback: () => { }
        }
      })))
    } catch (error) {
      console.log(error);
    }
  }

  const iconBackColorOpen = 'grey.300';
  const iconBackColor = 'grey.100';

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <IconButton
        disableRipple
        color="secondary"
        sx={{ color: 'text.primary', bgcolor: open ? iconBackColorOpen : iconBackColor }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Badge badgeContent={notificaciones.length} color="primary">
          <BellOutlined />
        </Badge>
      </IconButton>
      <Popper
        placement={matchesXs ? 'bottom' : 'bottom-end'}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [matchesXs ? -5 : 0, 9]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          // @ts-ignore
          <Transitions type="fade" in={open} {...TransitionProps}>
            <Paper
              sx={{
                boxShadow: theme.customShadows.z1,
                width: '100%',
                minWidth: 285,
                maxWidth: 420,
                [theme.breakpoints.down('md')]: {
                  maxWidth: 285
                }
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                {/* @ts-ignore */}
                <MainCard
                  title="Notificaciones"
                  elevation={0}
                  border={false}
                  content={false}
                  secondary={
                    <IconButton size="small" onClick={handleToggle}>
                      <CloseOutlined />
                    </IconButton>
                  }
                >
                  <List
                    component="nav"
                    sx={{
                      position: 'relative',
                      overflow: 'auto',
                      maxHeight: 300,
                      p: 0,
                      '& .MuiListItemButton-root': {
                        py: 0.5,
                        '& .MuiAvatar-root': avatarSX,
                        '& .MuiListItemSecondaryAction-root': { ...actionSX, position: 'relative' }
                      }
                    }}
                  >
                    {
                      notificaciones.map((notificacion, index) => (
                        <React.Fragment key={index}>
                          <ListItemButton>
                            <ListItemAvatar>
                              <Avatar
                                sx={{
                                  color: 'success.main',
                                  bgcolor: 'success.lighter'
                                }}
                              >
                                {
                                  notificacion.type === NotificationType.BIRTHDAY &&
                                  <GiftOutlined />
                                }
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                <Typography variant="h6">
                                  {notificacion.title}
                                </Typography>
                              }
                              secondary={notificacion.content}
                            />
                            {/* <ListItemSecondaryAction>
                              <Typography variant="caption" noWrap>
                                3:00 AM
                              </Typography>
                            </ListItemSecondaryAction> */}
                          </ListItemButton>
                          <Divider />
                        </React.Fragment>
                      ))
                    }
                    {/* <ListItemButton sx={{ textAlign: 'center', py: `${12}px !important` }}>
                      <ListItemText
                        primary={
                          <Typography variant="h6" color="primary">
                            View All
                          </Typography>
                        }
                      />
                    </ListItemButton> */}
                  </List>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default Notification;
