import { NumberLiteralType } from "typescript";

export interface Asesor {
    nombre : string,
    correo : string,
    telefono : string,
    tipo_usuario : number | string,
    id : number,
    id_constructora : number,
    id_desarrollo : number,
    id_inmobiliaria : number,
    id_oficina : number,
    oficina : string,
    id_rangos? : NumberLiteralType,
    id_banco : number,
    coleccion? : string,
    estatus? : number | string,
    estatus_name? : string,
    permisos : PermisosEspeciales,
    uid? : string,
    password? : string,
    name? : string
}

export interface IFasesPermisos{
    analisis  : KOpcionesPermisos,
    autorizados : KOpcionesPermisos,
    avaluo : KOpcionesPermisos,
    instruccion : KOpcionesPermisos,
    firma : KOpcionesPermisos,
    fondeo : KOpcionesPermisos,
    cobranza : KOpcionesPermisos,
    comision : KOpcionesPermisos,
}
export interface KFasesPermisos{
    [ key : string ] : Partial<OpcionesPermisos>
}
export interface KOpcionesPermisos{
    [ key : string ] : OpcionesPermisos
}
export interface PermisosEspeciales{
    lectura : boolean,
    clientes : boolean,
    catalogos : boolean,
    registro_expediente : boolean,
    seguimiento : boolean,
    fases : Partial<KFasesPermisos>,
    comentarios : boolean,
    leer_comentarios : boolean
}

export interface OpcionesPermisos{
    consulta : boolean,
    escritura : boolean
}

export interface  PermisosKeyValue{
    [ key : string ] : PermisosEspeciales
}
export interface FasesKeyValue{
    [ key : string ] : IFasesPermisos
}

export enum EPermisosEspeciales{
    lectura = "lectura",
    clientes = "clientes",
    catalogos = "catalogos",
    comentarios = "comentarios",
    leer_comentarios = "leer_comentarios",
    registro_expediente = "registro_expediente",
    seguimiento = "seguimiento",
}
export enum FasesPermisos{
    analisis = "analisis",
    autorizado = "autorizado",
    avaluo = "avaluo",
    instruccion = "instruccion",
    firma = "firma",
    fondeo = "fondeo",
    cobranza = "cobranza",
    comision = "comision",
}
export enum TipoUsuario{
    Administrador =  'Administrador',
    Asesor =  'Asesor',
    Gerente =  'Gerente Oficina',
    Control =  'Control',
    Externo  = 'Externo',
}