import React, { useEffect, useState } from 'react'
import { Box, Button, Grid } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid';
import CustomSection from '../../components/CustomSection'
import TableComponent, { createColumns } from '../../components/TableComponent'
import { useAppContext } from '../../AppContext';
import EstadisticasApi from '../../api/estadisticas';
import { mapCasos } from '../../business/Validations';
import { Casos } from '../../business/models/Casos';
import AsesorHook from '../../hooks/AsesorHook';
import SelectField from '../../components/SelectField';
import { array_month } from '../../business/models/Constants';
import { VerificarAccesos } from '../../business/components/TieneAcceso';

interface Props {

}

const columns: GridColDef[] = [
    createColumns({ headerName: "Asesor", field: "asesor" }),
    createColumns({ headerName: "Cliente", field: "cliente" }),
    createColumns({ headerName: "Banco", field: "nombre_banco" }),
    createColumns({ headerName: "Folio Banco", field: "folio_banco" }),
    createColumns({ headerName: "Monto Firmado", field: "monto_firmado", sortable: true, type: "number" }),
    createColumns({ headerName: "Fecha Firmado", field: "fecha_firmado" }),
    createColumns({ headerName: "Tasa", field: "tasa" }),
    createColumns({ headerName: "Escrituras", field: "escrituras" })
]


const Firmado = (props: Props) => {
    const { handleActivePage, usuario } = useAppContext()
    const { usuarios, getAsesores } = AsesorHook()
    const [datos, setDatos] = useState<Partial<Casos>[]>([])
    const [years, setYears] = useState([])
    const { buildContent } = VerificarAccesos()
    const [searchParams, setSearchParams] = useState<{ name: string, value: string }[]>(buildContent({ tipo_usuario: 2, permisos: { lectura: true } }) ? [{ name: 'asesor', value: usuario?.id.toString()! }] : [])

    useEffect(() => {
        handleActivePage('Firmados')
        getAsesores()
        obtener_years_firmados()
    }, [])


    const obtener_years_firmados = async () => {
        try {
            const { data } = await EstadisticasApi.getYears()
            setYears(data.years.map((item: any) => {
                return {
                    id: item.anio,
                    name: item.anio
                }
            }))
        } catch (error) {

        }
    }
    const obtener_datos = async () => {
        try {
            const queryParams = searchParams.length > 0 ? searchParams.map(item => { return `${item.name}=${item.value}` }).join('&') : ''
            const { data } = await EstadisticasApi.getFirmados(queryParams)
            setDatos(mapCasos(data.firmados))

        } catch (error) {
            console.log(error)
        }
    }

    const getSearchParams = (name: string, value: string) => {
        let exists = searchParams.findIndex(item => item.name === name)
        if (exists !== -1 && value === "0") {
            setSearchParams(searchParams.filter((item, index) => index !== exists))
        } else if (exists !== -1 && value !== "0") {
            setSearchParams(searchParams.map(item => {
                if (item.name === name) {
                    item.value = value
                }
                return item
            }))
        } else {
            setSearchParams(searchParams.concat({ name, value }))
        }
    }

    const selectedValue = (name: string) => {
        return searchParams.find(x => x.name === name)?.value! ?? 0
    }
    const rowSelected = () => {

    }
    return (
        <CustomSection>
            <Grid container>
                <Grid item xs={12} display='flex' flexDirection="row" justifyContent='center'>
                    {
                        (Number(usuario?.tipo_usuario) & 9) > 0 ?
                            <Box style={{ width: '100%' }} >
                                <SelectField label="Asesor" value={selectedValue('asesor')} menuRender={usuarios as any} onChange={getSearchParams} name="asesor" />
                            </Box>
                            : null
                    }
                    <Box style={{ width: '100%' }}>
                        <SelectField label="Mes firmado" value={selectedValue('month')} menuRender={array_month.map((item, index) => {
                            return {
                                id: index + 1,
                                name: item
                            }
                        })} onChange={getSearchParams} name="month" />
                    </Box>
                    <Box style={{ width: '100%' }}>
                        <SelectField label="Año firmado" value={selectedValue('year')} menuRender={years} onChange={getSearchParams} name="year" />
                    </Box>
                    <Box style={{ width: '100%' }}>
                        <Button color="primary" onClick={() => obtener_datos()}>Buscar</Button>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <div style={{ height: '100%', width: '100%' }}>
                        <TableComponent rows={datos} columns={columns} rowCallback={rowSelected} />
                    </div>
                </Grid>
            </Grid>
        </CustomSection>
    )
}

export default Firmado