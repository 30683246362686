import axios from 'axios';

class HttpService {
    request: any
    constructor() {
        this.request = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            headers: {
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        });

    }
    async post(url: string, params: any) {
        const data = await this.request.post(url, params)
        return data
    }
    async get(url: string) {
        const data = await this.request.get(url)
        return data
    }
    async put(url: string, params?: any) {
        const data = await this.request.put(url, params)
        return data
    }
    async delete(url: string) {
        const data = await this.request.delete(url)
        return data
    }
    badToken(message: string) {
        if (message === "Token no valido: inicie sesión nuevamente") {
            window.location.href = '/login'
        }
    }
};
const httpService = HttpService
export default httpService