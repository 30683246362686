import { Box, Container, Grid } from '@mui/material'
import React, { useEffect } from 'react'
import { useAppContext } from '../../AppContext'
import VerticalTabs from '../../components/VerticalTabs'
import Comunicados from './Comunicados'
import DatosGenerales from './DatosGenerales'
import Oficinas from './Oficinas'
import ComunicadosInternos from './ComunicadosInternos'

interface Props {

}



const AdminPage = (props: Props) => {
    const { handleActivePage } = useAppContext()

    const ListItems = [
        {
            title: 'Datos Generales',
            component: <DatosGenerales />
        },
        {
            title: 'Oficinas',
            component: <Oficinas />
        },
        {
            title: 'Comunicados',
            component: <Comunicados />
        },
        {
            title: 'Comunicados internos',
            component: <ComunicadosInternos />
        },
    ]
    useEffect(() => {
        handleActivePage(`Panel Administrativo `)
    }, [])

    return (
        <Box component="main" sx={{
            flexGrow: 1,
        }}>
            <Container maxWidth={false}>
                <Grid container>
                    <Grid item lg={12}>
                        <VerticalTabs options={ListItems} />
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default AdminPage