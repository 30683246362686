import { Box, Card, CardActionArea, CardContent, CardMedia, Paper, Typography } from '@mui/material'
import React from 'react'
import { Comunicado } from '../../business/models/Comunicados'


type Props = Partial<Comunicado>

const ComunicadoItem = (props: Props) => {
    return (
        <Card sx={{ maxWidth: 345 }}>
            <CardActionArea>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {props.titulo}
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        {props.contenido}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}

export default ComunicadoItem