import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Check from '../../components/Check';
import CustomSection from '../../components/CustomSection';
import AsesorHook from '../../hooks/AsesorHook';
import SaveIcon from '@mui/icons-material/Save';
import BancoHooks from '../../hooks/BancoHooks';
import ConstructorasHooks from '../../hooks/ConstructorasHook';
import InputField from '../../components/InputForm';
import SelectField from '../../components/SelectField';
import { EPermisosEspeciales, FasesPermisos, TipoUsuario } from '../../business/models/Asesor';
import { useAppContext } from '../../AppContext';
import { useParams } from 'react-router-dom';
import { getRole } from '../../business/Validations';
import AdministradorApi from '../../api/administrador';
//TODO: corregir un error cuando editas un asesor
interface IPermisos {
    label: string,
    name: EPermisosEspeciales,
    disabled: boolean,
    es_externo?: boolean
}
const tipo_usuario: { id: number, name: TipoUsuario }[] = [
    { id: 1, name: TipoUsuario.Administrador },
    { id: 2, name: TipoUsuario.Asesor },
    { id: 4, name: TipoUsuario.Control },
    { id: 8, name: TipoUsuario.Externo },
    { id: 16, name: TipoUsuario.Gerente },
]
const localFases: FasesPermisos[] = [
    FasesPermisos.analisis,
    FasesPermisos.autorizado,
    FasesPermisos.avaluo,
    FasesPermisos.instruccion,
    FasesPermisos.firma,
    FasesPermisos.fondeo,
    FasesPermisos.cobranza,
    FasesPermisos.comision,
]
const Asesor = () => {
    const { handleActivePage } = useAppContext()
    const { id } = useParams<{ id: string }>()
    const [oficinas, setOficinas] = useState<any[]>([])
    const { usuario, setDatosUsuario, asignarPermisos, asignarPermisoEspecial, guardar, actualizar, setAsesor, reiniciar_permisos } = AsesorHook();
    const { bancos, obtenerBancos } = BancoHooks()
    const { constructoras, obtenerConstructoras, obtenerDesarrollo } = ConstructorasHooks()

    useEffect(() => {
        handleActivePage("Nuevo Asesor")
        Promise.all([
            obtenerBancos(),
            obtenerConstructoras(),
            obtener_oficinas()
        ])
    }, [])
    const obtener_oficinas = async () => {
        try {
            const { data } = await AdministradorApi.oficinas()
            setOficinas(data.oficinas.map((item: any) => {
                item.name = item.nombre
                return item
            }))
        } catch (error) {

        }
    }

    useEffect(() => {
        if (id) {
            handleActivePage("Editar Asesor")
            setAsesor(id)
        }
    }, [id])

    useEffect(() => {
        if (Number(usuario.id_constructora)) {
            obtenerDesarrollo(usuario.id_constructora)
        }
    }, [usuario.id_constructora])
    //se debe esperar a que exista un usuario para poder cargar los permisos
    const permisos: IPermisos[] = usuario ? [
        {
            label: "Acceso a cliente a solo lectura",
            name: EPermisosEspeciales.lectura,
            disabled: usuario.permisos.clientes ? true : false,

        },
        {
            label: "Alta de clientes y consulta",
            name: EPermisosEspeciales.clientes,
            disabled: usuario.permisos.lectura ? true : false,

        },
        {
            label: "Alta de catalogos",
            name: EPermisosEspeciales.catalogos,
            disabled: usuario.permisos.lectura ? true : false,
            es_externo: usuario.tipo_usuario === 16 ? true : false
        },
        {
            label: "Escribir comentarios",
            name: EPermisosEspeciales.comentarios,
            disabled: usuario.permisos.lectura ? true : false,
            es_externo: usuario.tipo_usuario === 16 ? true : false
        },
        {
            label: "Consultar comentarios",
            name: EPermisosEspeciales.leer_comentarios,
            disabled: false,
            es_externo: usuario.tipo_usuario === 16 ? true : false
        },
        {
            label: "Registro de expedientes",
            name: EPermisosEspeciales.registro_expediente,
            disabled: usuario.permisos.lectura ? true : false,
            es_externo: usuario.tipo_usuario === 16 ? true : false
        },
        {
            label: "Seguimiento en etapas",
            name: EPermisosEspeciales.seguimiento,
            disabled: usuario.permisos.lectura ? true : false,
            es_externo: usuario.tipo_usuario === 16 ? true : false
        },
    ] : []

    return (
        <CustomSection>
            <>
                <Grid container>
                    <Grid item md={4} xs={12} sx={{ paddingBottom: 1, paddingRight: 1 }}>
                        <Box>
                            <InputField
                                type="text"
                                name="nombre"
                                value={usuario.nombre}
                                id="nombre"
                                label="Nombre del usuario"
                                onChange={setDatosUsuario}
                                fullWidth />
                        </Box>
                    </Grid>
                    <Grid item md={4} xs={12} sx={{ paddingBottom: 1, paddingRight: 1 }}>
                        <Box>
                            <InputField
                                type="text"
                                name="correo"
                                value={usuario.correo}
                                id="correo"
                                label="Correo del usuario"
                                onChange={setDatosUsuario}
                                fullWidth
                            />
                        </Box>
                    </Grid>

                    <Grid item md={4} xs={12} sx={{ paddingBottom: 1, paddingRight: 1 }}>
                        <Box>
                            <SelectField label="Tipo de usuario" menuRender={tipo_usuario} onChange={(name, value) => [reiniciar_permisos(), setDatosUsuario(name, value)]} name="tipo_usuario" value={usuario.tipo_usuario} />
                        </Box>
                    </Grid>
                    <Grid item md={4} xs={12} sx={{ paddingBottom: 1, paddingRight: 1 }}>
                        <Box>
                            <SelectField label="Oficina" menuRender={oficinas} onChange={setDatosUsuario} name="id_oficina" value={usuario.id_oficina} />
                        </Box>
                    </Grid>
                    {
                        (getRole(usuario.tipo_usuario) === TipoUsuario.Externo) &&
                        <>
                            {
                                usuario.id_constructora.toString() === "0" && usuario.id_inmobiliaria.toString() === "0" &&
                                <Grid item md={4} xs={12}>
                                    <Box>
                                        <SelectField label="Banco" menuRender={bancos} onChange={setDatosUsuario} name="id_banco" value={usuario.id_banco} defaultText="Selecciona si es de asesor de banco" />
                                    </Box>
                                </Grid>
                            }
                            {
                                usuario.id_banco.toString() === "0" && usuario.id_inmobiliaria.toString() === "0" &&
                                <>
                                    <Grid item md={4} xs={12}>
                                        <Box>
                                            <SelectField label="Constructora" menuRender={constructoras} onChange={setDatosUsuario} name="id_constructora" value={usuario.id_constructora} defaultText="Selecciona si es asesor de constructura" />
                                        </Box>
                                    </Grid>
                                    {
                                        Number(usuario.id_constructora) > 0 &&
                                        <Grid item md={4} xs={12}>
                                            <Box>
                                                <SelectField label="Desarrollo" menuRender={[]} onChange={setDatosUsuario} name="id_desarrollo" value={usuario.id_desarrollo} defaultText="Asignar a desarrollo" />
                                            </Box>
                                        </Grid>
                                    }
                                </>
                            }
                            {
                                usuario.id_constructora.toString() === "0" && usuario.id_banco.toString() === "0" && <Grid item md={4} xs={12}>
                                    <Box>
                                        <SelectField label="Inmobiliaria" menuRender={[{ id: 1, name: "x" }]} onChange={setDatosUsuario} name="id_inmobiliaria" value={usuario.id_inmobiliaria} defaultText="Selecciona si es asesor inmobiliario" />
                                    </Box>
                                </Grid>
                            }
                        </>
                    }
                    <Divider variant="middle" style={{ marginTop: 20, marginBottom: 20 }} />
                </Grid>
                <br />
                {((Number(usuario.tipo_usuario) & 14) > 0) &&
                    <Grid container>
                        <Grid item md={12}>
                            <Typography variant="h5" component="h5" gutterBottom>
                                Permisos
                            </Typography>
                        </Grid>
                        {
                            permisos.map((item: IPermisos) => (
                                <Grid item md={4} xs={12} key={item.name}>
                                    {!item.es_externo &&
                                        <Box>
                                            <Check
                                                label={item.label}
                                                value={usuario.permisos[item.name]}
                                                name={item.name}
                                                handleChange={(e: any) => asignarPermisos(e)} disabled={item.disabled} />
                                        </Box>
                                    }
                                </Grid>
                            ))
                        }
                    </Grid>
                }
                <Divider variant="middle" style={{ marginTop: 20, marginBottom: 20 }} />
                {
                    usuario.permisos.seguimiento &&
                    <Typography variant="h5" component="h2" gutterBottom>
                        Seleccione a que etapas tendrá acceso el usuario y que tipo de acción tendrá permitida realizar.
                    </Typography>
                }
                <Grid container>
                    {
                        usuario.permisos.seguimiento &&
                        localFases.map((item: FasesPermisos) => (
                            <Grid item md={4} xs={12} key={item}>
                                <Box>
                                    <Typography variant="h6" component="h6" gutterBottom style={{ textTransform: 'capitalize' }}>
                                        {item}
                                    </Typography>
                                </Box>
                                <Box>
                                    <Check
                                        label="Consultar"
                                        value={usuario.permisos.fases[item]!.consulta}
                                        name={item}
                                        id="consulta"
                                        handleChange={(e: any) => asignarPermisoEspecial(e)} />
                                </Box>
                                <Box>
                                    <Check
                                        label="Editar"
                                        value={usuario.permisos.fases[item]!.escritura}
                                        name={item}
                                        id="escritura"
                                        handleChange={(e: any) => asignarPermisoEspecial(e)} />
                                </Box>
                            </Grid>
                        ))
                    }
                    <Grid item xs={12}>
                        <Box>
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={() => id ? actualizar(id) : guardar()}
                                startIcon={<SaveIcon />}>
                                {id ? 'Actualizar' : 'Guardar'}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </>
        </CustomSection>
    );
};

export default Asesor;