import PropTypes from 'prop-types';
import { useMemo } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Drawer, useMediaQuery } from '@mui/material';

// project import
import DrawerHeader from './DrawerHeader';
import DrawerContent from './DrawerContent';
import MiniDrawerStyled from './MiniDrawerStyled';
import { drawerWidth } from '../../../config';

interface Props {
  open: boolean
  handleDrawerToggle: () => void
}

const MainDrawer = ({ open, handleDrawerToggle }: Props) => {

  // responsive drawer container
  const container = window !== undefined ? () => window.document.body : undefined;

  // header content
  const drawerContent = useMemo(() => <DrawerContent open={open} />, []);
  const drawerHeader = useMemo(() => <DrawerHeader open={open} />, [open]);

  return (
    <Box component="nav" sx={{ flexShrink: { md: 0 }, zIndex: 1300 }} aria-label="mailbox folders">

      <Drawer
        container={container}
        PaperProps={{
          sx: {
            backgroundColor: '#2a4682',
            color: '#FFFFFF',
          }
        }}
        variant="temporary"
        open={open}
        onClose={handleDrawerToggle}
        ModalProps={{ keepMounted: true }}
        sx={{
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
            backgroundImage: 'none',
            boxShadow: 'inherit'
          },
          zIndex: (theme) => theme.zIndex.appBar + 100
        }}
      >
        {open && drawerHeader}
        {open && drawerContent}
        {/* <Box sx={{ p: 2 }}>
          SIGH
        </Box> */}
      </Drawer>


    </Box>
  );
};

MainDrawer.propTypes = {
  open: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  window: PropTypes.object
};

export default MainDrawer;
