import { Alert, Box, Button, Grid } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useAppContext } from '../../AppContext';
import CustomSection from '../../components/CustomSection';
import DialogComponent, { DialogHooks } from '../../components/DialogComponent';
import InputField from '../../components/InputForm';
import TableComponent, { createColumns } from '../../components/TableComponent';
import ConstructorasHooks from '../../hooks/ConstructorasHook';

const TableColumns: GridColDef[] = [
    createColumns({
        field: 'nombre_constructora',
        headerName: 'Constructora'
    }),
    createColumns({
        field: 'desarrollos',
        headerName: 'Desarrollos',
        width: 600
    }),
]

const Constructoras = () => {
    const { constructoras, obtenerConstructorasDesarrollos, obtenerDatos, agregar, nuevoDesarrollo } = ConstructorasHooks()
    const [open, handleClickOpen, handleClose] = DialogHooks()
    const [desarrolloOpen, openDesarrollos, closeDesarrollos] = DialogHooks()
    const { handleActivePage } = useAppContext()
    const [id_constructora, setIdConstructora] = useState(0)

    useEffect(() => {
        handleActivePage("Constructoras")
        obtenerConstructorasDesarrollos()
    }, [])

    const rowSelected = (row: any) => {
        setIdConstructora(Number(row.id))
        openDesarrollos()
    }
    return (
        <CustomSection>
            <>
                <Grid container>
                    <Grid item xs={12} sx={{ marginBottom: 5 }} display='flex' flexDirection='row-reverse'>
                        <Button color="success" variant='outlined' onClick={() => handleClickOpen()}>Agregar Constructora</Button>
                    </Grid>
                    <Grid item xs={12} sx={{ marginBottom: 5 }}>
                        <Alert variant="outlined" severity="info">
                            Para agregar un esquema de click en una constructora
                        </Alert>
                    </Grid>
                    <Grid item xs={12}>
                        <div style={{ height: '100%', width: '100%' }}>
                            <TableComponent rows={constructoras} columns={TableColumns} rowCallback={rowSelected} />
                        </div>
                    </Grid>
                </Grid>
                <DialogComponent open={open} DialogText='Agrega Constructora' handleClose={handleClose}
                    ButtonActions={{
                        handleSuccessActionText: "Guardar",
                        handleSuccessActionFunction: () => agregar(handleClose),
                        handleCancelActionText: 'Cancelar',
                        handleCancelActionFunction: () => handleClose()
                    }}
                >
                    <Box sx={{ margin: 5 }}>
                        <InputField
                            type="text"
                            name="nombreConstructora"
                            onChange={obtenerDatos}
                            id="nombreConstructora"
                            label="Constructora"
                            fullWidth
                        />
                    </Box>
                </DialogComponent>

                <DialogComponent open={desarrolloOpen} DialogText='Agrega Desarrollo' handleClose={closeDesarrollos}
                    ButtonActions={{
                        handleSuccessActionText: "Guardar",
                        handleSuccessActionFunction: () => nuevoDesarrollo(closeDesarrollos, id_constructora),
                        handleCancelActionText: 'Cancelar',
                        handleCancelActionFunction: () => closeDesarrollos()
                    }}
                >
                    <Box sx={{ margin: 5 }}>
                        <InputField
                            type="text"
                            name="desarrollo"
                            onChange={obtenerDatos}
                            id="desarollo"
                            label="Desarrollo"
                            fullWidth
                        />
                    </Box>
                </DialogComponent>
            </>
        </CustomSection>
    );
};

export default Constructoras;