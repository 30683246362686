import { FormControlLabel, FormGroup, Switch } from '@mui/material';
import React from 'react';

const Check = ({value,handleChange,name,label,id, disabled} : any) => {
    return (
        <FormGroup row>
            <FormControlLabel
                disabled={disabled}
                control={
                    <Switch checked = { value }
                    id = {id}
                    onChange = {
                        handleChange
                    }
                    name = {name} />
                }
            label={label}/>
        </FormGroup>
    );
};

export default Check;