import React from 'react';
import { Paper } from '@mui/material';

const CustomSection = ({ children }: { children: JSX.Element }) => {
    return (
        <Paper elevation={3} sx={{padding : 2}} >
            { children }
        </Paper>
    );
};

export default CustomSection;