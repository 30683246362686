import { Avatar, Box, Card, CardContent, Grid, Typography } from '@mui/material';
import PaidIcon from '@mui/icons-material/Paid';

type Props = {
    cantidad: number | string
    expedientes: number
}
export default function PorFirmarCard(props: Props) {
    return (
        <Card sx={{ height: '100%' }}>
            <CardContent>
                <Grid
                    container
                    spacing={3}
                    sx={{
                        justifyContent: 'space-between'
                    }}>
                    <Grid item>
                        <Typography color="textSecondary" gutterBottom variant="overline">
                            Monto Firmado
                        </Typography>
                        <Typography color="textPrimary" variant="h4">
                            {`${props.cantidad}`}
                        </Typography>
                        <br />
                        <Typography color="textPrimary" variant="body1">
                            Expedientes firmados: {`${props.expedientes}`}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Avatar
                            sx={{
                                backgroundColor: 'success.main',
                                height: 56,
                                width: 56
                            }}
                        >
                            <PaidIcon />
                        </Avatar>
                    </Grid>
                </Grid>
                <Box sx={{ pt: 2, display: 'flex', alignItems: 'center' }}>
                    <Typography
                        color="textSecondary"
                        variant="caption"
                    >
                        Monto firmado actualmente
                    </Typography>
                </Box>
            </CardContent>
        </Card>
    );
}
