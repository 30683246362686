import React, { useState, useEffect } from 'react'
import { Button } from '@mui/material';
import { format } from 'date-fns';

import FasesService from '../../api/fases';
import { useAppContext } from '../../AppContext';
import Analisis from '../../pages/fases/Analisis';
import Autorizados from '../../pages/fases/Autorizados';
import Avaluo from '../../pages/fases/Avaluo';
import Instruccion from '../../pages/fases/Instruccion';
import Firma from '../../pages/fases/Firma';
import Fondeo from '../../pages/fases/Fondeo';
import Cobranza from '../../pages/fases/Cobranza';
import Comision from '../../pages/fases/Comision';
import { Fases } from '../models/Fases';


interface IAvanzarProps{
    fase : string,
    id_caso : number, 
    handleAvanzar : () => void
}

export interface IAvanzarValues{
    fecha_autorizacion : string,
    fecha_vencimiento : string,
    fecha_avaluo : string,
    fecha_instruccion : string,
    fecha_riesgos : string,
    fecha_firma : string,
    fecha_fondeo : string,
    fecha_cobranza : string,
    fecha_comision : string,
    fecha_pago : string,
    monto_autorizado : number,
    monto_firmado : number,
    tasa : number,
    id_notaria : number,
    id_valuador : number,
    escrituras : number
}

const initialState = {
    fecha_autorizacion : format(new Date(),'yyyy/MM/dd'),
    fecha_vencimiento : format(new Date(),'yyyy/MM/dd'),
    fecha_avaluo : format(new Date(),'yyyy/MM/dd'),
    fecha_instruccion : format(new Date(),'yyyy/MM/dd'),
    fecha_riesgos : format(new Date(),'yyyy/MM/dd'),
    fecha_firma : format(new Date(),'yyyy/MM/dd'),
    fecha_probable_firma : format(new Date(),'yyyy/MM/dd'),
    fecha_fondeo : format(new Date(),'yyyy/MM/dd'),
    fecha_cobranza : format(new Date(),'yyyy/MM/dd'),
    fecha_comision : format(new Date(),'yyyy/MM/dd'),
    fecha_pago : format(new Date(),'yyyy/MM/dd'),
    monto_autorizado : 0,
    monto_firmado : 0,
    tasa : 0,
    id_notaria : 0,
    id_valuador : 0,
    escrituras : 0
}

const Avanzar = ({ fase, id_caso, handleAvanzar } : IAvanzarProps) => {
    const { openAlert, obtener_fases } = useAppContext()
    const [ state, setState ] = useState<IAvanzarValues>(initialState)
    useEffect(()=>{
        if(Number(id_caso) > 0){
            obtener_caso()
        }
    },[id_caso])

    const obtener_caso = async () => {
        try {
            let { data } = await FasesService.obtenerExpediente(id_caso)
            let { monto_autorizado, monto_firmado, id_notaria, id_valuador, tasa, escrituras} = data.expediente
            if(fase === Fases.INSTRUCCION){
                monto_firmado = monto_autorizado
            }
            setState({
                ...state,  monto_autorizado, monto_firmado, id_notaria, id_valuador, tasa, escrituras
            })
        } catch (error : any) {
            
        }
    }
    const handlechange = (name : string , value : any ) =>{
        setState({
            ...state, [name] : value
        })
    }

    const setDates = (name : string , value : any) =>{
        setState({
            ...state, [name] : value
        })
    }

    const RenderFases = () =>{
        let component = null
        switch (fase) {
            case Fases.ANALISIS:
                component = <Analisis handlechange={ handlechange } setDates={setDates} state={state}/>
                break;
            case Fases.AUTORIZADO:
                component = <Autorizados handlechange={ handlechange } setDates={setDates} state={state}/>
                break;
            case Fases.AVALUO:
                component = <Avaluo handlechange={ handlechange } setDates={setDates} state={state}/>
                break;
            case Fases.INSTRUCCION:
                component = <Instruccion handlechange={ handlechange } setDates={setDates} state={state}/>
                break;
            case Fases.FIRMA:
                component = <Firma handlechange={ handlechange } setDates={setDates} state={state}/>
                break;
            case Fases.FONDEO:
                component = <Fondeo handlechange={ handlechange } setDates={setDates} state={state}/>
                break;
            case Fases.COBRANZA:
                component = <Cobranza handlechange={ handlechange } setDates={setDates} state={state}/>
                break;
            case Fases.COMISION:
                component = <Comision handlechange={ handlechange } setDates={setDates} state={state}/>
                break;
            default:
                break;
        }
        return component
    }

    const continuar = async () =>{
        try {
            validate_form()
            let { data } = await FasesService.avanzar(fase, id_caso, state)
            openAlert('success',data.message,)
            handleAvanzar()
            obtener_fases()
        } catch (error : any) {
            if(error.hasOwnProperty('response')){
                openAlert(error.response.hasOwnProperty('message') ? error.response.message : error.response.statusText, 'warning' )
            }else{
                openAlert('warning',error.message)
            }
        }
    }

    const validate_form = () =>{
        switch (fase.toLocaleLowerCase()) {
            case 'analisis':
                    if(!state.monto_autorizado || state.monto_autorizado === 0){
                        throw Error('El monto autorizado debe ser mayor a 0')
                    }
                break;
        
            default:
                break;
        }
    }

    return (
        <>
                {
                    RenderFases()   
                }
                <Button
                    fullWidth
                    style={{ marginTop : 5 }}
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={ () => continuar() }>
                    Guardar
                </Button>
        </>
    );
};

export default Avanzar;