import httpService from "./Http"

class EstadisticasApi{

    static async getBitacora(){
        return await new httpService().get(`v1/estadisticas/bitacora`)
    }

    static async getFirmados(searchParams : string){
        return await new httpService().get(`v1/estadisticas/firmados?${searchParams}`)
    }
    static async getYears(){
        return await new httpService().get(`v1/estadisticas/firmados_years`)
    }
    static async getAsesoresFirmados(query : string  ){
        return await new httpService().get(`v1/estadisticas/firmado_asesores?${query}`)
    }
    static async getFirmadosBanco(query : string){
        return await new httpService().get(`v1/estadisticas/firmados_banco?${query}`)
    }
    static async getComparativoBancoAnual(){
        return await new httpService().get(`v1/estadisticas/comparativo_bancos`)
    }
    static async getComparativoAsesorAnual(){
        return await new httpService().get(`v1/estadisticas/comparativo_asesores`)
    }
}
export default EstadisticasApi