import React from 'react';
import { Box } from '@mui/system';
import { IFaseProps } from './Index';
import SelectField from '../../components/SelectField';
import DateSelector from '../../components/DateSelector';
import NotariaHooks from '../../hooks/NotariasHook';
import ValuadoresHooks from '../../hooks/ValuadoresHook';

const Avaluo = ({ handlechange, setDates, state }: IFaseProps) => {
    const { notarias } = NotariaHooks(1)
    const { valuadores } = ValuadoresHooks(1)

    return (
        <Box style={{ flexDirection: 'column', justifyContent: 'space-around' }}>
            <Box>
                <Box sx={{ marginBottom: 2 }}>
                    <DateSelector
                        label="Fecha del Avaluo"
                        retrieveDate={(date: string) => setDates("fecha_instruccion", date)}
                    />
                </Box>
                {/* <Box>
                    <DateSelector
                        label = "Fecha de visto bueno por riesgos"
                        retrieveDate={( date : string ) => setDates("fecha_riesgos", date) }
                        />
                </Box> */}
            </Box>
            <Box mt={2}>
                <SelectField
                    label="Notaria"
                    menuRender={notarias}
                    onChange={handlechange}
                    value={state.id_notaria}
                    name="id_notaria"
                />
            </Box>
            <Box mt={2}>
                <SelectField
                    label="Valuador"
                    menuRender={valuadores}
                    onChange={handlechange}
                    value={state.id_valuador}
                    name="id_valuador"
                />
            </Box>
        </Box>
    )
};

export default Avaluo;