import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Grid,
    MenuItem,
    MenuList,
    Typography
} from '@mui/material';
import InputField from '../../components/InputForm';
import SaveIcon from '@mui/icons-material/Save';
import BancoHooks from '../../hooks/BancoHooks';
import { useAppContext } from '../../AppContext';
import SelectField from '../../components/SelectField';
import TableComponent, { createColumns } from '../../components/TableComponent';
import { GridColDef } from '@mui/x-data-grid';
import DialogComponent, { DialogHooks } from '../../components/DialogComponent';
import BancosApi from '../../api/bancos';

const columns: GridColDef[] = [
    createColumns({ headerName: "Banco", field: "nombre_banco" }),
    createColumns({ headerName: "Estatus", field: "estado" })
]

const Bancos = () => {
    const { bancos, obtenerBancos, obtenerDatos, guardarBanco, guardarEsquema, esquemas_banco, actualizarBanco, bancoSeleccionado, setBancoSeleccionado } = BancoHooks()
    const [esquemas, setEsquemas] = useState<any[]>([])
    const { handleActivePage, openAlert } = useAppContext()
    const [open, handleClickOpen, handleClose] = DialogHooks()
    const [openEditarEsquema, handleClickOpenEsquema, handleCloseEsquema] = DialogHooks()
    const [openEditarBanco, handleClickOpenBanco, handleCloseBanco] = DialogHooks()
    const [estatus, setEstatus] = useState('0')
    const [esquema, setEsquema] = useState('0')
    const [nombreEsquema, setNombreEsquema] = useState<null | string>(null)
    const [nombreBanco, setNombreBanco] = useState<null | string>(null)
    const [estatusBanco, setEstatusBanco] = useState(0)

    useEffect(() => {
        handleActivePage("Bancos")
        obtenerBancos(0)
    }, [])

    useEffect(() => {
        if (bancoSeleccionado !== null) {
            setEstatusBanco(Number(bancos.find(x => Number(x.id) === bancoSeleccionado)!.estatus))
        }
    }, [bancoSeleccionado])

    const rowSelected = async (row: any) => {
        setBancoSeleccionado(Number(row.id))
    }

    const esquemaSeleccionado = (id_esquema: string, estatus: string) => {
        setEstatus(estatus)
        setEsquema(id_esquema)
    }
    const actualizarEstatusEsquema = async (id_esquema: string | number, estatus: string) => {
        try {
            await BancosApi.updateEsquema(id_esquema, { esquema: { estatus: !Number(estatus) } })
            openAlert('success', 'Esquema actualizado')
            handleClose()
        } catch (error) {
            openAlert('error', 'Ocurrio un problema al actualizar el esquema')
            handleClose()
        }
    }
    const actualizarNombreEsquema = async (id_esquema: string | number) => {
        try {
            await BancosApi.updateEsquema(id_esquema, { esquema: { nombre_esquema: nombreEsquema?.trim() } })
            openAlert('success', 'Esquema actualizado')
            handleCloseEsquema()
        } catch (error) {
            openAlert('error', 'Ocurrio un problema al actualizar el esquema')
            handleCloseEsquema()
        }
    }
    const actualizarNombreBanco = async (nombre_banco: string) => {
        try {
            await BancosApi.updatebanco(bancoSeleccionado!, { nombre_banco })
            openAlert("success", "Banco actualizado")
            setBancoSeleccionado(null)
            obtenerBancos()
            handleCloseBanco()
        } catch (error: any) {
            openAlert('error', error.response.data.message)
        }
    }
    const handleEditatEsquema = (id_esquema: string | number) => {
        const { nombre_esquema } = esquemas.find(x => x.id! === id_esquema)!
        setNombreEsquema(nombre_esquema)
        handleClickOpenEsquema()
    }

    const handleEditarBanco = () => {
        const { nombre_banco } = bancos.find(x => Number(x.id) === bancoSeleccionado)!
        setNombreBanco(nombre_banco)
        handleClickOpenBanco()
    }
    return (
        <Grid container>
            <Grid item md={4} sx={{ paddingRight: 2 }}>
                <Grid item md={12}>
                    <Box>
                        <Card>
                            <CardContent>
                                <Typography component="h6" variant="h6">
                                    Nuevo banco
                                </Typography>
                                <Box mt={2}>
                                    <InputField
                                        type="text"
                                        name="nombre"
                                        onChange={obtenerDatos}
                                        id="nombre"
                                        label="Nombre del Banco"
                                        fullWidth />
                                </Box>
                            </CardContent>
                            <CardActions>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={() => guardarBanco()}
                                    startIcon={< SaveIcon />}>
                                    Guardar
                                </Button>
                            </CardActions>
                        </Card>
                    </Box>
                </Grid>
                <Grid
                    item
                    md={12}
                    style={{
                        marginTop: 16
                    }}>
                    <Card>
                        <CardContent>
                            <Grid item md={12}>
                                <Typography component="h6" variant="h6">
                                    Nuevo Esquema
                                </Typography>
                                <Box>
                                    <SelectField
                                        label="Selecciona un banco"
                                        menuRender={bancos}
                                        onChange={obtenerDatos}
                                        name="id" />
                                </Box>
                            </Grid>
                            <Grid
                                item
                                md={12}
                                style={{
                                    marginTop: 16
                                }}>
                                <Box>
                                    <InputField
                                        type="text"
                                        name="esquema"
                                        onChange={obtenerDatos}
                                        id="esquema"
                                        label="Esquema"
                                        fullWidth />
                                </Box>
                            </Grid>
                        </CardContent>
                        <CardActions>
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={() => guardarEsquema()}
                                startIcon={< SaveIcon />}>
                                Guardar
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
            <Grid item md={8}>
                <Box>
                    <Card>
                        <CardContent>
                            <Grid container>
                                <Grid item md={12}>
                                    {
                                        bancoSeleccionado !== null &&
                                        <Box display='flex' flexDirection='row-reverse' >
                                            {
                                                Boolean(estatusBanco) ?
                                                    <Button
                                                        size='small'
                                                        color='error'
                                                        onClick={() => actualizarBanco(bancoSeleccionado, false)}
                                                    >
                                                        Desactivar
                                                    </Button>
                                                    :
                                                    <Button
                                                        size='small'
                                                        color='success'
                                                        onClick={() => actualizarBanco(bancoSeleccionado, true)}
                                                    >
                                                        Activar
                                                    </Button>
                                            }
                                            <Button
                                                size="small"
                                                color='info'
                                                onClick={async () => handleEditarBanco()} >Editar banco</Button>
                                            <Button
                                                size="small"
                                                color='warning'
                                                onClick={async () => [setEsquemas(await esquemas_banco(bancoSeleccionado)), handleClickOpen()]} >Ver esquemas</Button>
                                        </Box>
                                    }
                                </Grid>
                            </Grid>
                            <div
                                style={{
                                    width: '100%'
                                }}>
                                <TableComponent rows={bancos} columns={columns} rowCallback={rowSelected} />
                            </div>
                        </CardContent>
                    </Card>
                </Box>
            </Grid>
            <DialogComponent open={open} handleClose={handleClose} fullWidth={true}
                maxWidth="lg" DialogText='Esquemas' ButtonActions={{
                    handleSuccessActionText: estatus === "1" ? 'Desactivar' : 'Activar',
                    handleSuccessActionFunction: () => actualizarEstatusEsquema(esquema, estatus),
                    handleCancelActionText: 'Cerrar',
                    handleCancelActionFunction: () => handleClose()
                }}>
                <Grid container>
                    {
                        esquema !== "0" &&
                        <Grid item xs={12}>
                            <Button onClick={() => handleEditatEsquema(esquema)} color="primary" variant="contained" >
                                Editar nombre esquema
                            </Button>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        {
                            <MenuList>
                                {
                                    esquemas.map((item: any) => (
                                        <MenuItem key={item.id} selected={item.id === esquema} onClick={() => esquemaSeleccionado(item.id, item.estatus)} sx={{
                                            color: item.estatus !== "1" ? 'red' : ''
                                        }} >{item.nombre_esquema}</MenuItem>
                                    ))
                                }
                            </MenuList>
                        }
                    </Grid>
                </Grid>
            </DialogComponent>
            <DialogComponent open={openEditarEsquema} handleClose={handleClickOpenEsquema} fullWidth={true}
                maxWidth="lg" DialogText='Editar Esquema' ButtonActions={{
                    handleSuccessActionText: "Guardar",
                    handleSuccessActionFunction: () => actualizarNombreEsquema(esquema),
                    handleCancelActionText: 'Cerrar',
                    handleCancelActionFunction: () => handleCloseEsquema()
                }}>
                <Grid container>
                    <Grid item xs={12}>
                        <Box mt={2}>
                            <InputField
                                type="text"
                                name="nombre_esquema"
                                onChange={(name: string, value: string) => setNombreEsquema(value)}
                                value={nombreEsquema}
                                id="nombre_esquema"
                                label="Nombre esquema"
                                fullWidth />
                        </Box>
                    </Grid>
                </Grid>
            </DialogComponent>
            <DialogComponent open={openEditarBanco} handleClose={handleClickOpenBanco} fullWidth={true}
                maxWidth="lg" DialogText='Editar Banco' ButtonActions={{
                    handleSuccessActionText: "Guardar",
                    handleSuccessActionFunction: () => nombreBanco !== null ? actualizarNombreBanco(nombreBanco) : openAlert('error', "El nombre del banco no puede ser vacio"),
                    handleCancelActionText: 'Cerrar',
                    handleCancelActionFunction: () => handleCloseBanco()
                }}>
                <Grid container>
                    <Grid item xs={12}>
                        <Box mt={2}>
                            <InputField
                                type="text"
                                name="nombre_banco"
                                onChange={(name: string, value: string) => setNombreBanco(value)}
                                value={nombreBanco}
                                id="nombre_banco"
                                label="Nombre banco"
                                fullWidth />
                        </Box>
                    </Grid>
                </Grid>
            </DialogComponent>
        </Grid>
    );
};

export default Bancos;
