import { Box } from '@mui/material';
import React from 'react';
import DateSelector from '../../components/DateSelector';
import InputField from '../../components/InputForm';
import SelectField from '../../components/SelectField';
import NotariaHooks from '../../hooks/NotariasHook';
import ValuadoresHooks from '../../hooks/ValuadoresHook';
import { IFaseProps } from './Index';

const Fondeo = ({ handlechange, setDates, state }: IFaseProps) => {
    const { notarias } = NotariaHooks(1)
    const { valuadores } = ValuadoresHooks(1)

    return (
        <Box style={{ flexDirection: 'column', justifyContent: 'space-around' }}>
            <Box>
                <DateSelector
                    label="Fecha de Fondeo"
                    retrieveDate={(date: string) => setDates("fecha_cobranza", date)}
                />
            </Box>
            <Box mt={2}>
                <SelectField
                    label="Notaria"
                    menuRender={notarias}
                    onChange={handlechange}
                    value={state.id_notaria}
                    name="id_notaria"
                />
            </Box>
            <Box mt={2}>
                <SelectField
                    label="Valuador"
                    menuRender={valuadores}
                    onChange={handlechange}
                    value={state.id_valuador}
                    name="id_valuador"
                />
            </Box>
            <Box mt={2}>
                <InputField onChange={handlechange} name="monto_firmado" label="Monto a firmar" type="number" value={state.monto_firmado} fullWidth />
                <span> {`$${new Intl.NumberFormat().format(state.monto_firmado)}`} </span>
            </Box>
            <Box mt={2}>
                <InputField onChange={handlechange} name="escrituras" label="Escrituras" type="number" fullWidth value={state.escrituras} />
            </Box>
        </Box>
    )
};

export default Fondeo;