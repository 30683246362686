import React from 'react';
import { Box, Typography } from '@mui/material';
import { IFaseProps } from './Index';
import DateSelector from '../../components/DateSelector';
import InputField from '../../components/InputForm';

const Instruccion = ({ handlechange, setDates, state }: IFaseProps) => {

    return (
        <Box style={{ flexDirection: 'column', justifyContent: 'space-around' }}>
            <Box sx={{ marginBottom: 2 }}>
                <DateSelector
                    label="Fecha de confirmación de condiciones"
                    retrieveDate={(date: string) => setDates("fecha_firma", date)}
                />
            </Box>
            <Box>
                <DateSelector
                    label="Fecha Probable de Firma"
                    retrieveDate={(date: string) => setDates("fecha_probable_firma", date)}
                />
            </Box>
            <Box mt={2}>
                <InputField onChange={handlechange} name="monto_firmado" label="Monto a firmar" defaultValue={state.monto_autorizado} value={state.monto_firmado} type="number" fullWidth />
                <span> {`$${new Intl.NumberFormat().format(state.monto_firmado === 0 ? state.monto_autorizado : state.monto_firmado)}`}</span>
                <Typography variant="caption" display="block" gutterBottom>
                    Monto autorizado: {`$${new Intl.NumberFormat().format(state.monto_autorizado)}`}
                </Typography>
            </Box>
            <Box mt={2}>
                <InputField onChange={handlechange} name="tasa" label="Tasa de interes" type="number" fullWidth value={state.tasa} />
            </Box>
        </Box>
    )
};

export default Instruccion;