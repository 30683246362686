import httpService from "./Http"

class AuthApi{

    static async  login(correo : string, password : string){
        return await new httpService().post(`admin/auth`, {correo, password})
    }
    static async recoverPassword(correo : string){
        return await new httpService().post(`admin/auth/recuperar`, {correo})
    }
    static async updatePassword(token : string, password : string ){
        return await new httpService().put(`admin/auth/actualizar`, { token ,password})
    }
}

export default AuthApi

