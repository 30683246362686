import React, { useState, useEffect } from 'react';
import CustomSection from '../../components/CustomSection';
import { Box, Button, CircularProgress, useMediaQuery, useTheme } from '@mui/material';
import TableComponent, { createColumns } from '../../components/TableComponent';
import FasesHook from '../../hooks/FasesHook';

import { Navigate, useParams } from 'react-router-dom';
import { useAppContext } from '../../AppContext';
import { GridColDef } from '@mui/x-data-grid';
import DialogComponent, { DialogHooks } from '../../components/DialogComponent';
import { SmallDialogHook } from '../../components/SmallDialog';
import SendIcon from '@mui/icons-material/Send';
import InboxIcon from '@mui/icons-material/Inbox';
import DeleteIcon from '@mui/icons-material/Delete';
import ArticleIcon from '@mui/icons-material/Article';

import Comentarios from '../../business/components/Comentarios';
import Avanzar, { IAvanzarValues } from '../../business/components/Avanzar';
import Opciones from '../../components/Opciones';
import { VerificarAccesos } from '../../business/components/TieneAcceso';
import { TipoUsuario } from '../../business/models/Asesor';
import SelectField from '../../components/SelectField';
import CancelarCaso from '../../components/CancelarCaso';
import Grid from '@mui/material/Unstable_Grid2/Grid2';

export interface IFaseProps {
    handlechange: (name: string, value: any) => void,
    setDates: (name: string, value: any) => void,
    state: IAvanzarValues
}



const FasesWrapper = () => {
    const { fase } = useParams<string>()
    const theme = useTheme();
    const matchDownsx = useMediaQuery(theme.breakpoints.down('md'));
    const { usuario, handleActivePage, openAlert } = useAppContext()
    const { casos, loading, setLoading, obtener_casos, faseExists, casoSeleccionado, setCasoSeleccionado, regresar_expediente } = FasesHook()
    const [open, handleClickOpen, handleClose] = SmallDialogHook()
    const [isOpen, handleOpen, close] = DialogHooks()
    const [isCancelar, setCancelar] = useState(false)
    const [opcionModal, setOpcion] = useState<string | null>(null)
    const [etapaRegresar, setEtapaRegresar] = useState<number>(0)
    const { buildContent } = VerificarAccesos()

    let columns: GridColDef[] = [
        createColumns({ headerName: "Cliente", field: "cliente", sortable: true, }),
        createColumns({ headerName: "Asesor", field: "asesor", sortable: true, width: matchDownsx ? 100 : undefined }),
        createColumns({ headerName: "Banco", field: "nombre_banco", sortable: true, width: matchDownsx ? 100 : undefined }),
        createColumns({ headerName: "Monto solicitado", field: "monto_solicitado", sortable: true, type: "number", width: matchDownsx ? 150 : undefined }),
        createColumns({ headerName: "Ingreso Banco", field: "fecha_ingreso_banco", sortable: true, width: matchDownsx ? 100 : undefined }),
        createColumns({ headerName: "Fecha Etapa", field: "fecha_ultima_fase", sortable: true, width: matchDownsx ? 100 : undefined }),
        createColumns({ headerName: "Dias en fase", field: "dias", sortable: true, type: "number", width: matchDownsx ? 100 : undefined }),
    ]
    const F: any = fase?.toUpperCase()
    if (F === 'AUTORIZADO') {
        columns = [
            createColumns({ headerName: "Cliente", field: "cliente", sortable: true, }),
            createColumns({ headerName: "Asesor", field: "asesor", sortable: true, width: matchDownsx ? 100 : undefined }),
            createColumns({ headerName: "Banco", field: "nombre_banco", sortable: true, width: matchDownsx ? 100 : undefined }),
            createColumns({ headerName: "Monto autorizado", field: "monto_autorizado", sortable: true, type: "number", width: matchDownsx ? 150 : undefined }),
            createColumns({ headerName: "Ingreso Banco", field: "fecha_ingreso_banco", sortable: true, width: matchDownsx ? 100 : undefined }),
            createColumns({ headerName: "Fecha Etapa", field: "fecha_ultima_fase", sortable: true, width: matchDownsx ? 100 : undefined }),
            createColumns({ headerName: "Dias en fase", field: "dias", sortable: true, type: "number", width: matchDownsx ? 100 : undefined }),
        ]
    }

    if (F !== 'ANALISIS' && F !== 'AUTORIZADO') {
        columns = [
            createColumns({ headerName: "Cliente", field: "cliente", sortable: true, }),
            createColumns({ headerName: "Asesor", field: "asesor", sortable: true, width: matchDownsx ? 100 : undefined }),
            createColumns({ headerName: "Banco", field: "nombre_banco", sortable: true, width: matchDownsx ? 100 : undefined }),
            createColumns({ headerName: "Notaria", field: "notaria", sortable: true, width: matchDownsx ? 150 : undefined }),
            createColumns({ headerName: "Valuador", field: "valuador", sortable: true, width: matchDownsx ? 150 : undefined }),
            createColumns({ headerName: "Monto autorizado", field: "monto_autorizado", sortable: true, type: "number", width: matchDownsx ? 100 : undefined }),
            createColumns({ headerName: "Ingreso Banco", field: "fecha_ingreso_banco", sortable: true, width: matchDownsx ? 100 : undefined }),
            createColumns({ headerName: "Fecha Etapa", field: "fecha_ultima_fase", sortable: true, width: matchDownsx ? 100 : undefined }),
            createColumns({ headerName: "Dias en fase", field: "dias", sortable: true, type: "number", width: matchDownsx ? 100 : undefined }),
        ]
    }
    if (F === 'FONDEO' || F === 'COMISION' || F === 'COBRANZA') {
        columns = [
            createColumns({ headerName: "Cliente", field: "cliente", sortable: true, }),
            createColumns({ headerName: "Asesor", field: "asesor", sortable: true, width: matchDownsx ? 100 : undefined }),
            createColumns({ headerName: "Banco", field: "nombre_banco", sortable: true, width: matchDownsx ? 100 : undefined }),
            createColumns({ headerName: "Notaria", field: "notaria", sortable: true, width: matchDownsx ? 150 : undefined }),
            createColumns({ headerName: "Valuador", field: "valuador", sortable: true, width: matchDownsx ? 150 : undefined }),
            createColumns({ headerName: "Monto firmado", field: "monto_firmado", sortable: true, type: "number", width: matchDownsx ? 100 : undefined }),
            createColumns({ headerName: "Ingreso Banco", field: "fecha_ingreso_banco", sortable: true, width: matchDownsx ? 100 : undefined }),
            createColumns({ headerName: "Fecha Etapa", field: "fecha_ultima_fase", sortable: true, width: matchDownsx ? 100 : undefined }),
            createColumns({ headerName: "Dias en fase", field: "dias", sortable: true, type: "number", width: matchDownsx ? 100 : undefined }),
        ]
    }

    const array_etapas = [
        { id: 1, name: 'Analisis' },
        { id: 2, name: 'Autorizado' },
        { id: 3, name: 'Avaluo' },
        { id: 4, name: 'Instruccion' },
        { id: 5, name: 'Firma' },
        { id: 6, name: 'Fondeo' },
        { id: 7, name: 'Cobranza' },
        { id: 8, name: 'Comision' },
    ]

    const ListaOpciones = [
        {
            text: "Avanzar",
            Icon: <SendIcon color="primary" />,
            handleEvent: () => [handleClose(), onHandleOption('avanzar')],
            hasAccess: buildContent({ tipo_usuario: TipoUsuario.Administrador, permisos: { fases: { [fase!.toLowerCase()]: { escritura: true } } } })
        },
        {
            text: "Comentarios",
            Icon: <InboxIcon color="secondary" />,
            handleEvent: () => [handleClose(), onHandleOption('comentarios')],
            hasAccess: buildContent({ tipo_usuario: TipoUsuario.Administrador, permisos: { comentarios: true } })
        },
        {
            text: "Regresar",
            Icon: <ArticleIcon color="warning" />,
            handleEvent: () => [handleClose(), onHandleOption('regresar')],
            hasAccess: buildContent({ tipo_usuario: TipoUsuario.Administrador, permisos: { fases: { [fase!.toLowerCase()]: { escritura: true } } } })
        },
        {
            text: "Eliminar",
            Icon: <DeleteIcon style={{ color: "red" }} />,
            handleEvent: () => [handleClose(), setCancelar(true)],
            hasAccess: (Number(usuario?.tipo_usuario) & 5) > 0
        }
    ]



    useEffect(() => {
        if (faseExists(fase!)) {
            handleActivePage(fase?.toUpperCase()!)
            obtener_casos(F)
        }
        return () => {
            setLoading(true)
        }
    }, [fase])

    const rowSelected = (option: any) => {
        setCasoSeleccionado(option.row)
        handleClickOpen()
    }

    const onHandleOption = (option: any) => {
        setOpcion(option)
        handleOpen()
    }

    const handleAvanzar = () => {
        close()
        obtener_casos(F)
    }

    const HandleOptionsModal = () => {
        switch (opcionModal) {
            case 'comentarios':
                return <Comentarios id_caso={casoSeleccionado?.id!} fase={fase!} />
            case 'avanzar':
                return <Avanzar fase={fase?.toUpperCase()!} id_caso={casoSeleccionado?.id!} handleAvanzar={handleAvanzar} />
            case 'regresar':
                return <RegresarExpediente id_caso={casoSeleccionado?.id!} />
            default:
                break;
        }
    }
    const RegresarExpediente = ({ id_caso }: { id_caso: number }) => {

        const regresarExpediente = async () => {
            if (etapaRegresar === 0) {
                return openAlert('warning', 'Debe seleccionar una etapa para poder regresar el expediente')
            }
            await regresar_expediente(id_caso, etapaRegresar)
            obtener_casos(F)
            close()
        }

        return (
            <Grid container >
                <Grid xs={12}>
                    <SelectField
                        label="Etapas"
                        menuRender={array_etapas}
                        onChange={(name, value) => setEtapaRegresar(value)}
                        value={etapaRegresar}
                        name="etapa"
                    />
                </Grid>
                <Grid xs={12}>
                    <Button onClick={() => regresarExpediente()} >Regresar</Button>
                </Grid>
            </Grid>
        )
    }

    if (!faseExists(fase!))
        return (<Navigate to="/error" replace={true} />)

    return (
        <>
            <CustomSection>
                <Grid container>
                    <Grid xs={12} >
                        <>
                            {
                                !loading ?
                                    <TableComponent rows={casos} columns={columns} rowCallback={rowSelected} />
                                    :
                                    <Box sx={{ display: 'flex' }}>
                                        <CircularProgress />
                                    </Box>
                            }
                        </>
                    </Grid>
                </Grid>
            </CustomSection>
            <Opciones options={ListaOpciones.filter(item => item.hasAccess)} open={open} handleClose={handleClose} text={`Opciones para: ${casoSeleccionado?.cliente && casoSeleccionado.cliente.toLocaleUpperCase()}`} />
            <DialogComponent
                open={isOpen}
                handleClose={close}
                DialogText={casoSeleccionado?.cliente && casoSeleccionado.cliente.toLocaleUpperCase()}
                fullWidth={true}
                maxWidth="lg">
                {
                    HandleOptionsModal()
                }
                {/* <EnviarCorreo /> */}
            </DialogComponent>
            <CancelarCaso id_caso={casoSeleccionado?.id!} open={isCancelar} close={() => setCancelar(false)} handleSuccessResponse={() => [setCancelar(false), obtener_casos(F)]} />
        </>
    );
};

export default FasesWrapper;