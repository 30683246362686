import React from 'react';
import { Box } from '@mui/system';
import DateSelector from '../../components/DateSelector';

import SelectField from '../../components/SelectField';
import NotariaHooks from '../../hooks/NotariasHook';
import { IFaseProps } from './Index';
import ValuadoresHooks from '../../hooks/ValuadoresHook';

const Autorizados = ({ handlechange, setDates, state }: IFaseProps) => {
    const { notarias } = NotariaHooks(1)
    const { valuadores } = ValuadoresHooks(1)

    return (
        <Box style={{ flexDirection: 'column', justifyContent: 'space-around' }}>
            <Box sx={{ marginBottom: 2 }}>
                <DateSelector
                    label="Fecha de autorización"
                    retrieveDate={(date: string) => setDates("fecha_autorizacion", date)}
                />
            </Box>
            <Box mt={2}>
                <SelectField
                    label="Notaria"
                    menuRender={notarias}
                    onChange={handlechange}
                    name="id_notaria"
                />
            </Box>
            <Box mt={2}>
                <SelectField
                    label="Valuador"
                    menuRender={valuadores}
                    onChange={handlechange}
                    name="id_valuador"
                />
            </Box>
        </Box>
    )
};

export default Autorizados;