import React from "react";
import { Autocomplete, Box, TextField } from "@mui/material";

interface IAutocompleteProps {
    options: any[],
    seleccion: (value: any) => void,
    buscar: (value: any) => void,
    label: string,
    id: string | any
}

const AutocompleteComponent = ({ options, label, id, seleccion, buscar }: IAutocompleteProps) => {
    return (
        <div style={{
            width: 300
        }}>
            <Autocomplete
                freeSolo
                id="custom-autocomplete"
                disableClearable
                size="small"
                options={options}
                onChange={(e, v) => seleccion(v)}
                onInputChange={(event, inputValue) => buscar(inputValue)}
                getOptionLabel={(option) => option.label ?? option}
                renderOption={(props, option) => (
                    <Box component="li" {...props} key={option[id]} id={option[id]}>
                        {option.label}
                    </Box>
                )}
                renderInput={(params) => (<TextField {...params} label={label} InputProps={{
                    ...params.InputProps,
                    type: 'search',
                }} />)} />
        </div>
    );
};

export default AutocompleteComponent;