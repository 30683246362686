import { useState } from 'react';
import BancosApi from '../api/bancos';
import { useAppContext } from '../AppContext';
import { Bancos } from '../business/models/Bancos';


const BancoHooks = () => {
  const { openAlert } = useAppContext()
  const [bancoSeleccionado, setBancoSeleccionado] = useState<null | number>(null)
  const [banco, setBanco] = useState({
    id: 0,
    nombre: '',
    esquema: ""
  });
  const [bancos, setBancos] = useState<Bancos[]>([])

  const obtenerDatos = (name: string, value: any) => {
    setBanco({ ...banco, [name]: value });
  };

  const guardarBanco = async () => {
    try {
      if (banco.nombre === "") {
        throw Error('Debe ingresar un nombre valido para poder continuar')
      }
      await BancosApi.create({ nombre: banco.nombre })
      openAlert("success", "Banco creado")
      obtenerBancos()
    } catch (error: any) {
      openAlert('error', error.response.data.message)
    }
  }

  const guardarEsquema = async () => {
    try {
      if (Number(banco.id) === 0) {
        throw Error('Debe seleccionar un banco para poder continuar')
      }
      if (banco.esquema === "") {
        throw Error('Debe ingresar un nombre validor para poder continuar')
      }
      await BancosApi.createEsquema({ id_banco: banco.id, esquema: banco.esquema })

    } catch (error: any) {
      console.log({ error });
      openAlert('error', error.response.data.message)
    }
  }
  const obtenerBancos = async (estatus = 1) => {
    try {
      const { data } = await BancosApi.get(estatus)
      setBancos(data.bancos.map((item: any) => {
        item.name = item.nombre_banco
        item.estado = Boolean(Number(item.estatus)) ? "Activo" : "Inactivo"
        return item
      }))
    } catch (error) {
      console.log(error)
    }
  }

  const esquemas_banco = async (id: number | string) => {
    let { data } = await BancosApi.esquemas(id)
    return data.esquemas.map((item: any) => {
      item.name = item.nombre_esquema
      return item
    })
  }

  const actualizarBanco = async (id: number, estatus: boolean) => {
    try {
      await BancosApi.updatebanco(id, { estatus })
      openAlert("success", "Banco actualizado")
      setBancoSeleccionado(null)
      obtenerBancos()
    } catch (error: any) {
      openAlert('error', error.response.data.message)
    }
  }


  return {
    bancos,
    obtenerDatos,
    obtenerBancos,
    esquemas_banco,
    guardarBanco,
    guardarEsquema,
    actualizarBanco,
    bancoSeleccionado,
    setBancoSeleccionado
  };
};

export default BancoHooks;
