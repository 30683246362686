import React, {  useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import { Alert,AlertColor } from '@mui/material';

interface AlertProps{
    open : boolean,
    message : string,
    type : AlertColor,
    handleClose : () => void
}

const AlertComponent = (props : AlertProps) => {
    return (
        <Snackbar
            anchorOrigin={{ vertical : "top", horizontal : "center" }}
            open={props.open}
            autoHideDuration={6000}
            onClose={props.handleClose}
        >
            <Alert severity={props.type}>{props.message}</Alert>
        </Snackbar>
    );
};

export default AlertComponent;

export const AlertHook = ( ) => {
    const [ open, setOpen ] = useState(false)
    const [ type, setType ] = useState<AlertColor>("success")
    const [ message, setMessage ] = useState<string>("")
    
    const openAlert = (type : AlertColor, message : string) =>{
        setOpen(true)
        setType(type)
        setMessage(message)
    }
    const handleClose = () =>{
        setOpen(false)
    }
    return {
        open, 
        type,
        message,
        openAlert,
        handleClose
    }
};

