import React, { useEffect, useState } from 'react';
import { Alert, AlertTitle, Box, Button, Grid } from '@mui/material';
import CustomSection from '../../components/CustomSection';
import TableComponent, { createColumns } from '../../components/TableComponent';
import { GridColDef } from '@mui/x-data-grid';
import ExpedientesApi from '../../api/expedientes';


import { Casos } from '../../business/models/Casos';
import { mapCasos } from '../../business/Validations';
import DialogComponent, { DialogHooks } from '../../components/DialogComponent';
import Comentarios from '../../business/components/Comentarios';
import { useNavigate } from 'react-router-dom';
import CancelarCaso from '../../components/CancelarCaso';
import ExpedientesNoActivos from './ExpedientesNoActivos';
import { useAppContext } from '../../AppContext';

const columns : GridColDef[] =[
    createColumns({headerName : "Ingresó a banco", field : "fecha_ingreso_banco"}),
    createColumns({headerName : "Vencimiento", field : "vencimiento"}),
    createColumns({headerName : "Asesor", field : "asesor"}),
    createColumns({headerName : "Banco", field : "nombre_banco"}),
    createColumns({headerName : "Esquema", field : "nombre_esquema"}),
    createColumns({headerName : "Monto Aprobado", field : "monto_autorizado"}),
    createColumns({headerName : "Monto Solicitado", field : "monto_solicitado"}),
    createColumns({headerName : "Fase actual", field : "fase"}),
]

interface IExpedientesProps{
    id : number | string,
    cliente : string
}
const Expedientes = (props : IExpedientesProps) => {
    const { id, cliente } = props
    const navigate = useNavigate()
    const { usuario } = useAppContext()
    const [ casos_activos, setActivos] = useState<Casos[]>([])
    const [ casoSeleccionado, setCaso] = useState<any>(null)
    const [ casos_no_activos, setNoActivos] = useState<Casos[]>([])
    const [ errorMessage, setErrorMessage ] = useState<string | null>(null);
    const [ isOpen,handleOpen,close]  = DialogHooks()
    const [ eliminarOpen, setEliminar ] = useState(false)

    useEffect(()=>{
        if(id)
            obtenerExpedientesCliente()
    },[id])

    const obtenerExpedientesCliente = async () =>{
        try {
            let { data } : { data : { activos : Casos[], no_activos : Casos[] } } = await ExpedientesApi.expedientes(id)
            setActivos( mapCasos(data.activos) )
            setNoActivos( mapCasos(data.no_activos) )
            setErrorMessage(null)
        } catch (error) {
            console.log({error})
            setActivos( [] )
            setNoActivos( [] )
            setErrorMessage('No se cuentan con registros de expediente para este cliente. Si usted no cuenta con los permisos para agregar expediente contacte al administrador de su oficina.')
        }
    }
    
    const selectedRow = (row : any) =>{
        setCaso(row.row)
    }

    const handleSuccessResponse = async ( )=>{
        await obtenerExpedientesCliente()
        setEliminar(false)
    }
    return (
        
            <Grid container>
                {
                    errorMessage === null ?
                    <>
                        <Grid item md={12} sx={{marginBottom : 2}}>
                                {
                                    casos_activos.length > 0 &&
                                    <CustomSection>
                                        <>
                                            <Box display='flex' flexDirection='row-reverse' >
                                            {
                                                    casoSeleccionado &&
                                                    <>
                                                        {
                                                            (Number(usuario?.tipo_usuario) & 5) > 0 && 
                                                                <Button color='error' size="small" onClick={() => setEliminar(true)}>Eliminar</Button>
                                                        }
                                                        {
                                                            (Number(usuario?.tipo_usuario) & 13) > 0 && 
                                                            <Button size="small" onClick={() => navigate(`/admin/expediente/editar/${casoSeleccionado.id}`) } >Editar</Button>
                                                        }
                                                        <Button color='info' size="small" onClick={() =>handleOpen()}>Comentarios</Button>
                                                    </>
                                            }
                                            </Box>
                                            <Box sx={{ height: 300, width: '100%' }}>
                                                        <TableComponent columns={columns} rows={casos_activos} rowCallback={ selectedRow }/>
                                            </Box>
                                        </>
                                    </CustomSection>
                                }
                        </Grid>
                        <Grid item md={12}>
                                {
                                    casos_no_activos.length > 0 &&
                                        <ExpedientesNoActivos casos_no_activos={casos_no_activos} handleSuccessResponse={handleSuccessResponse} />
                                }
                        </Grid>
                    </>
                    :
                    <Grid item md={12}>
                        <Alert severity="info">
                            <AlertTitle>Información</AlertTitle>
                            { errorMessage }
                        </Alert>
                    </Grid>
                }
                <DialogComponent
                    open={isOpen} 
                    handleClose={close} 
                    DialogText="Comentarios" 
                    fullWidth={true}
                    maxWidth="lg">
                        {
                            casoSeleccionado &&
                                <Comentarios id_caso = {casoSeleccionado?.id!} fase={casoSeleccionado?.fase} />
                        }
                </DialogComponent>
                <CancelarCaso id_caso={casoSeleccionado?.id!} open={eliminarOpen} close={() => setEliminar(false)} handleSuccessResponse={handleSuccessResponse}/>
            </Grid>
    );
};

export default Expedientes;