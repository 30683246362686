import React,{ useState } from 'react';
import { AppBar, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Slide, Toolbar, Typography } from '@mui/material';
import { GridCloseIcon } from '@mui/x-data-grid';
import { TransitionProps } from '@mui/material/transitions';

export interface DialogSettings {
    open : boolean;
    handleClose : () => void;
    children? : any;
    DialogText? : string;
    ButtonActions? : {
        handleCancelActionText? : string;
        handleCancelActionFunction? : () => void;
        handleSuccessActionText? : string;
        handleSuccessActionFunction? : () => void;
    },
    isFullSize? : boolean;
    maxWidth?: 'lg'
        | 'md'
        | 'sm'
        | 'xl'
        | 'xs'
        | false,
    fullWidth? : boolean
}

const Transition = React.forwardRef(function Transition( props: TransitionProps & 
    {
        children: React.ReactElement<any, any>;
    },ref: React.Ref<unknown>,) {
    return <Slide direction="up" ref={ref} {...props} />;
});
export const DialogHooks = () => {
    const [open,setOpen] = useState<boolean>(false);

    const handleClickOpen = () : void => {
        setOpen(true);
    };

    const handleClose = () : void => {
        setOpen(false);
    };
    return [ open, handleClickOpen, handleClose ] as const
}

const DialogComponent = ( { open, handleClose, ButtonActions, children, DialogText, isFullSize = false, maxWidth = 'lg', fullWidth } : DialogSettings ) => {
    return (
        <Dialog
            fullScreen ={ isFullSize }
            fullWidth={ fullWidth }
            TransitionComponent={ isFullSize ? Transition : undefined }
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={maxWidth}
            keepMounted
        >
            {
                isFullSize ?
                <AppBar>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close">
                            <GridCloseIcon/>
                        </IconButton>
                        <Typography variant="h6" >
                            { DialogText }
                        </Typography>
                    </Toolbar>
                </AppBar>
                :
                <DialogTitle id="alert-dialog-title">{DialogText}</DialogTitle>
            }
            <DialogContent>
                <Box sx={{ margin : 2}}>
                    { children }
                </Box>
            </DialogContent>
            {
                ButtonActions && 
                    <DialogActions>
                        <Button onClick={ButtonActions.handleCancelActionFunction} color="primary" >
                            { ButtonActions.handleCancelActionText  }
                        </Button>
                        <Button onClick={ButtonActions.handleSuccessActionFunction} color="success" variant="contained" autoFocus>
                            { ButtonActions.handleSuccessActionText }
                        </Button>
                    </DialogActions>
            }
        </Dialog>
    );
};

export default DialogComponent;