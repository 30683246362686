import React, { useEffect } from 'react';
import { Box, Button, Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import DateSelector from '../../components/DateSelector';
import InputField from '../../components/InputForm';
import SelectField from '../../components/SelectField';
import ExpedienteHook from '../../hooks/ExpedienteHook';
import CustomSection from '../../components/CustomSection';

const Expediente = () => {
    const { id_cliente_asesor } = useParams()
    const { expediente, bancos, esquemas, apoyos, crear, setDatos, setId } = ExpedienteHook()

    useEffect(() => {
        console.log(id_cliente_asesor);
        if (id_cliente_asesor === undefined || id_cliente_asesor === "0") {
            // window.location.href= '/buscar/cliente'
        } else {
            setId(id_cliente_asesor)
        }
    }, [id_cliente_asesor])


    const getValueFromInput = (name: string, value: any) => {
        if (!isNaN(value)) {
            value = parseFloat(value)
        }
        setDatos(name, value)
    }

    const Formulario = [
        <DateSelector
            label="Fecha de ingreso a banco"
            retrieveDate={(date: string) => setDatos("fecha_ingreso_banco", date)}
        />,
        (
            <>
                <InputField onChange={getValueFromInput} name="monto_solicitado" label="Monto solicitado" type="number" fullWidth /> <span> $ {new Intl.NumberFormat().format(expediente.monto_solicitado)}</span></>
        ),
        <InputField onChange={getValueFromInput} name="ejecutivo_banco" label="Ejecutivo banco" type="text" fullWidth />,
        <InputField onChange={getValueFromInput} name="plazo" label="Plazo" type="number" fullWidth />,
        <InputField onChange={getValueFromInput} name="tasa" label="Tasa de interes" type="number" fullWidth />,
        <InputField onChange={getValueFromInput} name="comision_apertura" label="Comision por apertura" type="number" fullWidth />,
        <InputField onChange={getValueFromInput} name="pago_adelantado" label="Comision pago adelantado" type="number" fullWidth />,
        <InputField onChange={getValueFromInput} name="folio_banco" label="Folio banco" type="text" fullWidth />,
    ]
    return (
        <CustomSection>
            <>
                <Grid container>
                    <Grid item md={4} xs={12} style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between'
                    }}>
                        <Box sx={{
                            padding: .5
                        }}>
                            <SelectField
                                label="Selecciona apoyo"
                                menuRender={apoyos}
                                onChange={getValueFromInput}
                                value={expediente.id_apoyo}
                                name="id_apoyo"
                            />
                        </Box>
                        <Box sx={{
                            padding: .5
                        }}>
                            <SelectField
                                label="Selecciona banco"
                                menuRender={bancos}
                                onChange={getValueFromInput}
                                value={expediente.id_banco}
                                name="id_banco"
                            />
                        </Box>
                        <Box sx={{
                            padding: .5
                        }}>
                            <SelectField
                                label="Selecciona esquema"
                                menuRender={esquemas}
                                onChange={getValueFromInput}
                                value={expediente.id_esquema}
                                name="id_esquema"
                            />
                        </Box>
                        <Box sx={{
                            padding: .5
                        }}>
                            <SelectField
                                label="Selecciona destino del credito"
                                menuRender={[
                                    { id: 1, name: 'Tradicional' },
                                    { id: 2, name: 'Terreno' },
                                    { id: 3, name: 'Terreno + Construcción' },
                                    { id: 4, name: 'Construcción' },
                                    { id: 5, name: 'Sustitución de hipoteca' },
                                    { id: 6, name: 'Pago de pasivos' },
                                    { id: 7, name: 'PYME' },
                                ]}
                                onChange={getValueFromInput}
                                value={expediente.id_destino}
                                name="id_destino"
                            />
                        </Box>
                    </Grid>
                    <Grid item md={8} xs={12}>
                        <Grid container>
                            {
                                Formulario.map((item, index) => (
                                    <Grid key={index} container item md={4} sx={{ paddingBottom: 1, paddingRight: 1 }}>
                                        <Box width={1}>
                                            {item}
                                        </Box>
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container sx={{ marginTop: 2 }}>
                    <Grid item md={12} >
                        <Button variant='contained' color='success' onClick={() => crear()}>Guardar</Button>
                    </Grid>
                </Grid>
            </>
        </CustomSection>
    );
};

export default Expediente;