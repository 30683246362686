import React, { useEffect, useState } from 'react'
import { Box, Button, Divider, Grid } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import FasesService from '../../api/fases'
import { useAppContext } from '../../AppContext'
import CustomSection from '../../components/CustomSection'
import TableComponent, { createColumns } from '../../components/TableComponent'

import { Casos } from '../../business/models/Casos'
import DialogComponent, { DialogHooks } from '../../components/DialogComponent';
import InputField from '../../components/InputForm'
import DateSelector from '../../components/DateSelector'
import ExpedientesApi from '../../api/expedientes'
import { mapCasos } from '../../business/Validations'

interface Props{}

const columns : GridColDef[] =[
    createColumns({headerName : "Cliente", field : "cliente"}),
    createColumns({headerName : "Asesor", field : "asesor"}),
    createColumns({headerName : "Ingresó a banco", field : "fecha_ingreso_banco"}),
    createColumns({headerName : "Vencimiento", field : "vencimiento"}),
    createColumns({headerName : "Dias por vencer", field : "por_vencer"}),
    createColumns({headerName : "Banco", field : "nombre_banco"}),
    createColumns({headerName : "Esquema", field : "nombre_esquema"}),
    createColumns({headerName : "Monto Aprobado", field : "monto_autorizado"}),
    createColumns({headerName : "Monto Solicitado", field : "monto_solicitado"}),
    createColumns({headerName : "Fase actual", field : "fase"}),
]

function Vencidos({} : Props) {
    const { handleActivePage, openAlert, handleToggleLoading, handleCloseLoading, usuario } = useAppContext()
    const [ expedientes, setExpedientes ] = useState<Casos[]>([])
    const [ expediente, setExpediente ] = useState<Casos | null>(null)
    const [ tipo, setTipo ] = useState<string | null>(null)
    const [ razon, setRazon ] = useState<string>("")
    const [ isOpen,handleOpen,close]  = DialogHooks()

    useEffect(()=>{
        handleActivePage('Expedientes Vencidos')
        obtener_vencidos()
    },[])

    const obtener_vencidos = async ( ) =>{
        try {
            handleToggleLoading()
            const { data } : {data : { vencidos : Casos[] }} = await FasesService.obtener_vencidos()
            setExpedientes( mapCasos( data.vencidos ) )
            handleCloseLoading()
        } catch (error) {
            handleCloseLoading()
        }
    }
    const seleccionado = (row : any) =>{
        setExpediente(row.row)
    }
    return (
        <CustomSection>
            <Grid container>
                <Grid item xs={12}>
                    <Box sx={{ height: '100%', width: '100%' }}>
                        <TableComponent columns={columns} rows={expedientes} rowCallback={ seleccionado }/>
                    </Box>
                </Grid>
            </Grid>
        </CustomSection>
    )
}

export default Vencidos