import { Box, Button, Grid } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import React, { useState } from 'react'
import ExpedientesApi from '../../api/expedientes'
import { useAppContext } from '../../AppContext'
import Comentarios from '../../business/components/Comentarios'
import { Casos } from '../../business/models/Casos'
import CustomSection from '../../components/CustomSection'
import DateSelector from '../../components/DateSelector'
import DialogComponent, { DialogHooks } from '../../components/DialogComponent'
import TableComponent, { createColumns } from '../../components/TableComponent'

type Props = {
    casos_no_activos : Casos[],
    handleSuccessResponse : CallableFunction
}
const columns : GridColDef[] =[
    createColumns({headerName : "Ingresó a banco", field : "fecha_ingreso_banco"}),
    createColumns({headerName : "Vencimiento", field : "vencimiento"}),
    createColumns({headerName : "Motivo de cancelacion", field : "razon"}),
    createColumns({headerName : "Asesor", field : "asesor"}),
    createColumns({headerName : "Banco", field : "nombre_banco"}),
    createColumns({headerName : "Esquema", field : "nombre_esquema"}),
    createColumns({headerName : "Monto Aprobado", field : "monto_autorizado"}),
    createColumns({headerName : "Monto Solicitado", field : "monto_solicitado"}),
    createColumns({headerName : "Ultima Fase", field : "fase"}),
]

const ExpedientesNoActivos = (props : Props) => {
    const [ casoNoActivo, setNoActivo] = useState<any>(null)
    const [ isOpen,openComments,close]  = DialogHooks()
    const [ vencimientoVisible,openVencimento, closeVencimiento]  = DialogHooks()
    const [ nuevoVencimiento, setVencimiento] = useState<string>('')
    const { openAlert } = useAppContext()
    
    const selectedNoActivo = ( row : any) =>{
        setNoActivo(row.row)
    }

    const handleActivar = async () =>{
        if(casoNoActivo.vencimiento !== 'No existe vencimiento'){
            if(new Date( casoNoActivo.vencimiento_pure).getTime() <= new Date().getTime()){
                openVencimento()
                return
            }
            return await ActivarCaso()
        }
        await ActivarCaso()
    }
    const handleChangeVencimiento = async () =>{
        try {
            const { data } : any = 
                await ExpedientesApi.actualizar_vencimiento(casoNoActivo.id, nuevoVencimiento)
                await ActivarCaso()
                closeVencimiento()
                openAlert('success', data.message);
        } catch (error) {
            openAlert('error', "Hubo un error, intente mas tarde");
        }
    }
    const ActivarCaso = async () =>{
        try {
            await ExpedientesApi.activar(casoNoActivo.id)
            openAlert('success','Expediente activado')
            props.handleSuccessResponse()
        } catch (error) {
            openAlert('error','Ocurrio un error al intentar activar el expediente')
        }
    }
    return (
        <CustomSection>
            <>
                <Box>
                    Expedientes no activos
                </Box>
                <Box display='flex' flexDirection='row-reverse' >
                    {
                            casoNoActivo &&
                            <>
                                <Button color='info' size="small" onClick={() =>openComments()}>Comentarios</Button>
                                <Button color='success' size="small" onClick={() =>handleActivar()}>Activar</Button>
                            </>
                    }
                    </Box>
                <Box sx={{ height: 300, width: '100%' }}>
                    <TableComponent columns={columns} rows={props.casos_no_activos} rowCallback={ selectedNoActivo }/>
                </Box>
                <DialogComponent
                    open={isOpen} 
                    handleClose={close} 
                    DialogText="Comentarios" 
                    fullWidth={true}
                    maxWidth="lg">
                        {
                            casoNoActivo &&
                                <Comentarios id_caso = {casoNoActivo?.id!} fase={casoNoActivo?.fase} />
                        }
                </DialogComponent>
                {/* modificar vencimiento */}
                <DialogComponent
                    open={vencimientoVisible} 
                    handleClose={closeVencimiento} 
                    DialogText="Nuevo vencimiento" 
                    fullWidth={true}
                    ButtonActions={{
                        handleCancelActionText : 'Cancelar',
                        handleCancelActionFunction : () => closeVencimiento(),
                        handleSuccessActionText : 'Guardar',
                        handleSuccessActionFunction : () => handleChangeVencimiento()
                    }}
                    maxWidth="lg">
                        <Grid container>
                            <Grid item xs={12}>
                                <DateSelector
                                    label = "Ingrese nuevo vencimiento"
                                    retrieveDate={( date : string ) => setVencimiento(date) }
                                />
                            </Grid>
                        </Grid>
                </DialogComponent>
            </>
        </CustomSection>
    )
}
export default ExpedientesNoActivos