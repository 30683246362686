import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Grid,
    Paper,
    TextField,
    Typography
} from '@mui/material';
import { isEmpty } from '../business/Validations';
import { useNavigate } from 'react-router-dom';

import AuthApi from '../api/auth';
import DialogComponent from '../components/DialogComponent';
import AlertComponent, { AlertHook } from '../components/Alert';
import LoadingButtonComponent from '../components/LoadingButton';

const Login = () => {
    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [recovery, openRecovery] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const { open, type, message, openAlert, handleClose } = AlertHook()
    const navigate = useNavigate()

    useEffect(() => {
        const expiration = localStorage.getItem('exp_mili')
        if (expiration) {
            if (Number(expiration) > new Date().getTime()) {
                navigate('/admin/principal')
            }
        }
    }, [])

    const handleLogin = async () => {
        try {
            setLoading(true)
            if (!isEmpty(email) && !isEmpty(password)) {
                localStorage.setItem('token', '')
                const { data } = await AuthApi.login(email as string, password as string)
                await localStorage.setItem('token', data.token)
                await localStorage.setItem('uid', data.uid)
                await localStorage.setItem('exp', data.exp)
                await localStorage.setItem('exp_mili', data.exp_mili)
                setTimeout(() => {
                    navigate('/admin/principal')
                }, 2000)
            }
        } catch (error) {
            setLoading(false)
            openAlert('error', 'No existe el usuario')
        }
    }
    const sendEmailVerification = async () => {
        if (email !== '') {
            try {
                const { data } = await AuthApi.recoverPassword(email);
                openAlert('success', data.message);
            } catch (error: any) {
                if (error.response && error.response.status) {
                    openAlert('error', error.response.data.message)
                }
            }
        } else {
            openAlert('warning', 'Debe escribir una direccion de correo eletronica valida');
        }
    }
    return (
        <Box sx={{
            backgroundColor: "#416477 !important",
            minHeight: "calc(100vh - 0px)"
        }}>
            <Grid container sx={{
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
                display: "flex",
                flexDirection: "column",
                alignContent: "space-around",
                padding: 2
            }}>
                <Grid item lg={12}>
                    <Paper elevation={16} sx={{ padding: 5 }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', my: 3 }}>
                            <Typography color="textPrimary" variant="h4">
                                SIGH
                            </Typography>
                            <Typography color="textSecondary" gutterBottom variant="body2">
                                Sistema Integral de Gestion Hipotecaria
                            </Typography>
                        </Box>
                        <TextField
                            fullWidth
                            label="Correo electronico"
                            margin="normal"
                            name="email"
                            type="email"
                            onChange={(ev: any) => setEmail(ev.target.value)}
                            value={email}
                            variant="outlined" />
                        <TextField
                            fullWidth
                            label="Contraseña"
                            margin="normal"
                            name="password"
                            type="password"
                            value={password}
                            onChange={(ev: any) => setPassword(ev.target.value)}
                            variant="outlined" />
                        <Box sx={{ py: 2 }}>
                            <LoadingButtonComponent
                                callback={() => handleLogin()}
                                label="Entrar"
                                loading={loading}
                                color="primary"
                                fullWidth
                                size="large"
                                sx={{ marginTop: 2 }}
                            />
                        </Box>
                        <Box sx={{ py: 2 }}>
                            <Button
                                variant='text'
                                color="primary"
                                fullWidth
                                size="small"
                                type="submit"
                                onClick={() => openRecovery(true)}
                            >
                                Olvide mi contraseña
                            </Button>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
            <DialogComponent
                open={recovery}
                handleClose={() => openRecovery(false)}
                DialogText='Recuperar contraseña'
                fullWidth={true}
                ButtonActions={{
                    handleSuccessActionFunction: () => sendEmailVerification(),
                    handleSuccessActionText: 'Enviar'
                }}
                maxWidth="lg">
                <Grid container>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Email Address"
                            margin="normal"
                            name="email"
                            type="email"
                            value={email}
                            onChange={(ev: any) => setEmail(ev.target.value)}
                            variant="outlined" />
                    </Grid>
                </Grid>
            </DialogComponent>
            <AlertComponent open={open} type={type} message={message} handleClose={handleClose} />
        </Box>
    );
};

export default Login;