import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Stack, Chip, Box } from '@mui/material';

// project import
import DrawerHeaderStyled from './DrawerHeaderStyled';

interface Props {
  open: boolean
}

const DrawerHeader = (props: Props) => {
  const theme = useTheme();

  return (
    // @ts-ignore
    <DrawerHeaderStyled theme={theme} open={props.open}>
      <Stack direction="row" spacing={1} alignItems="center">
        <div>
          <Box sx={{ p: 2 }}>
            SIGH
          </Box>
        </div>
      </Stack>
    </DrawerHeaderStyled>
  );
};

DrawerHeader.propTypes = {
  open: PropTypes.bool
};

export default DrawerHeader;
