import React from 'react';
import Fab from '@mui/material/Fab';

interface Props{
    handleOpen : CallableFunction
}
export default function SpeedDialTooltipOpen(props : Props) {
    return (
        <Fab 
            color="primary"
            aria-label="add"
            variant="extended"
            onClick={() => props.handleOpen()}
            sx={{
                position: 'fixed',
                bottom: 5,
                right: 16
            }}>
                Fases
        </Fab>
    );
}