import PropTypes from 'prop-types';
import { Box, Button, ListItem } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

export const NavItem = (props: any) => {
  const { href, icon, title, ...others } = props;
  const location = useLocation();
  const navigation = useNavigate()

  const active = href ? (location.pathname === href) : false;

  return (
    <ListItem
      disableGutters
      sx={{
        display: 'flex',
        mb: 0.5,
        py: 0,
        px: 2
      }}
      {...others}
    >
      <Button
        component="a"
        startIcon={icon}
        disableRipple
        onClick={() => {
          navigation(href)
        }}
        sx={{
          backgroundColor: active ? 'rgba(255,255,255, 0.08)' : '',
          borderRadius: 1,
          color: active ? 'secondary.main' : 'primary.light',
          fontWeight: active ? 'fontWeightBold' : '',
          justifyContent: 'flex-start',
          px: 3,
          textAlign: 'left',
          textTransform: 'none',
          width: '100%',
          '& .MuiButton-startIcon': {
            color: active ? 'primary.light' : 'primary.light'
          },
          '&:hover': {
            backgroundColor: 'rgba(255,255,255, 0.08)'
          }
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          {title}
        </Box>
      </Button>
    </ListItem>
  );
};


