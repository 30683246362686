// material-ui
import { Box, IconButton, Link, Theme, useMediaQuery } from '@mui/material';

// project import

import Profile from './Profile';
import Notification from './Notification';
import { VerificarAccesos } from '../../../../business/components/TieneAcceso';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const matchesXs = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const { buildContent } = VerificarAccesos()
  return (
    <>
      {matchesXs && <Box sx={{ width: '100%', ml: 1 }} />}
      <Box sx={{ width: '100%', ml: { xs: 0, md: 1 } }} />
      {
        buildContent({ tipo_usuario: 3 }) &&
        <Notification />
      }
      <Profile />
    </>
  );
};

export default HeaderContent;
