import httpService from "./Http"

class Notarias {

    static async create(notaria: any) {
        return await new httpService().post(`v1/notaria`, notaria)
    }

    static async all(estatus: number) {
        return await new httpService().get(`v1/notaria/${estatus}`)
    }
    static async update(id: string, params: any) {
        return await new httpService().put(`v1/notaria/${id}`, { params })
    }
}
export default Notarias