import { AlertColor } from '@mui/material';
import React, { createContext, useContext, ReactElement, useState, useEffect } from 'react';
import PerfilApi from './api/perfil';
import { Asesor } from './business/models/Asesor';
import { CantidadFases } from './business/models/Fases';
import AlertComponent, { AlertHook } from './components/Alert';
import Loading, { LoadingHook } from './components/Loading';
import OneSignal from 'react-onesignal';
import Clientes from './api/clientes';
interface AppProviderProps {
    children: ReactElement
}
interface AppContextValues {
    activePage: string,
    handleActivePage: (text: string) => void,
    openAlert: (type: AlertColor, message: string) => void,
    loading: boolean,
    handleToggleLoading: () => void,
    handleCloseLoading: () => void,
    usuario: Asesor | null,
    setUsuarios: (data: Asesor) => void,
    cantidades: CantidadFases | null,
    obtener_fases: () => void
    setExpMili: (exp: number) => void,
}
const AppContext = createContext({
    activePage: "",
    handleActivePage: (text: string) => { },
    openAlert: (type: AlertColor, message: string) => { },
    loading: false,
    handleToggleLoading: () => { },
    handleCloseLoading: () => { },
    usuario: null,
    setUsuarios: (data: Asesor) => { },
    cantidades: null,
    obtener_fases: () => { },
    setExpMili: (exp: number) => { },
} as AppContextValues)

const AppProvider = ({ children }: AppProviderProps) => {
    const [activePage, setActivePage] = useState<string>("");
    const { open, type, message, openAlert, handleClose } = AlertHook()
    const { loading, handleCloseLoading, handleToggleLoading } = LoadingHook()
    const [usuario, setUsuarios] = useState<Asesor | null>(null)
    const [cantidades, setCantidades] = useState<CantidadFases | null>(null)
    const [exp_mili, setExpMili] = useState(localStorage.hasOwnProperty('exp_mili') ? localStorage.exp_mili : null)

    useEffect(() => {
        obtenerUsuario()
    }, [])

    useEffect(() => {
        if (exp_mili < new Date().getTime()) {
            if (window.location.pathname !== "/login") {
                localStorage.clear()
                window.location.href = '/login';
            }
        } else {

        }
    }, [exp_mili])

    useEffect(() => {
        if (usuario !== null) {
            obtener_fases()
        }
    }, [usuario])

    useEffect(() => {
        const loadSignal = async () => {
            await OneSignal.init({
                appId: `${process.env.REACT_APP_ONESIGNAL_API}`,
                allowLocalhostAsSecureOrigin: true
            });
            OneSignal.showSlidedownPrompt();
            await OneSignal.showNativePrompt()
            const isEnabled = await OneSignal.isPushNotificationsEnabled()
            OneSignal.sendTag("uid", usuario!.uid);
            OneSignal.on('popoverShown', () => {
                console.log("here");
            })
            let id = await OneSignal.getUserId()
        }
        if (usuario && usuario.uid === "3082f058285d076c" && process.env.NODE_ENV !== "development") {
            loadSignal()
        }
    }, [usuario]);

    const obtener_fases = async () => {
        try {
            const { data } = await PerfilApi.casos_por_etapa()
            setCantidades(data)
        } catch (error) {

        }
    }

    const obtenerUsuario = async () => {
        try {
            const { data } = await PerfilApi.perfil(localStorage.uid)
            data.usuario.permisos = data.usuario.permisos !== null ? JSON.parse(data.usuario.permisos) : {}
            setUsuarios(data.usuario)
        } catch (error: any) {
            console.log({ error })
            if (error.response.data.message === "Token no valido: inicie sesión nuevamente") {
                localStorage.removeItem('exp_mili')
                window.location.href = '/login'
            }
        }
    }

    const handleActivePage = (text: string) => {
        setActivePage(text)
    }

    const felicitarCliente = async (id_cliente_asesor: number) => {
        try {
            await Clientes.felicitarCliente(id_cliente_asesor)
        } catch (error) {

        }
    }

    return (
        <AppContext.Provider value={{
            activePage,
            handleActivePage,
            openAlert,
            loading,
            handleToggleLoading,
            handleCloseLoading,
            usuario,
            setUsuarios,
            cantidades,
            obtener_fases,
            setExpMili,
        }}>
            {
                children
            }
            <AlertComponent open={open} type={type} message={message} handleClose={handleClose} />
            <Loading open={loading} handleClose={handleCloseLoading} />
        </AppContext.Provider>
    )
}

export const useAppContext = () => {
    const context = useContext(AppContext)
    if (context === undefined) {
        throw new Error('useAppContext must be used within a ContextProvider')
    }
    return context
}

export default AppProvider