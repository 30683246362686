import React from 'react';
import { TextField, Paper } from '@mui/material';

const InputField = ({
    ...props
}) => {
    const handleChange = (ev: any) => {
        props.onChange(ev.target.name, ev.target.value)
    }
    return (
        <Paper style={{
            width: '100%'
        }}>
            <TextField {...props} onChange={handleChange} size="small" variant="outlined" InputLabelProps={{ shrink: true }} />
        </Paper>
    )
};

export default InputField;
