import {useState} from 'react';
import Constructoras from '../api/constructoras';
import httpService from '../api/Http';
import { useAppContext } from '../AppContext';

const ConstructorasHooks = () => {
    const [constructora,
        setConstructora] = useState({nombreConstructora: '', desarrollo: '', buscar: ''});
    const [constructoras,
        setConstructoras] = useState([])
    const [desarrollos,
        setDesarrollos] = useState([])
    const [inmobiliarias,
        setInmobiliarias] = useState([])

    const { openAlert } = useAppContext()

    const obtenerDatos = (name : string, value : any) => {
        setConstructora({
            ...constructora,
            [name]: value
        });
    };

    const obtenerConstructoras = async() => {
        try {
            const {data} : any = await Constructoras.all()
            setConstructoras(data.constructoras.map((item : any) => {
                item.name = item.nombre_constructora
                return item
            }))
        } catch (error) {}
    }

    const obtenerConstructorasDesarrollos = async() => {
        try {
            const {data} : any = await Constructoras.constructoras_desarrollos()
            setConstructoras(data.constructoras_desarrollos)
        } catch (error) {}
    }

    const obtenerDesarrollo = async(id_constructora : number) => {
        try {
            const {data} : any = await Constructoras.desarrollo(id_constructora)
            setDesarrollos(data.desarrollo.map((item : any) => {
                item.name = item.nombre_desarrollo
                return item
            }))
        } catch (error) {
            console.log(error)
        }
    }

    const agregar = async(handleClose : CallableFunction) => {
        try {
            if(constructora.nombreConstructora === ""){
                throw Error('Debe ingresar un nombre valido como constructora para poder continuar')
            }
            await Constructoras.create(constructora.nombreConstructora)
            openAlert('success',"Constructora agregada con exito")
            handleClose()
            obtenerConstructorasDesarrollos()
        } catch (error : any) {
            openAlert('warning', error.message)
        }
    }

    const nuevoDesarrollo = async (handleClose : CallableFunction, id_constructora : number) =>{
        try {
            if(constructora.desarrollo === ""){
                throw Error('Debe ingresar un nombre valido como desarrollo para poder continuar')
            }
            await Constructoras.create_desarrollo( id_constructora ,constructora.desarrollo)
            openAlert('success',"Desarrollo agregado con exito")
            setConstructora({nombreConstructora: '', desarrollo: '', buscar: ''})
            handleClose()
            obtenerConstructorasDesarrollos()
        } catch (error : any) {
            openAlert('warning', error.message)
        }
    }

    const obtener_inmobiliarias = async () =>{
        try {
            const { data } = await new httpService().get(`v1/inmobiliaria/all`)
            setInmobiliarias(data.inmobiliarias.map((item : any) => {
                item.name = item.nombre
                return item
            }))
        } catch (error) {
            
        }
    }
    return {
        constructoras,
        inmobiliarias,
        obtenerDatos,
        obtenerConstructoras,
        obtenerDesarrollo,
        desarrollos,
        obtenerConstructorasDesarrollos,
        agregar,
        nuevoDesarrollo,
        obtener_inmobiliarias
    }
};

export default ConstructorasHooks;
