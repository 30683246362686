import React, { useEffect, useState } from 'react';
import { Box, Button, Grid } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { useAppContext } from '../../AppContext';
import CustomSection from '../../components/CustomSection';
import TableComponent, { createColumns } from '../../components/TableComponent';
import AsesorHook from '../../hooks/AsesorHook';
import { useNavigate } from 'react-router-dom';
import DialogComponent from '../../components/DialogComponent';
import InputField from '../../components/InputForm';
import AsesoresApi from '../../api/asesores';

const columns : GridColDef[] =[
    createColumns({ headerName : "Asesor", field : "nombre"}),
    createColumns({ headerName : "Correo", field : "correo"}),
    createColumns({ headerName : "Oficina", field : "oficina"}),
    createColumns({ headerName : "Rol", field : "tipo_usuario"}),
    createColumns({ headerName : "Esatus", field : "estatus_name"}),
]

const ListaAsesores = () => {
    const navigate = useNavigate()
    const [open, setOpen] = useState(false)
    const {handleActivePage, openAlert} = useAppContext()
    const { getAsesores,usuarios } = AsesorHook()
    const [asesor, setAsesor] = useState<any>(null)
    const [changePassword, setChangePassword] = useState< { newOne: string, copy : string } | null >(null)

    useEffect(()=>{
        handleActivePage('Asesores')
        getAsesores()
    },[])

    const rowSelected = (row : any) =>{
        setAsesor(row.row)
    }

    const handlechange = (name : string, value : string) =>{
        //@ts-ignore
        setChangePassword({...changePassword, [name] : value})
    }
    const modificarPassword = async () =>{
        if( changePassword !== null && changePassword?.newOne !== changePassword?.copy){
            openAlert('warning', "Las contraseñas no coinciden por favor verifiquelas")
            return
        }
        try {
            await AsesoresApi.cambiarPassword(asesor.uid, changePassword?.newOne!)
            openAlert('success', "Contraseña modificada con exito. Debe informar al asesor del cambio.")
            setOpen(false)
            setChangePassword(null)
        } catch (error) {
            openAlert('warning', "Ocurrio un erro al intentar cambiar el password")
        }
    }

    const cambiarEstatus = async (estatus : string) =>{
        try {
            await AsesoresApi.cambiarEstatus(asesor.id, estatus !== "1" ? "1" : "2")
            openAlert('success', "Se actualizo estatus de asesor.")
            getAsesores()
        } catch (error) {
            openAlert('warning', "Ocurrio un erro al intentar cambiar el estatus")
        }
    }
    return (
        <CustomSection>
            <React.Fragment>
                <Grid container>
                    <Grid item md={12}>
                        {
                            asesor !== null &&
                                <Box display='flex' flexDirection='row-reverse' >
                                    {
                                        Number(asesor.estatus) === 1 ?
                                            <Button size="small" color='error' onClick={() => cambiarEstatus(asesor.estatus)}>Desactivar</Button>
                                        :
                                            <Button size="small" color='success' onClick={() => cambiarEstatus(asesor.estatus)}>Activar</Button>
                                    }
                                    <Button size="small" color='info' onClick={() => setOpen(true)} >Modificar Contraseña</Button>
                                    <Button size="small" onClick={() => navigate(`/admin/asesores/editar/${asesor.id}`) } >Editar</Button>
                                </Box>
                        }
                    </Grid>
                    <Grid item md={12}>
                        <div style={{ height: '100%', width: '100%' }}>
                            <TableComponent rows={usuarios} columns={columns} rowCallback={ rowSelected } />
                        </div>
                    </Grid>
                </Grid>
                <DialogComponent
                    open={open} 
                    handleClose={() => setOpen(false)} 
                    DialogText="Cambiar contraseña"
                    fullWidth={true}
                    maxWidth="sm"
                    ButtonActions={{
                        handleSuccessActionText : 'Cambiar',
                        handleSuccessActionFunction : () => modificarPassword()
                    }}
                    >
                        <Grid container>
                            <Grid item md={6} p={1}>
                                <InputField onChange={handlechange} name="newOne" value={changePassword !== null ? changePassword.newOne : ''} label="Nueva contraseña" type="password" fullWidth/>
                            </Grid>
                            <Grid item md={6} p={1}>
                                <InputField onChange={handlechange} name="copy" value={changePassword !== null ? changePassword.copy : ''} label="Confirmar contraseña" type="password" fullWidth/>
                            </Grid>
                        </Grid>
                </DialogComponent>
            </React.Fragment>
        </CustomSection>
    );
};

export default ListaAsesores;