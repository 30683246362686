import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { Box } from '@mui/system';
import React,{ ReactElement, useState } from 'react';

interface SmallDialogProps{
    open : boolean,
    handleClose : () => void,
    children : ReactElement, 
    text : string
}
export const SmallDialogHook = () =>{
    const [open,
        setOpen] = useState(false);

    const handleClickOpen = () : void => {
        setOpen(true);
    };

    const handleClose = () : void => {
        setOpen(false);
    };

    return[
        open,
        handleClickOpen,
        handleClose 
    ] as const
}
const SmallDialog =({ open, handleClose,children, text } : SmallDialogProps) =>{

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">{text}</DialogTitle>
            <DialogContent>
                <Box>
                    {
                        children
                    }
                </Box>
            </DialogContent>
        </Dialog>
    );
}
export default SmallDialog