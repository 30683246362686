import React, { useEffect, useState } from 'react'
import { Box, Grid } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import { useAppContext } from '../../AppContext'
import TableComponent, { createColumns } from '../../components/TableComponent'
import FasesService from '../../api/fases'
import SelectField from '../../components/SelectField'
import { getDistance, mapCasos } from '../../business/Validations'
import { Casos } from '../../business/models/Casos'

interface Props {}

const columns : GridColDef[] =[
    createColumns({ headerName : "Cliente", field : "cliente"}),
    createColumns({ headerName : "Asesor", field : "asesor"}),
    createColumns({ headerName : "Banco", field : "nombre_banco"}),
    createColumns({ headerName : "Ultimo Comentario", field : "comentario", width : 850}),
    createColumns({ headerName : "Dias en fase", field : "dias"}),
]

const array_etapas = [
    { id : 'Analisis' , name : 'Analisis'},
    { id : 'Autorizado' , name : 'Autorizado'},
    { id : 'Avaluo' , name : 'Avaluo'},
    { id : 'Instruccion' , name : 'Instruccion'},
    { id : 'Firma' , name : 'Firma'},
    { id : 'Fondeo' , name : 'Fondeo'},
    { id : 'Cobranza' , name : 'Cobranza'},
    { id : 'Comision' , name : 'Comision'},
]

function ReporteDiario(props : Props) {
    const { handleActivePage } = useAppContext()
    const [ casos, setCasos] = useState<Casos[]>([])
    const [fase, setFase] = useState<string | null>(null)

    useEffect(() => {
        handleActivePage("Reporte Diario")
    }, []);

    useEffect(()=>{
        if(fase !== null){
            obtener_reporte(fase)
        }
    }, [fase])

    const obtener_reporte = async (fase : string) => { 
        try {
            const { data } = await FasesService.reporte_diario_fase(fase)
            setCasos(data.casos.map((item : any) =>{
                item.dias = getDistance(item.dias)
                return item
            }))
        } catch (error : any) {
            console.log(error);
        }
    }

    const rowSelected = (row : any) =>{

    }
    return (
        <Grid container>
            <Grid item md={12}>
                <Box style={{width : '100%'}}>
                    <SelectField label="Etapas" menuRender={array_etapas} onChange={(name, value) => setFase(value.toUpperCase())} name="fases"  />
                </Box>
            </Grid>
            <Grid item md={12}>
                <div style={{ height: '100%', width: '100%' }}>
                    <TableComponent rows={casos} columns={columns} rowCallback={rowSelected}/>
                </div>
            </Grid>
        </Grid>
    )
}

export default ReporteDiario