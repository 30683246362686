import { Box, Button, Divider, Grid } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import FasesService from '../../api/fases'
import { useAppContext } from '../../AppContext'
import CustomSection from '../../components/CustomSection'
import TableComponent, { createColumns } from '../../components/TableComponent'

import { Casos } from '../../business/models/Casos'
import DialogComponent, { DialogHooks } from '../../components/DialogComponent';
import InputField from '../../components/InputForm'
import DateSelector from '../../components/DateSelector'
import ExpedientesApi from '../../api/expedientes'
import { mapCasos } from '../../business/Validations'

type Props = {}

const columns: GridColDef[] = [
    createColumns({ headerName: "Cliente", field: "cliente" }),
    createColumns({ headerName: "Asesor", field: "asesor" }),
    createColumns({ headerName: "Ingresó a banco", field: "fecha_ingreso_banco" }),
    createColumns({ headerName: "Vencimiento", field: "vencimiento" }),
    createColumns({ headerName: "Dias por vencer", field: "por_vencer" }),
    createColumns({ headerName: "Banco", field: "nombre_banco" }),
    createColumns({ headerName: "Esquema", field: "nombre_esquema" }),
    createColumns({ headerName: "Monto Aprobado", field: "monto_autorizado" }),
    createColumns({ headerName: "Monto Solicitado", field: "monto_solicitado" }),
    createColumns({ headerName: "Fase actual", field: "fase" }),
]

const Vencer = (props: Props) => {
    const { handleActivePage, openAlert, handleToggleLoading, handleCloseLoading, usuario } = useAppContext()
    const [expedientes, setExpedientes] = useState<Casos[]>([])
    const [expediente, setExpediente] = useState<Casos | null>(null)
    const [tipo, setTipo] = useState<string | null>(null)
    const [razon, setRazon] = useState<string>("")
    const [isOpen, handleOpen, close] = DialogHooks()

    useEffect(() => {
        handleActivePage('Expedientes por Vencer')
        obtener_por_vencer()
    }, [])

    const obtener_por_vencer = async () => {
        try {
            handleToggleLoading()
            const { data }: { data: { vencer: Casos[] } } = await FasesService.obtener_por_vencer()
            setExpedientes(mapCasos(data.vencer))
            handleCloseLoading()
        } catch (error) {
            handleCloseLoading()
        }
    }

    const seleccionado = (row: any) => {
        setExpediente(row.row)
    }

    const setVencimiento = (value: any) => {
        if (expediente != null)
            setExpediente({ ...expediente, vencimiento: value })
    }
    const handleSuccess = async () => {
        try {
            const { data }: any = (tipo === "vencimiento") ?
                await ExpedientesApi.actualizar_vencimiento(expediente?.id!, expediente?.vencimiento!)
                : await ExpedientesApi.cancelarExpediente(expediente?.id!, razon);
            openAlert('success', data.message);
            obtener_por_vencer()
        } catch (error) {
            openAlert('error', "Hubo un error, intente mas tarde");
        }
    }
    return (
        <CustomSection>
            <Grid container>
                <Grid item xs={12}>
                    {
                        (expediente !== null && (Number(usuario?.tipo_usuario!) & 7) > 0) &&
                        <Box>
                            <Button onClick={() => [setTipo('vencimiento'), handleOpen()]} >
                                Actualizar Vencimiento
                            </Button>
                            <Button onClick={() => [setTipo('eliminar'), handleOpen()]}>
                                Eliminar
                            </Button>
                            <Divider />
                        </Box>
                    }
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ width: '100%' }}>
                        <TableComponent columns={columns} rows={expedientes} rowCallback={seleccionado} />
                    </Box>
                </Grid>
                <DialogComponent
                    open={isOpen}
                    handleClose={close}
                    DialogText={expediente?.cliente.toUpperCase()!}
                    ButtonActions={{
                        handleCancelActionText: 'Cancelar',
                        handleCancelActionFunction: () => close(),
                        handleSuccessActionText: 'Guardar',
                        handleSuccessActionFunction: () => handleSuccess()
                    }}
                    fullWidth={true}
                    maxWidth="lg">
                    {
                        <Grid container>
                            {tipo === "vencimiento" &&
                                <Grid item md={12}>
                                    <DateSelector
                                        label="Fecha de ingreso a banco"
                                        retrieveDate={(date: string) => setVencimiento(date)}
                                    />
                                </Grid>
                            }
                            {tipo === "eliminar" &&
                                <Grid item md={12}>
                                    <InputField fullWidth label="Razon de la cancelación" onChange={(name: string, value: string) => setRazon(value)} />
                                </Grid>
                            }
                        </Grid>
                    }
                </DialogComponent>
            </Grid>
        </CustomSection>
    )
}

export default Vencer