import React, { useEffect, useState } from 'react'
import { Alert, AlertTitle, Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, MenuItem, OutlinedInput, Select, TextField } from '@mui/material'
import { Fases } from '../../business/models/Fases'
import AdministradorApi from '../../api/administrador'
import { useAppContext } from '../../AppContext'

const FasesList = [
  "ANALISIS",
  "AUTORIZADOS",
  "INSTRUCCION",
  "FIRMA",
  "FONDEO"
]
const PalabrasDinamicas = [
  '$monto_autorizado',
  '$monto_firmado',
  '$fecha_autorizado',
  '$fecha_vencido',
  '$fecha_firma',
  '$fecha_probable_firma'
]
const Comunicados = () => {
  const [fases, setFases] = useState<Fases[]>([])
  const [comunicados, setComunicados] = useState<any>(null)
  const [enviar, setEnviar] = useState(false)
  const { handleToggleLoading, handleCloseLoading, openAlert } = useAppContext()

  useEffect(() => {
    handleToggleLoading()
    obtener_comunicados()
  }, [])

  useEffect(() => {
    if (comunicados !== null) {
      setEnviar(true)
    }
  }, [comunicados])

  const obtener_comunicados = async () => {
    const { data } = await AdministradorApi.obtener_comunicados()
    if (data.comunicados !== null && data.comunicados !== "null") {
      if (typeof data.comunicados === "string") {
        const com = JSON.parse(data.comunicados)
        //@ts-ignore
        setFases(Object.keys(com))
        setComunicados(com)
      }
    }
    handleCloseLoading()
  }

  const handleSelectionFase = (ev: any) => {
    let new_values: any = {};
    const values = ev.target.value
    setFases(values)
    values.forEach((element: string) => {
      if (comunicados !== null) {
        const exists = comunicados.hasOwnProperty(element)
        if (exists) {
          new_values = { ...new_values, [element]: comunicados[element] }
          setComunicados(new_values)
        }
      } else {
        setComunicados({ [element]: "" })
      }
    });
  }

  const guardar = async () => {
    let continuar = true
    FasesList.forEach((x: any) => {
      if (comunicados[x]) {
        let validado = verificar_campos_dinamicos(comunicados[x])
        if (!validado) {
          openAlert('warning', 'Se detecto un intento de añadir un Campo dinamico ya que encontramos el simbolo de $, por favor verifique su texto para que pueda guardarlo')
          continuar = false
        }
      }
    })
    if (!continuar) {
      return
    }
    try {
      await AdministradorApi.guardar_comunicados(enviar ? comunicados : null)
      openAlert('success', 'Comunicado guardado')
    } catch (error) {
      openAlert('error', "Ocurrio un error al intentar guardar el comunicado")
    }
  }
  const handleComunicado = (ev: any) => {
    const { value, name } = ev.target
    setComunicados({ ...comunicados, [name]: value })
  }
  /**
   * 
   * @param string texto a validar
   * @returns devolvera un boolean para identificar si se encontraron o no palabras dinamicas en el texto y si contienen un formato correcto
   */
  const verificar_campos_dinamicos = (string: string) => {
    const codigo = string.includes('$')
    let bandera = false
    if (codigo) {
      PalabrasDinamicas.forEach(x => {
        if (string.includes(x)) {
          bandera = true
        }
      })
    }
    return codigo === bandera
  }
  return (
    <Grid container>
      <Grid item md={12}>
        <Alert severity="info">
          <AlertTitle>Nota:</AlertTitle>
          Esta seccion te permite configurar comunicados automaticos, solo debes seleccionar en que etapa el correo se debe enviar y su respectivo mensaje . Los correos se enviaran a  los clientes con copia al asesor.
        </Alert>
      </Grid>
      <Grid item md={12}>
        <FormGroup>
          <FormControlLabel control={<Checkbox checked={enviar} onChange={() => setEnviar(!enviar)} />} label="¿Deseas que el sistema envie comunicados?" />
        </FormGroup>
      </Grid>
      {
        enviar ?
          <>
            <Grid item md={12}>
              <Box>
                <FormControl sx={{ m: 1, width: 300 }}>
                  <InputLabel id="demo-multiple-name-label">Selecciona una o mas fases</InputLabel>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    multiple
                    value={fases}
                    onChange={handleSelectionFase}
                    input={<OutlinedInput label="Name" />}
                  >
                    {FasesList.map((name) => (
                      <MenuItem
                        key={name}
                        value={name}
                      >
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item md={12}>
              <Grid container>
                {
                  fases.map(item => (
                    <Grid item md={12} key={item}>
                      <FormControl sx={{ m: 1, width: "100%" }}>
                        <TextField
                          id={item}
                          label={`Comunicado en ${item.toLowerCase()}`}
                          name={item}
                          multiline
                          maxRows={4}
                          onChange={handleComunicado}
                          value={comunicados[item]}
                          fullWidth
                          helperText="Puedes usar $monto_firmado, $monto_autorizado, $fecha_autorizado, $fecha_vencido, $fecha_firma, como campos dinamicos a su texto."
                        />
                      </FormControl>
                    </Grid>
                  ))
                }
              </Grid>
            </Grid>
          </>
          : null
      }
      <Grid item md={12}>
        <Button onClick={() => guardar()}>Guardar</Button>
      </Grid>
    </Grid>
  )
}

export default Comunicados