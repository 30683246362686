import React, { useEffect, useState } from 'react';
import { Alert, Box, Button, Card, CardActions, CardContent, Grid, Typography } from '@mui/material';
import InputField from '../../components/InputForm';
import SaveIcon from '@mui/icons-material/Save';
import NotariaHooks from '../../hooks/NotariasHook';
import { useAppContext } from '../../AppContext';
import TableComponent, { createColumns } from '../../components/TableComponent';
import { GridColDef } from '@mui/x-data-grid';

const columns: GridColDef[] = [
  createColumns({ headerName: "Notaria", field: "notaria" }),
  createColumns({ headerName: "Responsable", field: "nombre_notaria" }),
  createColumns({ headerName: "Ciudad", field: "ciudad_notaria" })
]

const Notarias = () => {
  const { handleActivePage } = useAppContext()
  const { notarias, notaria, obtenerDatos, nueva_notaria, editar, clearSelection, actualizar, actualizarEstatus, seleccion, setSeleccion } = NotariaHooks();
  const [estatus, setEstatus] = useState(0)


  useEffect(() => {
    handleActivePage('Notarias')
  }, [])

  useEffect(() => {
    if (seleccion !== null) {
      const { estatus } = notarias.find(x => x.id === seleccion)!
      setEstatus(Number(estatus))
    } else {
      clearSelection()
    }
  }, [seleccion])

  return (
    <>
      <Grid container justifyContent='space-between'>
        <Grid item md={4} sx={{ paddingRight: 2 }}>
          <Box>
            <Card>
              <CardContent>
                <Typography component="h6" variant="h6">
                  Nueva Notaria
                </Typography>
                <Box mt={2}>
                  <InputField
                    type="text"
                    name="notaria"
                    onChange={obtenerDatos}
                    id="notaria"
                    label="Numero de Notaria"
                    fullWidth
                    value={notaria.notaria}
                  />
                </Box>
                <Box mt={2}>
                  <InputField
                    type="text"
                    name="nombre_notaria"
                    onChange={obtenerDatos}
                    id="nombre_notaria"
                    label="Responsable de Notaria"
                    fullWidth
                    value={notaria.nombre_notaria}
                  />
                </Box>
                <Box mt={2}>
                  <InputField type="text" name="ciudad_notaria" onChange={obtenerDatos} id="ciudad_notaria" label="Ciudad" fullWidth value={notaria.ciudad_notaria} />
                </Box>
              </CardContent>
              <CardActions>
                <Button variant="contained" color="primary" size="small" startIcon={<SaveIcon />} onClick={() => (seleccion !== null && notaria.id !== "0") ? actualizar(seleccion) : nueva_notaria()}>
                  {
                    (seleccion !== null && notaria.id !== "0") ?
                      "Actualizar"
                      :
                      "Guardar"
                  }
                </Button>
                {
                  (seleccion !== null && notaria.id !== "0") &&
                  <Button variant="text" color="primary" size="small" startIcon={<SaveIcon />} onClick={() => setSeleccion(null)}>
                    Cancelar
                  </Button>
                }
              </CardActions>
            </Card>
          </Box>
        </Grid>

        <Grid item md={8}>
          <Box>
            <Card>
              <CardContent>
                <Alert variant="outlined" severity="info">
                  Las notarias dadas de baja, aun apareceran en la siguiente lista por si desean activarlas en algun momento.
                </Alert>
                <Grid container>
                  <Grid item xs={12}>
                    {
                      seleccion !== null &&
                      <Box display='flex' flexDirection='row-reverse' >
                        {
                          Boolean(estatus) ?
                            <Button
                              size='small'
                              color='error'
                              onClick={() => actualizarEstatus(seleccion, false)}
                            >
                              Desactivar
                            </Button>
                            :
                            <Button
                              size='small'
                              color='success'
                              onClick={() => actualizarEstatus(seleccion, true)}
                            >
                              Activar
                            </Button>
                        }
                        <Button
                          size="small"
                          color='info'
                          onClick={() => editar(seleccion)}
                        >Editar notaria</Button>
                      </Box>
                    }
                  </Grid>
                  <Grid item xs={12}>
                    <div
                      style={{
                        height: 300,
                        width: '100%'
                      }}>
                      <TableComponent rows={notarias} columns={columns} rowCallback={(row) => { setSeleccion(row.id) }} />
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Notarias;
