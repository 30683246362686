import httpService from "./Http"

class ComentariosService{

    static async get(id_caso : number){
        return await new httpService().get(`v1/comentarios/${ id_caso }`)
    }

    static async guardar(payload : any){
        return await new httpService().post('v1/comentarios', payload)
    }
    
}
export default ComentariosService