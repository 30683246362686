export enum Fases {
    INTEGRACION = "INTEGRACION",
    INTEGRADOS = "INTEGRADOS",
    VENCIDOS = "VENCIDOS",
    POR_VENCER = "POR_VENCER",
    ANALISIS = "ANALISIS",
    AUTORIZADO = "AUTORIZADO",
    AVALUO = "AVALUO",
    INSTRUCCION = "INSTRUCCION",
    FIRMA = "FIRMA",
    FONDEO = "FONDEO",
    COBRANZA = "COBRANZA",
    COMISION = "COMISION",
    PAGO = "PAGO"
}
export interface CantidadFases {
    [key: string]: number
    proyecciones: Proyecciones | any
}

export interface Proyecciones {
    monto_firmado: number
    monto_por_firmar: number
    firmado: number
    por_firmar: number
    monto_proyectado: number
}