import { Cliente } from "../business/models/Cliente"
import httpService from "./Http"

class Clientes {

    static async clientePorQuery(curp: string) {
        return await new httpService().get(`v1/cliente?query=${curp}`)
    }

    static async all() {
        return await new httpService().get(`v1/cliente/lista`)
    }

    static async cliente(id: number) {
        return await new httpService().get(`v1/cliente/detalles/${id}`)
    }

    static async save(cliente: Cliente) {
        return await new httpService().post('v1/cliente', cliente)
    }
    static async update(id: string, cliente: Cliente) {
        return await new httpService().put(`v1/cliente/${id}`, cliente)
    }
    static async delete(id: number) {
        return await new httpService().put(`v1/cliente/cancelar`, { id })
    }
    static async cambiarAsesor(nuevoAsesor: number, id_cliente: number, id_cliente_asesor: number) {
        return await new httpService().put(`v1/cliente/cambiar_asesor`, { nuevoAsesor, id_cliente, id_cliente_asesor })
    }
    static async buscarCliente(nombre: string) {
        return await new httpService().get(`v1/cliente/buscar/${nombre}`)
    }
    static async obtenerBirthdays() {
        return await new httpService().get(`v1/cliente/birthDays`)
    }
    static async felicitarCliente(id_cliente_asesor: number) {
        return await new httpService().post(`v1/cliente/birthDays`, { id_cliente_asesor })
    }
}

export default Clientes;