import React, { useEffect, useState } from 'react';
import { Alert, Box, Button, Card, CardActions, CardContent, Grid, Typography } from '@mui/material';
import InputField from '../../components/InputForm';
import SaveIcon from '@mui/icons-material/Save';
import ValuadoresHooks from '../../hooks/ValuadoresHook';
import TableComponent, { createColumns } from '../../components/TableComponent';
import { GridColDef } from '@mui/x-data-grid';

const columns: GridColDef[] = [
  createColumns({ headerName: "Valuador", field: "nombre_valuador" }),
  createColumns({ headerName: "Correo", field: "correo" }),
  createColumns({ headerName: "Telefono", field: "telefono" })
]

const Valuadores = () => {
  const { valuador, obtenerDatos, nuevo_valuador, setNombrePagina, valuadores, seleccion, setSeleccion, clearSelection, actualizarEstatus, editar, actualizar } = ValuadoresHooks();
  const [estatus, setEstatus] = useState(0)

  useEffect(() => {
    setNombrePagina()
  }, [])

  useEffect(() => {
    if (seleccion !== null) {
      const { activo } = valuadores.find(x => x.id === seleccion)!
      setEstatus(Number(activo))
    } else {
      clearSelection()
    }
  }, [seleccion])

  return (
    <>
      <Grid container>
        <Grid item md={4} sx={{ paddingRight: 2 }}>
          <Box>
            <Card>
              <CardContent>
                <Typography component="h6" variant="h6">
                  Nuevo Valuador
                </Typography>
                <Box mt={2}>
                  <InputField
                    type="text"
                    name="nombre_valuador"
                    onChange={obtenerDatos}
                    id="nombre_valuador"
                    value={valuador.nombre_valuador}
                    label="Nombre de Valuador"
                    fullWidth
                  />
                </Box>
                <Box mt={2}>
                  <InputField
                    type="text"
                    name="correo"
                    onChange={obtenerDatos}
                    id="correo"
                    value={valuador.correo}
                    label="correo del Valuador"
                    fullWidth
                  />
                </Box>
                <Box mt={2}>
                  <InputField
                    type="text"
                    name="telefono"
                    onChange={obtenerDatos}
                    id="telefono"
                    value={valuador.telefono}
                    label="telefono del Valuador"
                    fullWidth
                  />
                </Box>
              </CardContent>
              <CardActions>
                <Button variant="contained" color="primary" size="small" startIcon={<SaveIcon />}
                  onClick={() => (seleccion !== null && valuador.id !== "0") ? actualizar(seleccion) : nuevo_valuador()}>
                  {
                    (seleccion !== null && valuador.id !== "0") ?
                      "Actualizar"
                      :
                      "Guardar"
                  }
                </Button>
                {
                  (seleccion !== null && valuador.id !== "0") &&
                  <Button variant="text" color="primary" size="small" startIcon={<SaveIcon />} onClick={() => setSeleccion(null)}>
                    Cancelar
                  </Button>
                }
              </CardActions>
            </Card>
          </Box>
        </Grid>
        <Grid item md={8}>
          <Box>
            <Card>
              <CardContent>
                <Alert variant="outlined" severity="info">
                  Los valuadores dados de baja, aun apareceran en la siguiente lista por si desean activarlas en algun
                  momento.
                </Alert>
                <Grid container>
                  <Grid item xs={12}>
                    {
                      seleccion !== null &&
                      <Box display='flex' flexDirection='row-reverse' >
                        {
                          Boolean(estatus) ?
                            <Button
                              size='small'
                              color='error'
                              onClick={() => actualizarEstatus(seleccion, false)}
                            >
                              Desactivar
                            </Button>
                            :
                            <Button
                              size='small'
                              color='success'
                              onClick={() => actualizarEstatus(seleccion, true)}
                            >
                              Activar
                            </Button>
                        }
                        <Button
                          size="small"
                          color='info'
                          onClick={() => editar(seleccion)}
                        >Editar valuador</Button>
                      </Box>
                    }
                  </Grid>
                  <Grid item xs={12}>
                    <div
                      style={{
                        height: 300,
                        width: '100%'
                      }}>
                      <TableComponent rows={valuadores} columns={columns} rowCallback={(row) => setSeleccion(row.id)} />
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Valuadores;
