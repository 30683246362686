// project import
import { Box } from '@mui/material';
import Navigation from './Navigation';

// ==============================|| DRAWER CONTENT ||============================== //

const DrawerContent = ({ open }: { open: boolean }) => (
  <Box
    sx={{
      '& .simplebar-content': {
        display: 'flex',
        flexDirection: 'column'
      }
    }}
  >
    <Box sx={{ flexGrow: 1 }}>
      <Navigation open={open} />
    </Box>
  </Box>
);

export default DrawerContent;
