import httpService from "./Http"

class Constructoras{

    static async create(constructora : string ){
        try {
            return await new httpService().post(`v1/constructora`, { nombre : constructora })
        } catch (error) {
            
        }
    }

    static async all(){
        return await new httpService().get(`v1/constructora/all`)
    }

    static async desarrollo(id_constructora : number){
        return await new httpService().get(`v1/desarrollo/desarrollo_constructora/${id_constructora}`)
    }
    
    static async constructoras_desarrollos(){
        return await new httpService().get(`v1/constructora/desarrollos`)
    }

    static async create_desarrollo(id_constructora : number, desarrollo : string ){
        return await new httpService().post(`v1/desarrollo`,{
            id_constructora,
            nombre : desarrollo
        })
    }
    
}
export default Constructoras