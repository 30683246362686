import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ExpedientesApi from '../api/expedientes'
import { useAppContext } from '../AppContext'
import DialogComponent, { DialogHooks } from './DialogComponent'
import InputField from './InputForm'

type Props = {
    id_caso : number,
    open : boolean,
    close : () => void
    handleSuccessResponse? : CallableFunction
}

const CancelarCaso = (props : Props) => {
    const [ razon, setRazon ] = useState('')
    const { openAlert } = useAppContext()

    const handleChange = (name : string, value : any) =>{
        setRazon(value)
    }

    const handleSuccess = async () =>{
        if(razon !== ""){
            try {
                await ExpedientesApi.cancelarExpediente(props.id_caso, razon)
                openAlert('success','Expediente cancelado con exito')
                if(props.handleSuccessResponse){
                    props.handleSuccessResponse()
                }
            } catch (error) {
                openAlert('error','Hubo un error al cancelar el expediente')
            }
        }
    }
    return (
        <DialogComponent
            open={props.open} 
            handleClose={props.close} 
            DialogText="Eliminar expediente"
            fullWidth={true}
            maxWidth="lg"
            ButtonActions={{
                handleSuccessActionText : 'Enviar',
                handleCancelActionText : 'Cancelar',
                handleSuccessActionFunction : () => handleSuccess(),
                handleCancelActionFunction : () => props.close()
            }}
            >
            <Box
                sx={{
                    display : 'flex',
                    flexDirection : 'row',
                    maxWidth: '100%',
                }}
            >
                <InputField label='Razón de cancelacion' onChange={handleChange} fullWidth name="razon" />
            </Box>
        </DialogComponent>
    )
}

export default CancelarCaso