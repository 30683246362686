import React, { useEffect } from 'react';
import { Grid, Stack } from '@mui/material';
import { Box } from '@mui/system';
import InputField from '../../components/InputForm';
import SelectField from '../../components/SelectField';
import PaperInput from '../../components/PaperInput';
import LoadingButtonComponent from '../../components/LoadingButton';
import CustomSection from '../../components/CustomSection';
import { useAppContext } from '../../AppContext';
import ClientesHooks from '../../hooks/ClientesHook';
import DateSelector from '../../components/DateSelector';
import ConstructorasHooks from '../../hooks/ConstructorasHook';
import { getRole } from '../../business/Validations';
import { TipoUsuario } from '../../business/models/Asesor';
import AsesorHook from '../../hooks/AsesorHook';
import { useParams } from 'react-router-dom';

const Alta = () => {
    const { id } = useParams()
    const { usuario,handleActivePage } = useAppContext()
    const { cliente, loading, query, initialState, obtenerDatos, buscarClientePorQuery, guardar, actualizar, setCliente, buscarCliente, setQuery } = ClientesHooks()
    const { usuarios, getAsesores} : any = AsesorHook()
    const { constructoras, desarrollos, inmobiliarias ,obtenerConstructoras, obtenerDesarrollo, obtener_inmobiliarias} = ConstructorasHooks()

    useEffect(()=>{
        handleActivePage('Alta Prospecto')
        Promise.all([
            obtenerConstructoras(),
            obtener_inmobiliarias()
        ])
        return () =>{
            setCliente(initialState)
        }
    },[])

    useEffect(()=>{
        const load = async () =>{
            setCliente(await buscarCliente(Number(id)))
        }
        if(id){
            load()
            handleActivePage(`Editar Cliente:  ${ cliente.nombre } ${ cliente.apellido_materno} ${cliente.apellido_paterno}`)
        }
    },[id, cliente.id])

    useEffect(()=>{
        obtenerDesarrollo(cliente.id_constructora)
    },[cliente.id_constructora])

    useEffect(()=>{
        if(getRole(Number(usuario?.tipo_usuario)) === TipoUsuario.Externo){
            setCliente({ ...cliente, id_inmobiliaria : usuario!.id_inmobiliaria, tipo_compra : 1, referido : usuario!.id })
        }
        getAsesores()
    },[usuario])
    return (
        <CustomSection>
            <Grid container spacing={2}>
                {
                id === undefined &&
                    <Grid item xs={12} md={12}>
                        <Box>
                            <PaperInput
                                name="query"
                                value={query}
                                onChange={(ev : any) => setQuery(ev.target.value)}
                                onKeyUp={ (event : any) => event.keyCode === 13 && buscarClientePorQuery() }
                                handleClickIcon={() => buscarClientePorQuery() }
                                placeholder="Busca cliente por Curp, Telefono o correo"
                                id="buscarCliente"
                            />
                        </Box>
                    </Grid>
                }
                <br />
                <Grid item xs={12} md={3}>
                    <Box>
                        <InputField
                            type="text"
                            name="curp"
                            value={cliente.curp}
                            onChange={obtenerDatos}
                            id="Curp"
                            label="CURP"
                            fullWidth/>
                    </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Box>
                        <InputField
                            type="text"
                            name="apellido_paterno"
                            value={cliente.apellido_paterno}
                            onChange={obtenerDatos}
                            id="Paterno"
                            label="Apellido Paterno"
                            fullWidth/>
                    </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Box>
                        <InputField
                            type="text"
                            name="apellido_materno"
                            value={cliente.apellido_materno}
                            onChange={obtenerDatos}
                            id="materno"
                            label="Apellido Materno"
                            fullWidth/>
                    </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Box>
                        <InputField
                            type="text"
                            name="nombre"
                            value={cliente.nombre}
                            onChange={obtenerDatos}
                            id="nombre"
                            label="Nombre(s)"
                            fullWidth/>
                    </Box>
                </Grid>

                <Grid item xs={12} md={3}>
                    <Box>
                        <DateSelector
                            label = "Fecha Nacimiento"
                            retrieveDate={( date : string ) => obtenerDatos("fecha_nacimiento", date) }
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Box>
                        <InputField
                            type="text"
                            name="correo"
                            value={cliente.correo}
                            onChange={obtenerDatos}
                            id="correo"
                            label="Correo"
                            fullWidth/>
                    </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Box>
                        <InputField
                            type="text"
                            name="telefono"
                            value={cliente.telefono}
                            onChange={obtenerDatos}
                            id="telefono"
                            label="Telefono"
                            fullWidth/>
                    </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Box>
                        <InputField
                            type="text"
                            name="ingreso_mensual"
                            value={cliente.ingreso_mensual}
                            onChange={obtenerDatos}
                            id="ingresoMensual"
                            label="Ingreso Mensual"
                            fullWidth/>
                            <span>{`$${new Intl.NumberFormat().format(cliente.ingreso_mensual)}`}</span>
                    </Box>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Box>
                        <InputField
                            type="text"
                            name="valor_vivienda"
                            value={cliente.valor_vivienda}
                            onChange={obtenerDatos}
                            id="valorVivienda"
                            label="Valor de la Vivienda"
                            fullWidth/>
                            <span>{`$${new Intl.NumberFormat().format(cliente.valor_vivienda)}`}</span>
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Box>
                        <SelectField
                            label="Tipo de economia"
                            menuRender={[
                                { id : "Asalariado", name : "Asalariado"},
                                { id : "Independiente", name : "Independiente"},
                                { id : "Profesionista", name : "Profesionista"},
                                { id : "Accionista", name : "Accionista"},
                                { id : "Mixto", name : "Mixto"},
                            ]}
                            onChange={obtenerDatos}
                            name="tipo_economia"
                            value={cliente.tipo_economia}
                            />
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Box>
                        <SelectField
                            label="Tipo de compra"
                            menuRender={[
                                { id : -1, name : 'Ninguno'},
                                { id : 0, name : 'Desarrollo'},
                                { id : 1, name : 'Mercado abierto'}
                            ]}
                            onChange={obtenerDatos}
                            name="tipo_compra"
                            value={cliente.tipo_compra}
                            disabled = { usuario ? (getRole(usuario.tipo_usuario) === TipoUsuario.Externo) : false }
                            />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    {
                        Number(cliente.tipo_compra) === 0 ?
                        <Box display='flex' flexDirection='row' >
                            <Box>
                                <SelectField
                                    label="Selecciona una constructora"
                                    menuRender={constructoras}
                                    onChange={obtenerDatos}
                                    name="id_constructora"
                                    value={cliente.id_constructora}
                                />
                            </Box>
                            <Box>
                                <SelectField
                                    label="Selecciona un desarrollo"
                                    menuRender={desarrollos}
                                    onChange={obtenerDatos}
                                    name="id_desarrollo"
                                    value={cliente.id_desarrollo}
                                />
                            </Box>
                        </Box>
                        :
                        Number(cliente.tipo_compra) === 0 &&
                        <Box display='flex' flexDirection='row'>
                            <Box>
                                <SelectField
                                    label="Inmobiliaria"
                                    menuRender={inmobiliarias}
                                    onChange={obtenerDatos}
                                    name="id_inmobiliaria"
                                    value={cliente.id_inmobiliaria}
                                    />
                            </Box>
                            <Box>
                                <SelectField
                                    label="Selecciona un asesor"
                                    menuRender={usuarios}
                                    onChange={obtenerDatos}
                                    name="id_asesor"
                                    value={cliente.id_asesor}
                                    />
                            </Box>
                        </Box>
                    }
                </Grid>
                <Grid item xs={12}>
                    <Stack direction="row-reverse">
                        <LoadingButtonComponent
                            callback ={ () => id ? actualizar(id) : guardar() }
                            label = { id ? 'Actualizar' : "Guardar"}
                            loading = { loading }
                            color="success"
                        />
                    </Stack>
                </Grid>
            </Grid>
        </CustomSection>
    );
};

export default Alta;