// material-ui
import { ReactNode } from 'react';
import { Box, Typography } from '@mui/material';
import PieChartIcon from '@mui/icons-material/PieChart';

import HomeIcon from '@mui/icons-material/Home';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CheckCircleOutlined from '@mui/icons-material/CheckCircleOutlined';
import { TipoUsuario } from '../../../../../business/models/Asesor';
import { ChartBar } from '../../../../../assets/icons/chart-bar';
import { UserAccess } from '../../../../../business/models/UserAccess';
import { VerificarAccesos } from '../../../../../business/components/TieneAcceso';
import { User } from '../../../../../assets/icons/user';
import { Users } from '../../../../../assets/icons/users';
import NavGroup from './NavGroup';

import MenuComponent from '../../../MenuList';
import { NavItem } from '../../../nav-item';

export interface NavProps {
  path: string,
  icon: ReactNode | null,
  title: string,
  isMenu?: boolean,
  menu?: NavProps[],
  canAccess: boolean
}
const Navigation = ({ open }: { open: boolean }) => {
  const { buildContent } = VerificarAccesos()

  const items: NavProps[] = [
    {
      path: '/admin/principal',
      icon: (<HomeIcon fontSize="small" />),
      title: 'Principal',
      isMenu: false,
      canAccess: true
    },
    {
      path: '/admin/home',
      icon: (<DashboardIcon fontSize="small" />),
      title: 'Administrar oficina',
      isMenu: false,
      canAccess: buildContent({ tipo_usuario: TipoUsuario.Administrador })
    },
    {
      path: 'estadisticas',
      icon: (<ChartBar fontSize="small" />),
      title: 'Estadisticas',
      isMenu: true,
      canAccess: true,
      menu: [
        {
          path: '/admin/estadistica/bitacora',
          icon: (<PieChartIcon fontSize="small" />),
          title: 'Bitacora',
          isMenu: false,
          canAccess: buildContent({ tipo_usuario: TipoUsuario.Administrador }),
        },
        {
          path: '/admin/estadistica/firmados',
          icon: (<CheckCircleOutlined fontSize="small" />),
          title: 'Firmados',
          isMenu: false,
          canAccess: buildContent({ tipo_usuario: (UserAccess.Admin + UserAccess.Control + UserAccess.Gerente + UserAccess.Asesor) }),
        },
        {
          path: '/admin/estadistica/asesores',
          icon: (<CheckCircleOutlined fontSize="small" />),
          title: 'Asesores',
          isMenu: false,
          canAccess: buildContent({ tipo_usuario: (UserAccess.Admin + UserAccess.Gerente) }),
        },
        {
          path: '/admin/estadistica/bancos',
          icon: (<CheckCircleOutlined fontSize="small" />),
          title: 'Bancos',
          isMenu: false,
          canAccess: buildContent({ tipo_usuario: TipoUsuario.Administrador }),
        },
        {
          path: '/admin/reporte/diario',
          icon: (<CheckCircleOutlined fontSize="small" />),
          title: 'Reporte diario',
          isMenu: false,
          canAccess: buildContent({ tipo_usuario: (UserAccess.Admin + UserAccess.Gerente + UserAccess.Asesor + UserAccess.Control) }),
        },
        {
          path: '/admin/estadistica/comparativo/bancos',
          icon: (<CheckCircleOutlined fontSize="small" />),
          title: 'Comparativo bancos',
          isMenu: false,
          canAccess: buildContent({ tipo_usuario: TipoUsuario.Administrador }),
        },
        {
          path: '/admin/estadistica/comparativo/asesores',
          icon: (<CheckCircleOutlined fontSize="small" />),
          title: 'Comparativo asesores',
          isMenu: false,
          canAccess: buildContent({ tipo_usuario: TipoUsuario.Administrador }),
        },
      ]
    },
    {
      path: 'clientes',
      icon: (<User fontSize="small" />),
      title: 'Clientes',
      isMenu: true,
      canAccess: true,
      menu: [
        {
          path: '/admin/cliente/buscar',
          icon: (<Users fontSize="small" />),
          title: 'Buscar',
          isMenu: false,
          canAccess: buildContent({ tipo_usuario: 7, permisos: { lectura: true } }),
        },
        {
          path: '/admin/cliente/alta',
          icon: (<Users fontSize="small" />),
          title: 'Nuevo cliente',
          isMenu: false,
          canAccess: buildContent({ tipo_usuario: 3, permisos: { clientes: true } }),
        },
        {
          path: '/admin/cliente/lista',
          icon: (<Users fontSize="small" />),
          title: 'Clientes',
          isMenu: false,
          canAccess: buildContent({ tipo_usuario: 3, permisos: { clientes: true } }),
        },
      ]
    },
    {
      path: 'asesores',
      icon: (<Users fontSize="small" />),
      title: 'Asesores',
      isMenu: true,
      canAccess: buildContent({ tipo_usuario: TipoUsuario.Administrador }),
      menu: [
        {
          path: '/admin/asesores/alta',
          icon: (<Users fontSize="small" />),
          title: 'Nuevo',
          isMenu: false,
          canAccess: buildContent({ tipo_usuario: TipoUsuario.Administrador }),
        },
        {
          path: '/admin/asesores/lista',
          icon: null,
          title: 'Lista',
          isMenu: false,
          canAccess: buildContent({ tipo_usuario: TipoUsuario.Administrador }),
        },
      ]
    },
    {
      path: 'catalogos',
      icon: (<Users fontSize="small" />),
      title: 'Catalogos',
      isMenu: true,
      canAccess: buildContent({ tipo_usuario: TipoUsuario.Administrador, permisos: { catalogos: true } }),
      menu: [
        {
          path: '/admin/catalogo/constructoras',
          icon: null,
          title: 'Constructoras',
          isMenu: false,
          canAccess: buildContent({ tipo_usuario: TipoUsuario.Administrador, permisos: { catalogos: true } }),
        },
        {
          path: '/admin/catalogo/bancos',
          icon: null,
          title: 'Bancos',
          isMenu: false,
          canAccess: buildContent({ tipo_usuario: TipoUsuario.Administrador, permisos: { catalogos: true } }),
        },
        {
          path: '/admin/catalogo/notarias',
          icon: null,
          title: 'Notarias',
          isMenu: false,
          canAccess: buildContent({ tipo_usuario: TipoUsuario.Administrador, permisos: { catalogos: true } }),
        },
        {
          path: '/admin/catalogo/valuadores',
          icon: null,
          title: 'Valuadores',
          isMenu: false,
          canAccess: buildContent({ tipo_usuario: TipoUsuario.Administrador, permisos: { catalogos: true } }),
        },
      ]
    },
  ];

  const navGroups = items.map((item, index) => (
    item.canAccess ? (
      item.isMenu ?
        <MenuComponent key={item.title} menuRoute={item} />
        :
        <NavItem
          key={item.title}
          icon={item.icon}
          href={item.path}
          title={item.title}
        />
    ) : null
  ))
  return <Box sx={{ pt: 2 }}>{navGroups}</Box>;
};

export default Navigation;

