import httpService from "./Http"

class PerfilApi{

    static async casos_por_etapa(){
        return await new httpService().get(`v1/perfil/expedientes_fases`)
    }
    static async perfil(uid : string){
        return await new httpService().get(`v1/perfil/${uid}`)
    }
}

export default PerfilApi