import React, { useState, useEffect } from 'react';
import { Alert, AlertTitle, Box, Button, Grid } from '@mui/material';
import { useAppContext } from '../../AppContext';

import AutocompleteComponent from '../../components/AutocompleteComponent';
import ClientesHooks from '../../hooks/ClientesHook';
import { useNavigate } from 'react-router-dom';
import Expedientes from './Expedientes';
import CustomSection from '../../components/CustomSection';
import TieneAcceso from '../../business/components/TieneAcceso';
import { TipoUsuario } from '../../business/models/Asesor';
import DialogComponent, { DialogHooks } from '../../components/DialogComponent';
import AsesorHook from '../../hooks/AsesorHook';
import SelectField from '../../components/SelectField';

const Buscar = () => {
    const { handleActivePage, handleCloseLoading, usuario } = useAppContext()
    const { clientes, cliente, ObtenerClientes, buscarCliente, setCliente, cambiarCliente, nuevoAsesor, setNuevoAsesor, findCliente } = ClientesHooks()
    const { usuarios, getAsesores }: any = AsesorHook()
    const [newClientes, setNewClientes] = useState<any[]>([])

    const [open, handleClickOpen, handleClose] = DialogHooks()

    const navigate = useNavigate()

    useEffect(() => {
        handleActivePage('Buscar Cliente')
        // ObtenerClientes()
        getAsesores()
    }, [])

    useEffect(() => {
        const new_list = clientes.map((item: any) => {
            return {
                label: item.nombre_completo,
                id: Number(item.id),
                id_cliente_asesor: item.id_cliente_asesor
            }
        })
        setNewClientes(new_list)
    }, [clientes])

    const seleccion = async (value: any) => {
        const data = await buscarCliente(value.id_cliente_asesor)

        handleCloseLoading()
        if (data === null) {
            return
        }
        setCliente(data)
    }


    return (
        <Grid container>
            <DialogComponent
                open={open}
                handleClose={handleClose}
                DialogText='Cambio de asesor'
                maxWidth="lg"
                ButtonActions={{
                    handleSuccessActionText: 'Cambiar',
                    handleCancelActionText: 'Cancelar',
                    handleSuccessActionFunction: () => [cambiarCliente(nuevoAsesor!, cliente.id!, cliente.id_cliente_asesor), handleClose()],
                    handleCancelActionFunction: () => handleClose()
                }}
            >
                <div style={{ width: '100%' }}>
                    <SelectField
                        label="Selecciona un asesor"
                        menuRender={usuarios}
                        onChange={(name, value) => setNuevoAsesor(value)}
                        name="id_asesor"
                        value={cliente.id_asesor}
                    />
                </div>
            </DialogComponent>
            <Grid item xs={12} sx={{ marginBottom: 5 }}>
                <CustomSection>
                    <>
                        <Grid item xs={12} sx={{ marginBottom: 2 }}>
                            <Grid item xs={4}>
                                <AutocompleteComponent
                                    label="Cliente"
                                    options={newClientes}
                                    seleccion={seleccion}
                                    buscar={findCliente}
                                    id='id_cliente_asesor' />
                            </Grid>
                        </Grid>
                        {
                            newClientes.length === 0 ?
                                <Grid item xs={12}>
                                    <Alert severity="info">
                                        <AlertTitle>Atención</AlertTitle>
                                        Para buscar un cliente ingrese nombre seguido de apellidos o primero apellidos y seguido de nombre (s)
                                    </Alert>
                                </Grid> : null
                        }
                        {
                            Number(cliente.id)! > 0 &&
                            <Grid item xs={12}>
                                <Grid container >
                                    <Grid item xs={12} display='flex' flexDirection='row' >
                                        <TieneAcceso permisos={{ registro_expediente: true }} tipo_usuario={TipoUsuario.Administrador}>
                                            <React.Fragment>
                                                <Box sx={{ marginRight: 2 }}>
                                                    <Button
                                                        variant="contained"
                                                        color="warning"
                                                        onClick={() => navigate(`/admin/expediente/crear/${cliente.id_cliente_asesor}`)}
                                                        size="small">
                                                        Agregar expediente a banco
                                                    </Button>
                                                </Box>
                                                <Box sx={{ marginRight: 2 }}>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() => handleClickOpen()}
                                                        size="small">
                                                        Cambiar de asesor
                                                    </Button>
                                                </Box>
                                            </React.Fragment>
                                        </TieneAcceso>
                                        {
                                            (cliente.id_asesor === usuario?.id || (Number(usuario?.tipo_usuario) & 13) > 0) ?
                                                <Box>
                                                    <Button
                                                        variant="contained"
                                                        color="inherit"
                                                        onClick={() => navigate(`/admin/cliente/editar/${cliente.id_cliente_asesor}`)}
                                                        size="small">
                                                        Editar cliente
                                                    </Button>
                                                </Box>
                                                : null
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                    </>
                </CustomSection>
            </Grid>
            <Grid item md={12}>
                <Expedientes id={cliente.id_cliente_asesor} cliente={cliente.nombre} />
            </Grid>
        </Grid>
    );
};

export default Buscar;