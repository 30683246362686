import { useState, useEffect } from 'react';
import Notarias from '../api/notarias';
import { useAppContext } from '../AppContext';
import { Notaria } from '../business/models/Notarias';

const NotariaHooks = (estatus = 0) => {
    const { openAlert } = useAppContext()

    useEffect(() => {
        obtener_notarias(estatus)
    }, [])

    const [notarias,
        setNotarias] = useState<Notaria[]>([])
    const [notaria,
        setNotaria] = useState<Notaria>({ id: "0", notaria: '', name: '', nombre_notaria: '', ciudad_notaria: '', buscar: '', estatus: 1 });
    const [seleccion, setSeleccion] = useState<null | string>(null)

    const obtenerDatos = (name: string, value: any) => {
        setNotaria({
            ...notaria,
            [name]: value
        });
    };

    const obtener_notarias = async (estatus: number) => {
        try {
            let { data }: any = await Notarias.all(estatus)
            setNotarias(data.notarias.map((item: any) => {
                item.name = `${item.notaria} - ${item.nombre_notaria}`
                return item
            }))
        } catch (error) { }
    }

    const nueva_notaria = async () => {
        try {
            await Notarias.create(notaria)
            openAlert('success', 'Notaria registrada')
            setNotaria({ id: "0", notaria: '', name: '', nombre_notaria: '', ciudad_notaria: '', buscar: '', estatus: 1 })
            obtener_notarias(0)
        } catch (error) {
            openAlert('warning', 'Hubo un error al registrar la notaria')
        }
    }

    const editar = (id: string) => {
        const data = notarias.find(x => x.id === id)
        if (data) {
            setNotaria(data as Notaria)
        }
    }

    const clearSelection = () => {
        setNotaria({ id: "0", notaria: '', name: '', nombre_notaria: '', ciudad_notaria: '', buscar: '', estatus: 1 })
    }

    const actualizar = async (id: string) => {
        try {
            await Notarias.update(id, { nombre_notaria: notaria.nombre_notaria, ciudad_notaria: notaria.ciudad_notaria, notaria: notaria.notaria })
            openAlert('success', 'Notaria actualizada')
            setNotaria({ id: "0", notaria: '', name: '', nombre_notaria: '', ciudad_notaria: '', buscar: '', estatus: 1 })
            obtener_notarias(0)
        } catch (error: any) {
            openAlert('error', error.response.data.message)
        }
    }

    const actualizarEstatus = async (id: string, estatus: boolean) => {
        try {
            await Notarias.update(id, { estatus })
            openAlert('success', 'Notaria actualizada')
            obtener_notarias(0)
            setSeleccion(null)
        } catch (error: any) {
            openAlert('error', error.response.data.message)
        }
    }
    return {
        notaria,
        notarias,
        obtenerDatos,
        nueva_notaria,
        editar,
        clearSelection,
        actualizar,
        actualizarEstatus,
        seleccion, setSeleccion
    };
};

export default NotariaHooks;
