import {Avatar, Box, Card, CardContent, Grid, Typography} from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import { Link } from 'react-router-dom';

type Props = {
    cantidad : number
}
export default function VencidosCard(props : Props) {
    return (
        <Card sx={{ height: '100%' }}>
            <CardContent>
                <Grid
                    container
                    spacing={3}
                    sx={{
                    justifyContent: 'space-between'
                }}>
                    <Grid item>
                        <Typography color="textSecondary" gutterBottom variant="overline">
                            Vencidos
                        </Typography>
                        <Typography color="textPrimary" variant="h4">
                            { props.cantidad }
                        </Typography>
                        <Typography color="textPrimary" variant='subtitle1'>
                            Expedientes
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Avatar
                            sx={{
                            backgroundColor: 'error.main',
                            height: 56,
                            width: 56
                            }}
                        >
                            <PeopleIcon />
                        </Avatar>
                    </Grid>
                </Grid>
                <Box sx={{pt: 2,display: 'flex',alignItems: 'center'}}>
                    <Typography
                    color="textSecondary"
                    variant="caption"
                    >
                        <Link to="/admin/fases/vencidos">Ver</Link>
                    </Typography>
                </Box>
            </CardContent>
        </Card>
    );
}
