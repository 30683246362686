import { IDatosGenerales } from "../pages/admin/DatosGenerales"
import httpService from "./Http"

class AdministradorApi{

    static async create(oficina : string){
        return await new httpService().post(`v1/administracion/AltaOficina`, { oficina })
    }

    static async oficinas(){
        return await new httpService().get(`v1/administracion/ObtenerOficinas`)
    }

    static async actualizarLista(lista : { id : number, estatus : number}[]){
        return await new httpService().put(`v1/administracion/ActualizarOficinas`, { lista })
    }
    
    static async guardar_comunicados (comunicados : any){
        return await new httpService().put(`v1/administracion/comunicados`, { comunicados })
    }

    static async obtener_comunicados () {
        return await new httpService().get(`v1/administracion/comunicados`)
    }

    static async cambiar_oficina (id_oficina : number){

    }

    static async crear_detalles(datos : IDatosGenerales ){
        return await new httpService().post(`v1/administracion/detalles`, datos)
    }
    
    static async obtener_detalles(){
        return await new httpService().get(`v1/administracion/detalles`)
    }
}
export default AdministradorApi