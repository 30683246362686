import React, { ReactElement } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';

interface IButtonProps {
    callback : () => void,
    label : string,
    loading : boolean,
    color : any,
    sx? : any,
    icon? : ReactElement,
    fullWidth? : boolean,
    size? : any
}
const LoadingButtonComponent = (props : IButtonProps) => {
    return (
        <LoadingButton
            loading ={props.loading}
            loadingPosition="start"
            startIcon={props.icon ? props.icon : false }
            variant="contained"
            onClick={() => props.callback()}
            color={props.color}
            sx={props.sx}
            fullWidth = {props.fullWidth}
            size = {props.size}
        >
            {props.label}
        </LoadingButton>
    );
};

export default LoadingButtonComponent;