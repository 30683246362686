import httpService from "./Http"

class ExpedientesApi{
    static async getApoyos(){
        return await new httpService().get('v1/catalogos/apoyos')
    }
    static async crear(id : string | number,expediente : any){
        return await new httpService().post(`v1/expediente/nuevo/${id}`, expediente)
    }
    static async expedientes(id : number | string ){
        return await new httpService().get(`v1/expediente/expedientes/${ id }`)
    }
    static async expediente(id : number | string ){
        return await new httpService().get(`v1/expediente/${ id }`)
    }
    static async update(id_caso : string ,expediente : any){
        return await new httpService().put(`v1/expediente/${ id_caso }`,expediente)
    }
    static async destino_creditos(){
        return await new httpService().get(`v1/expediente/destino_credito`)
    }
    static async cancelarExpediente(id_caso : number, razon : string ){
        return await new httpService().put(`v1/expediente/cancelar/${id_caso}`, { razon })
    }
    static async activar(id_caso : string){
        return await new httpService().put(`v1/expediente/activar/${id_caso}`)
    }
    static async actualizar_vencimiento(id_caso : string | number, vencimiento : string ){
        return await new httpService().put(`v1/expediente/por_vencer/${id_caso}`,{
            vencimiento
        })
    }
    static async monto_actual_firmar(){
        return await new httpService().get(`v1/firma/monto_firmar`)
    }
    
}

export default ExpedientesApi;