import React, { useEffect, useState } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import TextField from '@mui/material/TextField';
import { format, isValid } from 'date-fns';
import { LocalizationProvider } from '@mui/x-date-pickers';

interface IDateSelectorProps {
    label: string,
    retrieveDate: (date: string) => void,
    incomingValue?: string | null | Date
}

const DateSelector = (props: IDateSelectorProps) => {
    const [value, setValue] = useState<Date | null | string | number>(null)

    useEffect(() => {
        if (props.incomingValue) {
            setValue(new Date(props.incomingValue).setHours(24, 0, 0, 0))
        }
    }, [props.incomingValue])

    const handleChange = (newValue: Date | null) => {
        if (isValid(newValue)) {
            setValue(newValue);
            props.retrieveDate(format(newValue!, 'yyyy/MM/dd'))
        }
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
                label={props.label}
                format="dd/MM/yyyy"
                onChange={handleChange}
                slotProps={{
                    textField: {
                        size: "small",
                        variant: "outlined",
                        fullWidth: true
                    }
                }}
            />
        </LocalizationProvider>
    );
};

export default DateSelector;