import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, TextareaAutosize, TextField } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { useAppContext } from '../../AppContext';
import CustomSection from '../../components/CustomSection';
import TableComponent, { createColumns } from '../../components/TableComponent';

import ClientesHook from '../../hooks/ClientesHook';
import { Cliente } from '../../business/models/Cliente';
import Clientes from '../../api/clientes';
import DialogComponent from '../../components/DialogComponent';
import SelectField from '../../components/SelectField';
import AsesorHook from '../../hooks/AsesorHook';
const columns : GridColDef[] =[
    createColumns({ headerName : "Cliente", field : "nombre_completo"}),
    createColumns({ headerName : "Asesor", field : "asesor"}),
    createColumns({ headerName : "Correo", field : "correo"}),
    createColumns({ headerName : "Fecha de Ingreso", field : "fecha_ingreso"}),
    createColumns({ headerName : "Fecha de Nacimiento", field : "fecha_nacimiento"}),
]
//TODO: corregir cambio de asesor y desactivar cliente
const ListaClientes = () => {
    const {handleActivePage, openAlert} = useAppContext()
    const { clientes, ObtenerClientes, setDatosCambioCliente, cambiarCliente }= ClientesHook()
    const { usuarios, getAsesores } = AsesorHook()
    const [cliente, setCliente] = useState<Cliente | null >(null)
    const [open, setOpen] = useState<boolean>(false)

    useEffect(()=>{
        handleActivePage('Clientes')
        ObtenerClientes()
        getAsesores()
    },[])

    const rowSelected = (row : any) =>{
        setCliente(row.row)
        setDatosCambioCliente('id_cliente', row.row.id)
    }

    const desactivar = async () =>{
        if(cliente !== null){
            try {
                await Clientes.delete(Number(cliente.id))
                openAlert('success','El cliente ha sido dado de baja')
            } catch (error) {
                openAlert('warning','Hubo un error al intentar eliminar al cliente')
            }
        }
    }

    return (
        <CustomSection>
                <Grid container>
                    <Grid item md={12}>
                        <Box display='flex' flexDirection='row-reverse' >
                            {/* <Button disabled={cliente === null} size="small" color='warning' onClick={() => desactivar()}>Desactivar</Button> */}
                            {/* <Button disabled={cliente === null} size="small" color='info' onClick={() => setOpen(true)}>Cambiar de asesor</Button> */}
                        </Box>
                    </Grid>
                    <Grid item md={12}>
                        <div style={{ height: '100%', width: '100%' }}>
                            <TableComponent rows={clientes} columns={columns} rowCallback={ rowSelected } />
                        </div>
                    </Grid>
                    {/* <DialogComponent DialogText='Cambiar asesor a cliente' open={open} handleClose={() => setOpen(false)}  ButtonActions={{
                        handleCancelActionText : 'Cancelar',
                        handleCancelActionFunction : () => setOpen(false),
                        handleSuccessActionText : 'Guardar',
                        handleSuccessActionFunction : () => cambiarCliente(),
                    }} >
                        <Grid container>
                            <Grid item xs={12}>
                                <SelectField label='Selecciona un asesor' name='id_asesor' menuRender={usuarios.map(item => {
                                        return { name : item.name!, id : item.id! }
                                    }
                                )} onChange={setDatosCambioCliente} />
                            </Grid>
                            <Grid item xs={12} mt={5}>
                                <TextField sx={{width : '100%'}} multiline label='Razon por la cual se cambia al cliente' onChange={(ev) => setDatosCambioCliente('razon', ev.target.value) } />
                            </Grid>
                        </Grid>
                    </DialogComponent> */}
                </Grid>
        </CustomSection>
    );
};

export default ListaClientes;