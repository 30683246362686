import React,{useState,useEffect} from 'react';
import AsesoresApi from '../api/asesores';
import { useAppContext } from '../AppContext';
import { Asesor, FasesKeyValue } from '../business/models/Asesor';
import { getRole } from '../business/Validations';
import { useNavigate } from 'react-router-dom';
//@ts-ignore
import  CryptoJS  from 'crypto-js'
const AsesorHook = () => {
    const { openAlert, handleToggleLoading,handleCloseLoading} = useAppContext()
    const navigate = useNavigate()
    const [usuario, setUsuario] = useState<Asesor>({
        nombre : '',
        correo : '',
        telefono : '',
        tipo_usuario : 0,
        id : 0,
        id_oficina : 0,
        id_banco : 0,
        id_constructora : 0,
        id_desarrollo : 0,
        id_inmobiliaria : 0,
        permisos : {
            lectura : false,
            clientes : false,
            catalogos : false,
            registro_expediente : false,
            seguimiento : false,
            fases : {
                analisis : {
                    consulta : true, escritura : false
                },
                autorizado : {
                    consulta : true, escritura : false
                },
                avaluo : {
                    consulta : true, escritura : false
                },
                instruccion : {
                    consulta : true, escritura : false
                },
                firma : {
                    consulta : true, escritura : false
                },
                fondeo : {
                    consulta : true, escritura : false
                },
                cobranza : {
                    consulta : true, escritura : false
                },
                comision : {
                    consulta : true, escritura : false
                }
            },
            comentarios : false,
            leer_comentarios : false
        },
        oficina : '',
        password : '123456'
    })
    const [ usuarios, setUsuarios ] = useState<Asesor[] | []>([])

    useEffect(()=>{
        verificar_tipo_usuario(usuario.tipo_usuario)
    },[usuario.tipo_usuario])

    const getAsesores = async () =>{
        try {
            let { data } = await AsesoresApi.getAll()
            setUsuarios(data.asesores.map((item : Asesor) =>{
                item.oficina = item.oficina ?? 'Principal'
                item.tipo_usuario = getRole(item.tipo_usuario)!
                item.name = item.nombre
                item.estatus_name = Number(item.estatus) === 1 ? "Activo" : "Desactivado"
                return item
            }))
        } catch (error) {
            
        }
    }

    const setAsesor = async ( id : string ) =>{
        try {
            let { data } = await AsesoresApi.get(id)
            if(data.asesor.permisos === null ){
                data.asesor.permisos = usuario.permisos
            }
            setUsuario(data.asesor)
        } catch (error) {
            
        }
    }

    const setDatosUsuario = (name : string, value : any) =>{
        setUsuario({
            ...usuario, [name] : value
        })
    }

    const asignarPermisos = (e : any) =>{
        const {name, checked } = e.target
        setUsuario({
            ...usuario, permisos : {
                ...usuario.permisos , [name] : checked
            }
        })
    }

    const asignarPermisoEspecial = (e : any) => {
        const { id, checked } = e.target
        const name : keyof FasesKeyValue = e.target.name
        let consulta = {}
        if(id === 'escritura' && !usuario.permisos.fases[name]!.consulta){
            consulta = { consulta : true }
        }
        setUsuario({
            ...usuario, permisos : {
                ...usuario.permisos , fases : {
                    ...usuario.permisos.fases , [name] : { 
                        ...usuario.permisos.fases[name] , [id] : checked,  ...consulta 
                    }
                }
            }
        })
    }
    const verificar_tipo_usuario = (tipo_usuario : number | string) =>{
        switch (Number(tipo_usuario)) {
            case 2:
                setUsuario({
                    ...usuario, permisos : {
                        ...usuario.permisos , clientes : true
                    }
                })
                break;
        
            default:
                break;
        }
    }
    // esta funcion se debe ejecutar cada vez que el usuario cambie de Tipo de usuario
    // para evitar que los permisos que se hayan seleccionado previamente queden en el estado
    const reiniciar_permisos = ()=>{
        setUsuario({
            ...usuario , permisos : {
                lectura : false,
                clientes : false,
                catalogos : false,
                registro_expediente : false,
                seguimiento : false,
                fases : {
                    analisis : {
                        consulta : true, escritura : false
                    },
                    autorizado : {
                        consulta : true, escritura : false
                    },
                    avaluo : {
                        consulta : true, escritura : false
                    },
                    instruccion : {
                        consulta : true, escritura : false
                    },
                    firma : {
                        consulta : true, escritura : false
                    },
                    fondeo : {
                        consulta : true, escritura : false
                    },
                    cobranza : {
                        consulta : true, escritura : false
                    },
                    comision : {
                        consulta : true, escritura : false
                    }
                },
                comentarios : false,
                leer_comentarios : false
            }
        })
    }
    const guardar = async () =>{
        try {
            handleToggleLoading()
            usuario.password = CryptoJS.MD5(usuario.password!).toString().substring(0,8)
            usuario.uid = CryptoJS.MD5(usuario.correo).toString().substring(0,16)
            await AsesoresApi.crear_usuario_global({ uid : usuario.uid!, password : usuario.password! })
            await AsesoresApi.crear(usuario)
            openAlert('info', "Se ha enviado un correo de bienvenida al usuario con sus datos de acceso")
            handleCloseLoading()
            navigate('/admin/asesores/lista')
        } catch (error : any) {
            handleCloseLoading()
            openAlert('warning', "Ocurrio un error al registrar el asesor, verifique los datos e intente de nuevo.")
            console.log(error)
        }
    }
    const actualizar = async (id : string) =>{
        try {
            await AsesoresApi.update(id, usuario)
            openAlert('success', "Asesor actualizado con exito")
        } catch (error : any) {
            console.log(error.response)
        }
    }
    return {
        usuario,usuarios,setUsuario,setDatosUsuario,asignarPermisos,asignarPermisoEspecial,guardar, actualizar, getAsesores, setAsesor, reiniciar_permisos
    };
};

export default AsesorHook;