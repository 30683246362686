import { Comunicado } from "../business/models/Comunicados"
import httpService from "./Http"
class ComunicadosService {

    static async create(comunicado: Comunicado) {
        return await new httpService().post(`v1/comunicados/interno`, comunicado)
    }
    static async get() {
        return await new httpService().get(`v1/comunicados`)
    }
}
export default ComunicadosService