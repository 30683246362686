import React, { useCallback, useMemo } from 'react';
import { pink } from '@mui/material/colors';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';

import { CantidadFases } from '../../business/models/Fases';
import { useNavigate } from 'react-router-dom';
import { VerificarAccesos } from '../../business/components/TieneAcceso'
import { TipoUsuario } from '../../business/models/Asesor';
import { useAppContext } from '../../AppContext';
import { drawerWidth } from '../../config';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

interface Props {
    anchor: Anchor,
    open: boolean,
    onClose: CallableFunction,
    cantidades: any
}

interface Fases {
    nombre: string,
    cantidad: React.ReactNode
}

const SidebarComponent = (props: Props) => {
    const navigation = useNavigate()
    const { buildContent } = VerificarAccesos()

    const cantidadFases = useCallback(() => {
        let content: Fases[] = []
        if (props.cantidades !== null) {
            const cant = props.cantidades as CantidadFases
            Object.keys(cant).forEach((element: string) => {
                if (element !== "proyecciones") {
                    if (element === 'vencidos' || element === 'venciendo') {
                        content.push({
                            nombre: element.toUpperCase(),
                            cantidad: cant[element]
                        })
                    } else
                        if (element === 'pago' && buildContent({ tipo_usuario: TipoUsuario.Administrador })) {
                            content.push({
                                nombre: element.toUpperCase(),
                                cantidad: cant[element]
                            })
                        } else
                            if (buildContent({ tipo_usuario: TipoUsuario.Administrador, permisos: { fases: { [element]: { consulta: true } } } })) {
                                content.push({
                                    nombre: element.toUpperCase(),
                                    cantidad: cant[element]
                                })
                            }
                }
            })
            return content
        }
        return []
    }, [props.cantidades])

    const handleRedirect = (fase: string) => {
        navigation('fases/' + fase)
    }

    return (
        props.cantidades &&
        <React.Fragment key={props.anchor}>
            <Drawer
                ModalProps={{ keepMounted: true }}
                sx={{
                    '& .MuiDrawer-paper': {
                        boxSizing: 'border-box',
                        width: drawerWidth,
                        backgroundImage: 'none',
                        boxShadow: 'inherit'
                    },
                    zIndex: (theme) => theme.zIndex.appBar + 100
                }}
                anchor={props.anchor} open={props.open} onClose={() => props.onClose()}>
                <List
                    sx={{
                        width: '100%',
                        maxWidth: 360,
                        bgcolor: 'background.paper'
                    }}>
                    {
                        cantidadFases().map((action: any) => (
                            <ListItem key={action.nombre} onClick={() => handleRedirect(action.nombre)}>
                                <ListItemText primary={action.nombre} />
                                <ListItemAvatar>
                                    <Avatar sx={{ bgcolor: pink[500] }}>
                                        {action.cantidad}
                                    </Avatar>
                                </ListItemAvatar>
                            </ListItem>
                        ))
                    }
                </List>
            </Drawer>
        </React.Fragment>
    );
}
export default SidebarComponent