import { useAppContext } from './../AppContext';
import { useState } from 'react';
import Clientes from '../api/clientes';
import { Cliente } from '../business/models/Cliente';
// import { AlertHook } from 'components/Alert';

const ClientesHooks = () => {
  // const { showAlert} = AlertHook()
  const initialState = {
    id: 0,
    id_cliente_asesor: 0,
    curp: '',
    buscarCliente: '',
    apellido_paterno: '',
    apellido_materno: '',
    nombre: '',
    fecha_nacimiento: null,
    correo: '',
    telefono: '',
    ingreso_mensual: 0,
    valor_vivienda: 0,
    tipo_economia: '',
    tipo_compra: -1,
    referido: 0,
    id_constructora: 0,
    id_desarrollo: 0,
    id_inmobiliaria: 0
  }

  const [clientes, setClientes] = useState([])
  const [loading, setLoading] = useState(false)
  const { openAlert, handleToggleLoading, handleCloseLoading } = useAppContext()
  const [cliente, setCliente] = useState<Cliente>(initialState);
  const [cambioCliente, setCambioCliente] = useState<{ id_cliente: number, id_asesor: number, razon: string } | null>(null)
  const [nuevoAsesor, setNuevoAsesor] = useState(null)
  const [query, setQuery] = useState("");

  const obtenerDatos = (name: string, value: any) => {
    setCliente({ ...cliente, [name]: value });
  };

  const buscarClientePorQuery = async () => {
    try {
      let { data } = await Clientes.clientePorQuery(query)
      openAlert('warning', data.message)
    } catch (error: any) {
      const { message } = error.response.data
      openAlert('error', message)
    }
  }

  const ObtenerClientes = async () => {
    handleToggleLoading()
    try {
      let { data } = await Clientes.all()
      setClientes(data.lista)
      handleCloseLoading()
    } catch (error) {
      handleCloseLoading()
    }
  }

  const buscarCliente = async (id: number) => {
    try {
      handleToggleLoading()
      let { data } = await Clientes.cliente(id)
      handleCloseLoading()
      return data.detalles
    } catch (error: any) {
      const { message } = error.response.data
      openAlert('error', message)
      handleCloseLoading()
    }
  }

  const guardar = async () => {
    setLoading(true)
    try {
      await Clientes.save(cliente)
      setLoading(false)
      openAlert('success', "Prospecto agregado con exito")
      setCliente(initialState)
    } catch (error: any) {
      if (error.hasOwnProperty('response')) {
        const { message } = error.response.data
        openAlert('error', message)
        setLoading(false)
        return
      }
      openAlert('error', "Ocurrio un error al registrar el cliente")
      setLoading(false)
    }
  }
  const actualizar = async (id: string) => {
    setLoading(true)
    try {
      await Clientes.update(id, cliente)
      setLoading(false)
      openAlert('success', "Cliente actualizado con exito")
    } catch (error: any) {
      const { message } = error.response.data
      openAlert('error', message)
      setLoading(false)
    }
  }
  const setDatosCambioCliente = (name: any, value: any) => {
    setCambioCliente({ ...cambioCliente!, [name]: value })
  }
  const cambiarCliente = async (nuevoAsesor: number, id_cliente: number, id_cliente_asesor: number) => {
    try {
      handleToggleLoading()
      await Clientes.cambiarAsesor(nuevoAsesor, id_cliente, id_cliente_asesor)
      openAlert('success', "Se asigno correctamente asesor a cliente")
      setNuevoAsesor(null)
      handleCloseLoading()
    } catch (error) {
      openAlert('error', "Ocurrio un error al intentar asignar asesor")
      handleCloseLoading()
    }
  }
  const findCliente = async (nombre: any) => {
    try {
      if (nombre !== "" && nombre.length > 3) {
        const { data } = await Clientes.buscarCliente(nombre)
        setClientes(data.cliente)
      }
    } catch (error) {
      openAlert('error', "No se encontraron clientes")
    }
  }
  return {
    cliente,
    clientes,
    loading,
    query,
    initialState,
    nuevoAsesor,
    obtenerDatos,
    buscarClientePorQuery,
    ObtenerClientes,
    buscarCliente,
    guardar,
    setCliente,
    actualizar,
    setQuery,
    setDatosCambioCliente,
    cambiarCliente,
    setNuevoAsesor,
    findCliente
  };
};

export default ClientesHooks;
