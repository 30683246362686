import React, { useState } from 'react'
import { Box, Button, Grid, Paper, TextField, Typography } from '@mui/material'
import AlertComponent, { AlertHook } from '../components/Alert'
import { useNavigate, useParams } from 'react-router-dom'
import AuthApi from '../api/auth';

type Props = {}

const RecuperarPassword = (props : Props) => {
    const [ passwords, setPasswords ] = useState<{ one : string | null, two : string | null }>({ one : null, two : null})
    const { open, type, message, openAlert, handleClose } = AlertHook()
    const { token } = useParams()
    const navigate = useNavigate()
    
    const handleRecoverPassword = async () =>{
        try {
            if(passwords.one !== null && passwords.two !== null ){
                if(passwords.one !== passwords.two){
                    throw Error('Las contraseñas no coinciden, por favor verifiquelas');
                }
            }else{
                throw Error('Las contraseñas no pueden estar vacias, por favor verifiquelas');
            }
            if(token){
                const { data } = await AuthApi.updatePassword(token, passwords.one);
                openAlert('success', data.message)
                setTimeout(() =>{
                    navigate('/login')
                },2000)
            }else{
                throw Error('No es posible actualizar password sin un token, por favor regrese a la pantalla de inicio de sesion e intentelo de nuevo.');
            }
        } catch (error : any) {
            if(error.response && error.response.status){
                openAlert('error', error.response.data.message)
            }else{
                openAlert('warning', error.message)
            }
        }
    }
    return (
        <Box sx={{
            backgroundColor: "#416477 !important",
            minHeight: "calc(100vh - 0px)"
        }}>
            <Grid container sx={{
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
                display: "flex",
                flexDirection: "column",
                alignContent: "space-around",
                padding : 2
            }}>
                <Grid item lg={12}>
                    <Paper elevation={16} sx={{padding : 5}}>
                        <Box sx={{ display : 'flex', flexDirection :'column' ,alignItems:'center' ,my: 3}}>
                            <Typography color="textPrimary" variant="h4">
                                Recuperación de contraseña
                            </Typography>
                            <Typography color="textSecondary" gutterBottom variant="body2">
                                Sistema Integral de Gestion Hipotecaria
                            </Typography>
                        </Box>
                        <TextField
                            fullWidth
                            label="Contraseña"
                            margin="normal"
                            name="one"
                            type="password"
                            onChange={(ev : any) => setPasswords({ ...passwords ,one : ev.target.value})}
                            value={passwords.one}
                            variant="outlined"/>
                        <TextField
                            fullWidth
                            label="Confirmar contraseña"
                            margin="normal"
                            name="two"
                            type="password"
                            onChange={(ev : any) => setPasswords({ ...passwords ,two : ev.target.value})}
                            value={passwords.two}
                            variant="outlined"/>
                        <Box sx={{py: 2}}>
                            <Button
                                color="primary"
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                onClick={() => handleRecoverPassword()}
                                >
                                Entrar
                            </Button>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        <AlertComponent open={open} type = { type } message={message} handleClose={ handleClose } />
        </Box>
    )
}

export default RecuperarPassword