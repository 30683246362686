import httpService from "./Http"

class Valuadores {

    static async create(valudador: any) {
        return await new httpService().post(`v1/valuador`, valudador)
    }

    static async all(estatus: number) {
        return await new httpService().get(`v1/valuador/${estatus}`)
    }

    static async update(id: string, params: any) {
        return await new httpService().put(`v1/valuador/${id}`, { params })
    }
}
export default Valuadores