import httpService from "./Http"

class BancosApi {

    static async create(banco: any) {
        return await new httpService().post(`v1/banco`, banco)
    }

    static async get(estatus: number) {
        return await new httpService().get(`v1/banco/all/${estatus}`)
    }

    static async esquemas(id_banco: number | string) {
        return await new httpService().get(`v1/banco/esquemas/${id_banco}`)
    }

    static async createEsquema(nuevoEsquema: any) {
        return await new httpService().post(`v1/esquema`, nuevoEsquema)
    }

    static async updateEsquema(id_esquema: string | number, params: any) {
        return await new httpService().put(`v1/esquema/actualizar/${id_esquema}`, params)
    }

    static async updatebanco(id_banco: number, params: any) {
        return await new httpService().put(`v1/banco/${id_banco}`, { params })
    }

}
export default BancosApi