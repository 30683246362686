import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import PersonIcon from '@mui/icons-material/Person';
import SearchIcon from '@mui/icons-material/Search';

export default function PaperInput({...props}) {
    
    return (
        <Paper sx={{ display: 'flex', alignItems: 'center'}}>
        <IconButton sx={{ p: '10px' }} aria-label="menu">
            <PersonIcon />
        </IconButton>
        <InputBase
            onChange={props.onChange}
            sx={{ ml: 1, flex: 1 }}
            {...props}
        />
        <IconButton sx={{ p: '10px' }} aria-label="search" onClick={() => props.handleClickIcon()}>
            <SearchIcon />
        </IconButton>
        </Paper>
    );
}