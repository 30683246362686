import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Collapse, List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import React from 'react'
import { NavProps } from './dashboard-sidebar'
import { NavItem } from './nav-item'

interface IMenuComponent {
    menuRoute: NavProps;
}
const MenuComponent = (props: IMenuComponent) => {
    const { menuRoute } = props

    const [open,
        setOpen] = React.useState(false)

    return (
        <React.Fragment>
            <ListItem button onClick={() => setOpen(!open)} disableGutters
                sx={{
                    display: 'flex',
                    mb: 0.5,
                    py: 0,
                    px: 2,
                    '& .MuiListItemIcon-root': {
                        color: 'primary.light'
                    },
                }}>
                {
                    menuRoute.icon &&
                    <ListItemIcon>
                        {menuRoute.icon}
                    </ListItemIcon>
                }
                <ListItemText primary={menuRoute.title} /> {open
                    ? <ExpandLess />
                    : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {menuRoute.menu!.map((item, index) => (
                        item.canAccess ?
                            <NavItem
                                key={index}
                                icon={item.icon}
                                href={item.path}
                                title={item.title}
                            /> : null
                    ))
                    }
                </List>
            </Collapse>
        </React.Fragment>
    )
}
export default MenuComponent;