import { format, formatDistanceToNow, formatDistanceStrict } from 'date-fns';
import { es } from 'date-fns/locale';
import { PermisosEspeciales, TipoUsuario } from './models/Asesor';
import { Casos } from './models/Casos';

export const validateForm = (form: any, data: any) => {
    Object
        .keys(form)
        .forEach((element) => { })
}

const equals = (a: any, b: any): any => {
    if (a === b)
        return true;

    if (a instanceof Date && b instanceof Date)
        return a.getTime() === b.getTime();

    if (!a || !b || (typeof a !== 'object' && typeof b !== 'object'))
        return a === b;

    if (a.prototype !== b.prototype)
        return false;

    const keys = Object.keys(a);

    return keys.every(k => equals(a[k], b[k]));
}

export const getRole = (tipo_usuario: number | string) => {
    switch (Number(tipo_usuario)) {
        case 1:
            return TipoUsuario.Administrador
        case 2:
            return TipoUsuario.Asesor
        case 4:
            return TipoUsuario.Control
        case 8:
            return TipoUsuario.Externo
        case 16:
            return TipoUsuario.Gerente
    }
}

export const isEmpty = (value: any) => {
    if (value === '' || value === null || value === undefined) {
        return true
    }
    return false
}

export const format_to_number = (value: string | number) => {
    if (!isNaN(Number(value))) {
        return `${new Intl
            .NumberFormat('es-Mx')
            .format(Number(value))}`
    }
    return 0
}

export const format_date_string = (date: string) => {
    try {
        return format(new Date(date).setHours(24, 0, 0, 0), 'dd/MM/yyyy')
    } catch (error) {
        return null
    }
}

export const mapCasos = (casos: Casos[]) => {
    return casos.map(item => {
        if (item.fecha_ultima_fase) {
            item.fecha_ultima_fase = format_date_string(item.fecha_ultima_fase) ?? 'No cuenta con fecha'
        }
        item.fecha_ingreso_banco = format_date_string(item.fecha_ingreso_banco) ?? 'No cuenta con fecha'
        item.fecha_firmado = format_date_string(item.fecha_firmado) ?? 'No cuenta con fecha'
        item.monto_autorizado = item.monto_autorizado ?? 0
        item.monto_solicitado = item.monto_solicitado ?? 0
        item.monto_firmado = item.monto_firmado ?? 0
        item.por_vencer = item.vencimiento
            ? formatDistanceToNow(new Date(item.vencimiento).setHours(24, 0, 0, 0), { locale: es, addSuffix: true })
            : ''
        item.vencimiento_pure = item.vencimiento
        item.vencimiento = item.vencimiento === null ? 'No existe vencimiento' : format_date_string(item.vencimiento) ?? ''
        if (item.fechas) {
            //@ts-ignore
            item.dias = getDistance(item.fechas![item.fase.toLowerCase()]) !== '0' ? getDistance(item.fechas![item.fase.toLowerCase()]) : '0'
            item.fecha_ultima_fase = format_date_string(item.fechas![item.fase.toLowerCase()]) ?? 'No cuenta con fecha'
        }
        return item
    })
}

export const getDistance = (fecha: string) => {

    try {
        const days = formatDistanceStrict(new Date(fecha.toLowerCase()).setHours(24, 0, 0, 0), new Date(), {
            locale: es,
            unit: 'day'
        })
        if (days.includes('día')) {
            return Number(days.split('día')[0])
        }
        return Number(days.split('días')[0])
    } catch (error) {
        return '0'
    }
}

export const hasAccess = (permisos: Partial<PermisosEspeciales>, permisos_usuario: PermisosEspeciales) => {
    return equals(permisos, permisos_usuario)
}
export const convertArrayOfObjectsToCSV = (array: any[]) => {
    let result: any;

    const columnDelimiter = ',';
    const lineDelimiter = '\n';
    const keys = Object.keys(array[0]);

    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach(item => {
        let ctr = 0;
        keys.forEach(key => {
            if (ctr > 0)
                result += columnDelimiter;

            result += item[key];

            ctr++;
        });
        result += lineDelimiter;
    });

    return result;
}

export const downloadCSV = (array: any[], columns: any[]) => {
    const link = document.createElement('a');
    let new_array = array.map(item => {
        let new_row: any = {};
        columns.forEach(col => {
            let value = item[col.field]
            new_row[col.headerName] = value ?? ''
        })
        return new_row
    })
    let csv = convertArrayOfObjectsToCSV(new_array);
    if (csv == null)
        return;

    const filename = 'export.csv';

    if (!csv.match(/^data:text\/csv/i)) {
        csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute('href', encodeURI(csv));
    link.setAttribute('download', filename);
    link.click();
}