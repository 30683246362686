import { Box, Button, FormControl, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import InputField from '../../components/InputForm'
import { Comunicado } from '../../business/models/Comunicados'
import DateSelector from '../../components/DateSelector'
import ComunicadosService from '../../api/comunicados'
import { useAppContext } from '../../AppContext'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import ComunicadoItem from './ComunicadoItem'

const InitialState: Comunicado = {
    titulo: "",
    contenido: "",
    expiracion: "",
    tipo: ""
}
const ComunicadosInternos = () => {
    const { openAlert } = useAppContext()
    const [comunicado, setComunicado] = useState<Comunicado>(InitialState)
    const [comunicados, setComunicados] = useState<Comunicado[]>([])

    useEffect(() => {
        getComunicados()
    }, [])

    const getValueFromInput = (name: string, value: string) => {
        setComunicado({ ...comunicado, [name]: value })
    }

    const save = async () => {
        try {
            await ComunicadosService.create(comunicado)
            openAlert("success", "Comunicado creado")
            getComunicados()
        } catch (error) {
            openAlert("error", "Hubo un error al crear el comunicado")
        }
    }

    const getComunicados = async () => {
        try {
            const { data } = await ComunicadosService.get()
            setComunicados(data.comunicados)
        } catch (error) {

        }
    }

    return (
        <Grid container spacing={2} >
            <Grid xs={12}>
                <InputField onChange={getValueFromInput} name="titulo" label="Titulo" type="text" fullWidth />
            </Grid>
            <Grid xs={12}>
                <FormControl sx={{ mt: 1, mb: 1, width: "100%" }}>
                    <TextField
                        label="Comunicado"
                        name="contenido"
                        multiline
                        maxRows={4}
                        onChange={(ev) => getValueFromInput("contenido", ev.target.value)}
                        value={comunicado.contenido}
                        fullWidth
                    />
                </FormControl>
            </Grid>
            <Grid xs={12}>
                <DateSelector
                    label="Expiracion"
                    retrieveDate={(date: string) => getValueFromInput("expiracion", date)}
                />
            </Grid>
            <Grid xs={12}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row-reverse'
                }}>
                    <Button variant="contained" size='small' onClick={() => save()}>Guardar</Button>
                </Box>
            </Grid>
            <Grid xs={12}>
                <Grid container spacing={2}>
                    {
                        comunicados.map((item, index) => (
                            <Grid key={index} xs={12} md={4}>
                                <ComunicadoItem titulo={item.titulo} contenido={item.contenido} />
                            </Grid>
                        ))
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ComunicadosInternos