import { Box } from '@mui/material';
import React from 'react';
import DateSelector from '../../components/DateSelector';
import InputField from '../../components/InputForm';
import { IFaseProps } from './Index';

const Firma = ({ handlechange,setDates,state } : IFaseProps) =>{
    
    return (
        <Box style={{ flexDirection: 'column', justifyContent : 'space-around'}}>
            <Box >
                <DateSelector
                    label = "Fecha Firmado"
                    retrieveDate={( date : string ) => setDates("fecha_fondeo", date) }
                />
            </Box>
                <Box mt={2}>
                    <InputField onChange={handlechange} name="monto_firmado" label="Monto a firmar" type="number" value={state.monto_firmado} fullWidth/>
                    <span> {`$${new Intl.NumberFormat().format(state.monto_firmado)}`} </span>
                </Box>
                <Box mt={2}>
                    <InputField onChange={handlechange} name="escrituras" label="Escrituras" type="number" fullWidth/>
                </Box>
        </Box>
    )
};

export default Firma;